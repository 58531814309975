export const DatosPago = {
    correo: "",
    tipoIdentificacion: "",
    tipoIdentificacionTitular: "",
    numeroIdentificacion: "",
    numeroIdentificacionTitular: "",
    banco: "",
    tipoPersona: "0",
    celular: "",
    nombreORazonSocial: "",
    razonSocialTitular: "",
    pais: "",
    departamento: "",
    municipio: "",
    metadataDireccion: "",
    tipoRegistro: ""
};