<template>
  <label :for="inputId" class="ael-checkbox-label">
    <input
      type="checkbox"
      :id="inputId"
      v-model="checked"
      class="ael-checkbox-input"
      @click="handleChange"
    />
    <span :class="{ checked: checked }" class="ael-checkbox">
      <svg v-if="checked" viewBox="0 0 24 24" class="ael-check-icon">
        <path
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          d="M20 6L9 17l-5-5"
        />
      </svg>
    </span>
    {{ label }}
  </label>
</template>
<script>
import { defineComponent, ref } from "vue";

export default defineComponent({
  props: {
    modelValue: Boolean,
    label: String,
    inputId: String,
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const checked = ref(props.modelValue || false);
    const handleChange = () => {
      checked.value = !checked.value;
      emit("update:modelValue", checked.value);
    };
    return {
      checked,
      handleChange,
    };
  },
});
</script>

<style  lang="scss" scoped>
@import "@/assets/sass/components/layout/_checkbox.scss";
</style>