import { Module } from 'vuex';
import axios from 'axios'

const DueDiligence = process.env.VUE_APP_Gateway_API + "DebidaDiligencia/";
const BackendDueDiligence = process.env.VUE_APP_DebidaDiligenciaAPI + "DebidaDiligencia/"
const BackendDueDiligenceEmpleador = process.env.VUE_APP_DebidaDiligenciaAPI + "Empleador/"

const ddModule: Module<any, any> = {
  state: {
    step: 1,
    debidaDiligencia: {
      contacto: {},
      Pep: {},
      Cliente: {},
      Financiera: {},
      Legal: {
        RepLegalTitular:{},
        RepLegalSuplente:{},
        RepLegalPep:{}
      },
      AccionistaNatural:{
        AccNatural:{},
        PepAccNatural:{}
      },
      AccionistaJuridico:{
        AccJuridico:{},
        BeneficiarioJuridico:{},
        PepAccJuridico:{}
      },
      Compromisos: {}
    },
    parametricas:{}
  },
  getters: {},
  mutations: {
    Siguiente(state) {
      state.step++;
    },
    SiguienteDosPasos(state) {
      state.step = state.step + 2;
    },
    SiguienteTresPasos(state){
      state.step = state.step + 3;
    },
    Anterior(state){
      state.step--;
    },
    AnteriorDosPasos(state) {
      state.step = state.step - 2;
    },
    AnteriorTresPasos(state){
      state.step = state.step - 3;
    },
    infoContacto(state, objetoContacto){
      state.debidaDiligencia.contacto = objetoContacto;
    },
    infoPep(state, objetoPep) {
      state.debidaDiligencia.Pep = objetoPep;
    },
    eliminarPEP(state) {
      state.debidaDiligencia.Pep = {}
    },
    infoCliente(state, objetoCliente) {
      state.debidaDiligencia.Cliente = objetoCliente
    },
    infoFinanciera(state, objetoFinanciera) {
      state.debidaDiligencia.Financiera = objetoFinanciera
    },
    eliminarFinanciera(state) {
      state.debidaDiligencia.Financiera = {}
    },
    infoRepresentanteTitular(state, objetoInfoRepresentanteTitular){
      state.debidaDiligencia.Legal.RepLegalTitular = objetoInfoRepresentanteTitular
    },
    infoRepresentanteSuplente(state, objetoInfoRepresentanteSuplente){
      state.debidaDiligencia.Legal.RepLegalSuplente = objetoInfoRepresentanteSuplente
    },
    infoRepresentantePep(state, objetoInfoRepresentantePep){
      state.debidaDiligencia.Legal.RepLegalPep = objetoInfoRepresentantePep
    },
    infoAccionistaNatural(state, objetoInfoAccionista_Natural){
      state.debidaDiligencia.AccionistaNatural.AccNatural = objetoInfoAccionista_Natural
    },
    infoPepAccionistaNatural(state, objetoInfoPepAccionista_Natural){
      state.debidaDiligencia.AccionistaNatural.PepAccNatural = objetoInfoPepAccionista_Natural
    },
    infoAccionistaJuridico(state, objetoInfoAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.AccJuridico = objetoInfoAccionista_Juridico
    },
    infoBeneficiarioAccionistaJuridico(state, objetoInfoBeneficiarioAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.BeneficiarioJuridico = objetoInfoBeneficiarioAccionista_Juridico
    },
    infoPepAccionistaJuridico(state, objetoInfoPepAccionista_Juridico){
      state.debidaDiligencia.AccionistaJuridico.PepAccJuridico = objetoInfoPepAccionista_Juridico
    },
    cargarParametricas(state, objetoParametricas){
      state.parametricas = objetoParametricas
    },
    infoCompromisos(state, objetoCompromisos){
      state.debidaDiligencia.Compromisos = objetoCompromisos
    },
  },
  actions: {
    Avanzar({
      commit
    }) {
      commit('Siguiente');
    },
    AvanzarDosPasos({
      commit
    }) {
      commit('SiguienteDosPasos')
    },
    AvanzarTresPasos({commit}){
      commit('SiguienteTresPasos')
    },
    Retroceder({commit}){
      commit('Anterior');
    },
    RetrocederDosPasos({
      commit
    }) {
      commit('AnteriorDosPasos')
    },
    RetrocederTresPasos({commit}){
      commit('AnteriorTresPasos')
    },
    CargarInformacionContacto({commit}, objetoContacto){
       commit('infoContacto', objetoContacto);
    },
    CargarInformacionPep({ commit }, objetoPep) {
      commit('infoPep', objetoPep);
    },
    EliminarInformacionPEP({ commit }) {
      commit('eliminarPEP');
    },
    CargarInformacionCliente({ commit }, objetoCliente) {
      commit('infoCliente', objetoCliente)
    },
    CargarInformacionFinanciera({ commit }, objetoFinanciera) {
      commit('infoFinanciera', objetoFinanciera)
    },
    EliminarInformacionFinanciera({ commit }) {
      commit('eliminarFinanciera');
    },
    CargarInformacionRepresentanteTitular({commit}, objetoInfoRepresentanteTitular){
      commit('infoRepresentanteTitular', objetoInfoRepresentanteTitular)
    },
    CargarInformacionRepresentanteSuplente({commit}, objetoInfoRepresentanteSuplente){
      commit('infoRepresentanteSuplente', objetoInfoRepresentanteSuplente)
    },
    CargarInformacionRepresentantePep({commit}, objetoInfoRepresentantePep){
      commit('infoRepresentantePep', objetoInfoRepresentantePep)
    },
    CargarInformacionAccionistaNatural({commit}, objetoInfoAccionista_Natural){
      commit('infoAccionistaNatural',objetoInfoAccionista_Natural)
    },
    CargarInformacionPepAccionistaNatural({commit}, objetoInfoPepAccionista_Natural){
      commit('infoPepAccionistaNatural',objetoInfoPepAccionista_Natural)
    },
    CargarInformacionAccionistaJuridico({commit}, objetoInfoAccionista_Juridico){
      commit('infoAccionistaJuridico',objetoInfoAccionista_Juridico)
    },
    CargarInformacionBeneficiarioAccionistaJuridico({commit}, objetoInfoBeneficiarioAccionista_Juridico){
      commit('infoBeneficiarioAccionistaJuridico',objetoInfoBeneficiarioAccionista_Juridico)
    },
    CargarInformacionPepAccionistaJuridico({commit}, objetoInfoPepAccionista_Juridico){
      commit('infoPepAccionistaJuridico',objetoInfoPepAccionista_Juridico)
    },
    CargarInformacionCompromisos({commit}, objetoCompromisos){
      commit('infoCompromisos',objetoCompromisos)
    },
    ObtenerTokenDD({commit,dispatch},id){
      let config = {
        headers: {
            Token: id
        }
    }
      return new Promise ((resolve,reject)=>{
        axios.get(DueDiligence+"ObtenerTokenDD",config)
          .then((response)=>{
              resolve(response);
          })
          .catch(error=>{
            reject(error.request.response);
          });
      }
      )
    },
    GetParametricasDebidaDiligencia({ commit }, data) {
      let config = {
          headers: {
              Token: data.Token
          }
      }
      return new Promise((resolve, reject) => {
          axios.get(`${DueDiligence}ObtenerParametricas`, config)
              .then((res) => {
                commit('cargarParametricas', res.data);
                  resolve(res);
              })
              .catch((err) => {
                  reject(err)
              })
      })
    },
    RegistrarDebidaDiligencia({ commit }, data) {
      return new Promise((resolve, reject) => {
        let config = {
          headers: {
            Token: data.Token
          }
        }
        axios.post(`${DueDiligence}Registrar`, data.Archivos, config)
        .then((response)=>{
          resolve(response);
        })
        .catch(error=>{
          reject(error.request.response);
        });
      })
    },
    ObtenerEstadoEmpleadorDD({commit,dispatch},data){
      let config = {
        headers: {
          TokenDD: data.TokenDD
        }
      }
      return new Promise ((resolve,reject)=>{
        axios.get(BackendDueDiligenceEmpleador+"ObtenerEstadoEmpleador?TipoDocumento="+data.TipoDocumento+
        "&NumeroDocumento="+data.NumeroDocumento,config)
          .then((response)=>{
              resolve(response);
          })
          .catch(error=>{
            reject(error.request.response);
          });
      }
      )
    },
    CifrarDatosEmpleadorParaDD({commit,dispatch},data){
      let config = {
        headers: {
          TipoDocumento: data.TipoDocumento,
          NumeroDocumento: data.NumeroDocumento,
          RazonSocial: data.RazonSocial,
          IdProducto: data.IdProducto,
          TokenDD: data.TokenDD
        }
      }
      return new Promise ((resolve,reject)=>{
        axios
            .get(`${BackendDueDiligenceEmpleador}CifrarDatosEmpleador`, config)
            .then((response: any) => {
                resolve(response);
            })
            .catch((err) => {
                reject(err)
            });
      }
      )
    },
    obtenerUrlDebidaDiligencia({commit, dispatch}){     
      return new Promise ((resolve, reject)=>{     
        dispatch('ObtenerTokenDD',localStorage.getItem('Tokensin')).then(data=>{
          let ParametrosParaCifrar = {
              TipoDocumento: localStorage.getItem('TipoIdentificacion'),
              NumeroDocumento: localStorage.getItem('Identificacion'), 
              RazonSocial: localStorage.getItem('RazonSocial'),
              IdProducto: 1, 
              TokenDD: data.data 
              }
  
        dispatch('CifrarDatosEmpleadorParaDD',ParametrosParaCifrar).then((k) => {
            resolve(process.env.VUE_APP_PortalDebidaDiligencia+"DueDiligence/"
            +k.data.valorCifrado)
        })   
        }).catch(error=>{
          reject(error);  
        });   
      })
    },
    validarEstadoParaMostrarComponenteDD({commit, dispatch}){
      return new Promise ((resolve, reject)=>{     
        dispatch('ObtenerTokenDD',localStorage.getItem('Tokensin')).then(data=>{
          let ParametrosEmpleador = {
            TipoDocumento: localStorage.getItem('TipoIdentificacion'),
            NumeroDocumento: localStorage.getItem('Identificacion'),  
            TokenDD: data.data 
            }
  
        dispatch('ObtenerEstadoEmpleadorDD',ParametrosEmpleador).then((k) => {
          resolve(k.data);
        })   
        }).catch(error=>{
          reject(error);  
        });   
      })
    }
  },
  modules: {}
}

export default ddModule;