

import { onMounted, ref } from "vue";
import ResumenEmpleado from "@/components/DetalleNomina/ResumenEmpleado.vue"

export default {
  props: {
    empleado: {},
    detalle: {},
    fechaIngreso: String,
    fechaAprobacion: String
  },
  components:{
    ResumenEmpleado
  },
  setup(props) {

    const empleado = ref();
    const detalleNomina = ref();
    const fechaIngreso = props.fechaIngreso;
    const fechaAprobacion = props.fechaAprobacion;
    
    onMounted(() => {
      empleado.value = props?.empleado;
      detalleNomina.value = props?.detalle; 
    });

    return { empleado, detalleNomina, fechaIngreso, fechaAprobacion };
  },
};
