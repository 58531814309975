
import InputNumber from "primevue/inputnumber";
import SaludPensionValidacion from "@/validation/PayrollTransmission/SaludPension";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [saludPension, validaciones] =
      new SaludPensionValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, saludPension, {
      $scope: props.nomina,
    });

    return {
      saludPension,
      v$,
    };
  },
};
