
import { ref } from "@vue/reactivity";
import {useStore} from "vuex";
import Dialog from "primevue/dialog";
import router from '@/router';
import '../../assets/sass/components/TransmisionNomina/PopUpConfirmarTransmision.scss';

export default {
name: 'PopUpConfirmarTransmision',
components: {
    Dialog
},
props: {
    UploadFile:Function,
    year: String,
    month: String
},
setup() {     
      const store= useStore();     
      const tipoIdentificacion = ref(localStorage.getItem("TipoIdentificacion")?.toString());
      const numeroIdentificacion = ref(localStorage.getItem("Identificacion")?.toString()); 
      
      const CancelarTransmision = () => {
      router.push('Transmission')
    };
        
    return {
      store,
      tipoIdentificacion,
      numeroIdentificacion,
      CancelarTransmision
    };
  },  
};
