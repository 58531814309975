
import DireccionValidacion from "@/validation/Layout/Direccion";
import useVuelidate from "@vuelidate/core";
import { useStore } from "vuex";
import { computed, onMounted, ref, watch } from "vue";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import { CampoNuloOVacio, Split } from "@/helpers/helpers";
import "@/assets/sass/components/layout/_direccion.scss";

export default {
  components: {
    Dropdown,
    InputText,
  },
  props: {
    direccion: String,
    requiredAsterisk: Boolean,
    metadataDireccion: {
      type: Object,
      required: false,
      default: () => ({
        TipoVia: "",
        NumeroVia: "",
        OrientacionEsteOeste: "",
        NumeroComplementario: "",
        OrientacionNorteSur: "",
        NumeroAdicional: "",
        TipoUbicacion: "",
        Ubicacion: "",
      }),
    },
  },
  setup(props) {
    const store = useStore();
    const cardinalidad = ref();
    const tiposVias = ref();
    const tiposUbicacion = ref();

    let [direccion, reglasDireccion] =
      new DireccionValidacion().ObtenerReglasValidacion();

    watch(
      () => props.metadataDireccion,
      (newVal) => {
        Object.assign(direccion, newVal);
      },
      { immediate: true }
    );

    function ObtenerDireccion() {
      const tipoVia = Split(direccion.TipoVia ?? "", 0, "-");
      const numeroVia = direccion.NumeroVia;
      const orientacionEsteOeste = ObtenerOrientacion(direccion.OrientacionEsteOeste);
      const numeroComplementario = direccion.NumeroComplementario;
      const orientacionNorteSur = ObtenerOrientacion(direccion.OrientacionNorteSur);
      const numeroAdicional = direccion.NumeroAdicional;
      const codigoUbicacion = Split(direccion.TipoUbicacion ?? "", 0, "-");
      const lugar = direccion.Ubicacion;
      return `${tipoVia} ${numeroVia}${orientacionEsteOeste} # ${numeroComplementario}${orientacionNorteSur} ${numeroAdicional} ${codigoUbicacion} ${lugar}`;
    }

    function ObtenerOrientacion(orientacion: string): string {
      orientacion = Split(orientacion ?? "", 0, "-");
      return CampoNuloOVacio(orientacion) ? orientacion : ` ${orientacion}`;
    }

    const v$ = useVuelidate(reglasDireccion, direccion, {
      $scope: props.direccion,
    });

    const asterisk = (name: String) => {
      return "<span class='required-asterisk'>*</span>" + name;
    };

    const hayErroresDireccion = computed(() => {
      return (
        v$.value.TipoVia.$error ||
        v$.value.NumeroVia.$error ||
        v$.value.NumeroComplementario.$error ||
        v$.value.NumeroAdicional.$error
      );
    });

    const hayErroresOpcional = computed(() => {
      return v$.value.Ubicacion.$error || v$.value.TipoUbicacion.$error;
    });

    const errorDireccion = computed(() => {
      if (v$.value.TipoVia.$error)
        return v$.value.TipoVia.$errors[0].$message;
      if (v$.value.NumeroVia.$error)
        return v$.value.NumeroVia.$errors[0].$message;
      if (v$.value.NumeroComplementario.$error)
        return v$.value.NumeroComplementario.$errors[0].$message;
      if (v$.value.NumeroAdicional.$error)
        return v$.value.NumeroAdicional.$errors[0].$message;
      return "";
    });

    const errorOpcional = computed(() => {
      if (v$.value.Ubicacion.$error)
        return v$.value.Ubicacion.$errors[0].$message;
      if (v$.value.TipoUbicacion.$error)
        return v$.value.TipoUbicacion.$errors[0].$message;
      return "";
    });

    function ObtenerListasParaDireccion() {
      let parametrosConsulta = {
        token: localStorage.getItem("Tokensin"),
      };
      store
        .dispatch("ObtenerListasParaDireccion", parametrosConsulta)
        .then((response) => {
          if (response.request.status == 200) {
            cardinalidad.value = response.data.Cardinalidad;
            tiposVias.value = response.data.TiposVias;
            tiposUbicacion.value = response.data.TiposUbicacion;
          }
        })
        .catch((error) => {
          console.log(error.request.response);
        });
    }

    onMounted(() => {
      ObtenerListasParaDireccion();
    });

    return {
      direccion,
      ObtenerDireccion,
      v$,
      props,
      asterisk,
      cardinalidad,
      tiposVias,
      tiposUbicacion,
      hayErroresDireccion,
      hayErroresOpcional,
      errorDireccion,
      errorOpcional,
    };
  },
};
