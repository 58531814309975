
import router from "@/router";
import { onMounted, ref } from "vue";
import { useStore } from "vuex";
export default {
  name: "Menu",
  setup() {
    
    const store = useStore();
    const mostrarConfiguracion = ref(false);

    const GotoTransmission = () => {
      router.push({ name: "Transmission" });
    };
    const GoToPlans = () => {
      router.push({ name: "Plans" });
    };
    const GoToStatePlan = () => {
      router.push({ name: "PlanControl" });
    };
    const GoToEmployerContact = () => {
      router.push({ name: "EmployerContact" });
    };
    const GoToConfiguration = () => {
      router.push({ name: "Configuration" });
    };

    function GetFeatureFlag() {
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-260477",
      };
      store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          mostrarConfiguracion.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    onMounted(() => {
      GetFeatureFlag();
    });

    return {
      GotoTransmission,
      GoToPlans,
      GoToStatePlan,
      GoToEmployerContact,
      GoToConfiguration,
      mostrarConfiguracion,
    };
  },
};
