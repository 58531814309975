enum EstadoDebidaDiligencia{
    
    NoDefinido = 0,
    CreadaDD = 1,
    ParaCrearNuevaDD = 2,
    AprobadaDD = 3,
    RechazadaDD = 4,
    Notificado = 5
}

export default EstadoDebidaDiligencia