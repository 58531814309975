
import { ref } from "@vue/reactivity";
import {useStore} from "vuex";
import Dialog from "primevue/dialog";
import router from '@/router';
import '../../assets/sass/components/DueDiligence/_popUpRenovacionDebidaDiligencia.scss';

export default {
name: 'PopUpRenovacionDebidaDiligencia',
components: {
    Dialog,
},
props: {
  estadoDD: Number
},
setup() {     
      const store= useStore();
      const UrlDebidaDiligencia = ref();
 
      store.dispatch('obtenerUrlDebidaDiligencia').then((response) => 
      {
        UrlDebidaDiligencia.value = response;
      });

      const CerrarModal = () => {
      router.push('Transmission')
    };
       
    return {
      store,
      UrlDebidaDiligencia,
      CerrarModal
    };
  },   
};
