import { computed, reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class FinancieraValidacion{
    public ObtenerReglasValidacion() {
        const objetoFinanciera =reactive({
            VentasIngresosOperacionales:'',
            TotalActivos:'',
            Egresos:'',
            IngresosNoOperacionales:'',
            OtrosActivos:'',
            TotalPasivos:'',
            TotalIngresos:'',
            DescripcionOtrosActivos:'',
            ActivosTotales2:'',
            GastosTotales:'',
            GastosFinancieros:'',
            DescripcionOtrosIngresos:'',
            DescripcionIngresosNoOperacionales:'',
        })

        // const ValidaObligatorios = (valor_moneda:any) =>
        // helpers.withParams(
        // { type: 'moneda', value: valor_moneda },
        // (value:any) => value > 0 
        // )

        // const reglasValidacion = computed(()=>{
        //     return{
        //         VentasIngresosOperacionales: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.VentasIngresosOperacionales))              
        //         },
        //         TotalActivos: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.TotalActivos)) 
        //         },
        //         IngresosNoOperacionales: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.IngresosNoOperacionales))  
        //         },
        //         OtrosActivos: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.OtrosActivos))  
        //         },
        //         TotalPasivos: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.TotalPasivos))  
        //         },
        //         TotalActivos2: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.TotalActivos2))  
        //         },
        //         DescripcionOtrosActivos: { 
        //             required: helpers.withMessage('Ingrese descripción', required)
        //         }, 
        //         ActivosTotales2: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.ActivosTotales2))  
        //         },
        //         GastosTotales: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.GastosTotales))  
        //         },
        //         GastosFinancieros: { 
        //             Obligatorio: helpers.withMessage('Ingrese valor', ValidaObligatorios(objetoFinanciera.GastosFinancieros))  
        //         },
        //         DescripcionOtrosIngresos: { 
        //             required: helpers.withMessage('Ingrese descripción', required)
        //         },
        //         DescripcionIngresosNoOperacionales: { 
        //             required: helpers.withMessage('Ingrese descripción', required)  
        //         },
        //     }
        // })

        return [objetoFinanciera] as const;
    }
}
export default FinancieraValidacion;