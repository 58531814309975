<template>

  <div >
    <h1 class="Transmision_title" v-if="mostrar">
      Detalle Validación Nómina Electrónica
    </h1>
  </div>

  <TransmissionHeader 
    v-if="mostrar"
    :cantidad ="cantidadErrores"
    :periodo ="periodo"
    :titulo ="'Errores'">
  </TransmissionHeader>

  <ErrorCargueNomina
    v-if="erroresCargue != null && erroresCargue != ''"
    :errores="erroresCargue"
    :periodo="periodo">
  </ErrorCargueNomina>

  <ErrorValidacionNomina
    v-if="erroresValidacion != null && erroresValidacion != ''" 
    :errores="erroresValidacion"
    :periodo="periodo">
  </ErrorValidacionNomina>

  <ErrorProveedorTecnologico
    v-if="erroresProveedorTecnologico?.length > 0"
    :errores="erroresProveedorTecnologico" 
    :periodo="periodo">
  </ErrorProveedorTecnologico>

  <div class="row tac">
    <template v-if="
      estadoEntrada == estadosEntradaNomina.ConDocumentosInsuficientes ||
      estadoEntrada == estadosEntradaNomina.SinDocumentosOVencidos">
      <input
        type="button"
        @click="GotoPlans()"
        value="Comprar nuevo plan"
        class="Button_BColorDegraded Button_fontButtonError"
        />
    </template>
    <input
      v-if="cantidadErrores > 0"
      type="button"
      @click="GotoTransmission()"
      value="Volver al inicio"
      class="Button_BColorDegraded Button_fontButtonError"
      />
  </div>  

</template>

<script>

import router from "@/router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import { useMutations } from '@/store/helper';
import { EstadoEntradaNomina } from '@/models/Payroll/Enums';
import ErrorCargueNomina from "@/components/DetalleTransmission/ErrorCargueNomina.vue";
import TransmissionHeader from '@/components/DetalleTransmission/TransmissionHeader.vue';
import ErrorValidacionNomina from "@/components/DetalleTransmission/ErrorValidacionNomina.vue";
import ErrorProveedorTecnologico from '@/components/DetalleTransmission/ErrorProveedorTecnologico.vue';

export default {
  name: "ErrorTransmission",
  components: {
    ErrorCargueNomina,
    ErrorValidacionNomina,
    ErrorProveedorTecnologico,
    TransmissionHeader
  },
  setup() {

    const store = useStore();
    const { setStateLoading } = useMutations(["setStateLoading"]);

    const periodo = ref('');
    const cantidadErrores = ref('');
    const erroresNomina = ref('');
    const erroresProveedorTecnologico = ref('');
    const erroresValidacion = ref(null)
    const erroresCargue = ref(null);
    const mostrar = ref(false);
    const erroresNE = ref('');

    const estadosEntradaNomina = EstadoEntradaNomina;
    const estadoEntrada = ref(estadosEntradaNomina);
    const entradaNomina = store.state.EntradaNomina;
    const token = localStorage.getItem('Tokensin');

    

    function GotoPlans() {
      router.push({
        name: "Plans"
      });
    }

    function GotoTransmission() {
      router.push({
        name: "Transmission"
      });
    }

    function DisabledBackNavigation() {
      window.onpopstate = function() {
        history.go(1);
      };
    }

    function ObtenerTotalizadosNomina() {
      if (entradaNomina.EntradaNominaId == '') {
        cantidadErrores.value = erroresNomina.value.cantidadErrores > 0 ?
          erroresNomina.value.cantidadErrores :
          store.state.DetalleEntradaNomina.CantidadErrores;
        periodo.value = store.state.DetalleEntradaNomina.Periodo;
      } else {
        cantidadErrores.value = erroresNomina.value.cantidadErrores > 0 ?
          erroresNomina.value.cantidadErrores :
          entradaNomina.CantidadErrores;

        periodo.value = entradaNomina.Periodo;
      }

      mostrar.value = entradaNomina.EntradaNominaId != '' && cantidadErrores.value > 0;
    }

    function ObtenerErroresTransmision() {

      setStateLoading(true);

      store.dispatch("ObtenerErroresTransmision", token)
        .then((errores) => {
          
          erroresNomina.value = errores.data;
          erroresCargue.value = errores.data.erroresCargue;
          erroresValidacion.value = errores.data.erroresValidacion;
          erroresProveedorTecnologico.value = errores.data.erroresProveedorTecnologico;

          estadoEntrada.value = erroresCargue.value == null ?
            estadosEntradaNomina.NoDefinido :
            erroresCargue.value.estadoEntrada;

          erroresNE.value = errores.value;

          ObtenerTotalizadosNomina();
          setStateLoading(false);
        })
        .catch((error) => {

          setStateLoading(false);
          console.log(error.request.response);

          if (error.response.status == 404) {
            GotoTransmission();
          }
        });
    }
    onMounted(() => {
      ObtenerErroresTransmision();
      DisabledBackNavigation();
    });
    return {
      GotoPlans,
      erroresCargue,
      cantidadErrores,
      periodo,
      erroresValidacion,
      erroresProveedorTecnologico,
      estadosEntradaNomina,
      estadoEntrada,
      GotoTransmission,
      entradaNomina,
      DisabledBackNavigation,
      mostrar,
      erroresNE
    };
  },
};

</script>