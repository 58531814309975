
import InputNumber from "primevue/inputnumber";
import HorasExtrasValidacion from "@/validation/PayrollTransmission/HorasExtras";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { HorasExtras } from "@/helpers/Parametricas";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { onMounted, ref } from "vue";
import { DatetimeToDate, Split } from "@/helpers/helpers";

export default {
  components: {
    InputNumber,
    Calendar,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const listaHorasExtras = ref();

    let [HE, horasExtras, validaciones] =
      new HorasExtrasValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, HE, {
      $scope: props.nomina,
    });

    const AgregarFila = () => {
      const horaExtra = {
        SurchargeTypeCode: null,
        StartDate: null,
        EndDate: null,
        Quantity: null,
        Total: null,
      };

      HE.items.push(horaExtra);
      horasExtras.push(horaExtra);
    };

    const AsignarPorcentaje = (index: number, codigo: string) => {
      if (codigo) {
        const horaExtraId = codigo.split("-")[0];
        HE.items[index].Percentage = listaHorasExtras.value
          .filter((horaExtra: any): Object => {
            return horaExtra.horaExtraId === Number(horaExtraId);
          })
          .map((horaExtra: any): number => {
            return horaExtra.porcentaje;
          })
          .shift();
      }
    };

    const EliminarHoraExtra = (index: number) => {
      horasExtras.splice(index, 1);
      HE.items.splice(index, 1);
    };

    const NombreHorasExtras = () => {
      HE.items = HE.items.map((hr: any) => {
        hr.TipoHoraExtra = listaHorasExtras.value
          .filter((listaHoraExtra: any) => {
            return Number(listaHoraExtra.horaExtraId) == hr.SurchargeTypeCode;
          })
          .shift()?.abreviatura;
        return hr;
      });
    };

    const CodigoHoraExtra = (horaExtra: string, index: number) => {
      if (horaExtra) {
        HE.items[index].SurchargeTypeCode = Split(horaExtra, 0, "-");
      } else {
        HE.items[index].SurchargeTypeCode = "";
      }
    };

    const FormatearFechas = () => {
      HE.items = HE.items.map((horaExtra: any) => {
        horaExtra.StartDate = DatetimeToDate(horaExtra.StartDate);
        horaExtra.EndDate = DatetimeToDate(horaExtra.EndDate);
        return horaExtra;
      });
    };

    onMounted(() => {
      listaHorasExtras.value = HorasExtras();
      NombreHorasExtras();
      FormatearFechas();
    });

    return {
      HE,
      listaHorasExtras,
      v$,
      EliminarHoraExtra,
      AsignarPorcentaje,
      FormatearFechas,
      AgregarFila,
      CodigoHoraExtra,
    };
  },
};
