

import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import InfoPepAccionista_JuridicoValidacion from '../../validation/InfoPepAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoPepAccionista_Juridico: String,
        HabilitaCamposPepAJ: Boolean,
        parametricas: {}
    },
    setup(props) {
        
        let [objetoInfoPepAccionista_Juridico, reglasValidacion] = new InfoPepAccionista_JuridicoValidacion().ObtenerReglasValidacion();


        const AgregarFilaPEPAJ = () => {

            objetoInfoPepAccionista_Juridico.PepAJ.push({
                NombreAJ_PEP: '',
                NombrePEP: '',
                TipoIdPEPAJ: '',
                NumeroIdentificacionPEPAJ: '',
                TipoVinculoPEPAJ: '',
                TipoPEPAJ: ''
            }); 
        }

        const EliminarFilaPEPAJ = (index: number) => {
            objetoInfoPepAccionista_Juridico.PepAJ.splice(index, 1);
        }

        const reiniciarObjetoInfoPepAccionista_Juridico = () => {
          objetoInfoPepAccionista_Juridico.PepAJ = [];
          AgregarFilaPEPAJ();
        }
        const validacionInfoPepAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoPepAccionista_Juridico, { $scope: props.scopeInfoPepAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]


        return { 
          AgregarFilaPEPAJ, 
          EliminarFilaPEPAJ, 
          validacionInfoPepAccionista_Juridico, objetoInfoPepAccionista_Juridico, reiniciarObjetoInfoPepAccionista_Juridico,
          tiposIdentificacion
        }
    },
})

