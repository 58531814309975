

import useVuelidate from '@vuelidate/core';
import { defineComponent, onUpdated } from 'vue';
import InfoAccionista_NaturalValidacion from '../../validation/InfoAccionista_Natural';
import '../../assets/sass/components/DueDiligence/_legal.scss';
import func from '@/validation/GeneralRules';

export default defineComponent({
    props: {
        scopeInfoAccionista_Natural: String,
        parametricas: {}
    },
    setup(props) {
        
        let [objetoInfoAccionista_Natural, reglasValidacion] = new InfoAccionista_NaturalValidacion().ObtenerReglasValidacion();

        const AgregarFilaAN = () => {

            objetoInfoAccionista_Natural.An.push({
                NombreAN: '',
                TipoIdAN: '',
                NumeroIdentificacionAN: '',
                ParticipacionCapitalAN: '',
                EsPEPAN: '',
                VinculosFamPEP_AN: ''
            }); 
        }

        const EliminarFilaAN = (index: number) => {
            objetoInfoAccionista_Natural.An.splice(index, 1);
        }

        const reiniciarObjetoInfoAccionista_Natural = () => {
          objetoInfoAccionista_Natural.An = [];
          AgregarFilaAN();
        }

        const CharPercentage = (e:any,index:number) => {
          if(e != '')
          {
              if(!e.includes('%'))
            {
                objetoInfoAccionista_Natural.An[index].ParticipacionCapitalAN = objetoInfoAccionista_Natural.An[index].ParticipacionCapitalAN + ' %';
            }     
          }         
        }

        const validacionInfoAccionista_Natural = useVuelidate(reglasValidacion, objetoInfoAccionista_Natural, { $scope: props.scopeInfoAccionista_Natural })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onUpdated(() => {
            window.scrollTo(0, 0);
})

        return { 
          AgregarFilaAN,
          EliminarFilaAN,
          validacionInfoAccionista_Natural, objetoInfoAccionista_Natural, reiniciarObjetoInfoAccionista_Natural,CharPercentage,func,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPAN()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoAccionista_Natural.An.length; i++)
            {
                if(this.objetoInfoAccionista_Natural.An[i].EsPEPAN.includes("Si") || this.objetoInfoAccionista_Natural.An[i].VinculosFamPEP_AN.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepAN',Result)
        }
    }
})

