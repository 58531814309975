
import { defineComponent, onUpdated, ref } from "vue";
import useVuelidate from "@vuelidate/core";
import clienteValidacion from "@/validation/infoCliente";
import func from '@/validation/GeneralRules';
import { useStore } from "vuex";

export default defineComponent({
  props: {
    scopeCliente: String,
    DeshabilitarCamposJuridicos: String,
    clienteRef: {},
    parametricas: {}
  },
  setup(props) {

    const store = useStore();
    let [objetoCliente, reglasValidacion] =
    new clienteValidacion().ObtenerReglasValidacion();
    const validacionCliente = useVuelidate(reglasValidacion, objetoCliente, {
      $scope: props.scopeCliente,
    });
    let DescripcionActEconomica1 = ref();
    const DescripcionActEconomica2 = ref();

    onUpdated(() => {
            window.scrollTo(0, 0);
    });

    return {
      store,
      objetoCliente,
      validacionCliente,
      DescripcionActEconomica1,
      DescripcionActEconomica2,
      func
    };
  }
});
