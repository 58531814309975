import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-tabpanel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Empleado = _resolveComponent("Empleado")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_DatosEmpresa = _resolveComponent("DatosEmpresa")!
  const _component_NominaElectronica = _resolveComponent("NominaElectronica")!
  const _component_LiquidacionDevengos = _resolveComponent("LiquidacionDevengos")!
  const _component_LiquidacionDeducciones = _resolveComponent("LiquidacionDeducciones")!
  const _component_TotalNomina = _resolveComponent("TotalNomina")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      ref: "tabView",
      activeIndex: $setup.activeTab,
      class: "payroll-tabview",
      scrollable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, { header: "Empleado" }, {
          default: _withCtx(() => [
            _createVNode(_component_Empleado, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Datos empresa" }, {
          default: _withCtx(() => [
            _createVNode(_component_DatosEmpresa, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Nómina electrónica" }, {
          default: _withCtx(() => [
            _createVNode(_component_NominaElectronica, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Devengos" }, {
          default: _withCtx(() => [
            _createVNode(_component_LiquidacionDevengos, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Deducciones" }, {
          default: _withCtx(() => [
            _createVNode(_component_LiquidacionDeducciones, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Total nómina" }, {
          default: _withCtx(() => [
            _createVNode(_component_TotalNomina, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}