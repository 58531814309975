import { helpers, maxValue, minValue } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class PrimaValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const cesantias = reactive(
      store.state.DetalleNomina.JsonNomina.GeneralAccruals
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasCesantias = computed(() => {
      return {
        LayOffTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE120`,
            maxValue(maxInt)
          ),
        },
        LayOffPercentage: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE121`,
            maxValue(maxInt)
          ),
        },
        LayOffInterest: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE122`,
            maxValue(maxInt)
          ),
        },
      };
    });

    return [cesantias, reglasCesantias] as const;
  }
}

export default PrimaValidacion;