import { TipoParametrica } from "@/models/Payroll/Enums";
import { useStore } from "vuex";

function ListasParametricas() {
    const store = useStore();
    return store.state.ParametricasTransmision.Parametricas;
}

export function Decision() {
    return ListasParametricas().filter((decision: any) => {
        return decision.tipoListaParametricaId === TipoParametrica.Decision;
    });
}

export function TiposTrabajador() {
    return ListasParametricas().filter((tipoTrabajador: any) => {
        return (
            tipoTrabajador.tipoListaParametricaId ===
            TipoParametrica.TiposTrabajador
        );
    });
}

export function SubtiposTrabajador() {
    return ListasParametricas().filter((subTipoTrabajador: any) => {
        return (
            subTipoTrabajador.tipoListaParametricaId ===
            TipoParametrica.SubtiposTrabajador
        );
    });
}

export function Paises() {
    return ListasParametricas().filter((pais: any) => {
        return (
            pais.tipoListaParametricaId === TipoParametrica.Paises &&
            pais.codigo == "CO"
        );
    });
}

export function Departamentos() {
    return ListasParametricas().filter((dpto: any) => {
        return dpto.tipoListaParametricaId === TipoParametrica.Departamentos;
    });
}

export function PeriodoNomina() {
    return ListasParametricas().filter((periodo: any) => {
        return (
            periodo.tipoListaParametricaId === TipoParametrica.PeriodosNomina
        );
    });
}

export function FormaPago() {
    return ListasParametricas().filter((forma: any) => {
        return forma.tipoListaParametricaId === TipoParametrica.FormaPago;
    });
}

export function MetodoPago() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.MetodosPago;
    });
}

export function TipoCuenta() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.TiposCuenta;
    });
}

export function TipoMoneda() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.TiposMoneda;
    });
}

export function Incapacidades() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.Incapacidades;
    });
}

export function Licencias() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.TiposLicencia;
    });
}

export function Vacaciones() {
    return ListasParametricas().filter((metodo: any) => {
        return metodo.tipoListaParametricaId === TipoParametrica.Vacaciones;
    });
}

export function HorasExtras() {
    const store = useStore();
    return store.state.ParametricasTransmision.HorasExtras;
}