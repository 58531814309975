
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { useMutations } from "@/store/helper";
import { onMounted, onUnmounted, ref, watch } from "vue";

export default {
  name: "Header",
  setup() {
    const store = useStore();
    const route = useRoute();
    const { updateContent } = useMutations(["updateContent"]);

    const razonSocial = ref<string | null>("");
    const tipoIdentificacion = ref<string | null>("");
    const numeroIdentificacion = ref<string | null>("");
    const sucursal = ref<string | null>("");
    const dv = ref<string | null>("");
    const mostrarDatosEmpleador = ref(true);

    const ActualizarDatosEmpleador = () => {
      razonSocial.value = localStorage.getItem("RazonSocialPila");
      tipoIdentificacion.value = localStorage.getItem("TipoIdentificacion");
      numeroIdentificacion.value = localStorage.getItem("Identificacion");
      sucursal.value = localStorage.getItem("Sucursal");
      dv.value = localStorage.getItem("DV");
    };

    onMounted(() => {
      ActualizarDatosEmpleador();
      const unwatch = store.watch(
        () => store.state.refresh,
        (nuevaAutenticacion) => {
          if (nuevaAutenticacion) {
            ActualizarDatosEmpleador();
            updateContent(false);
          }
        }
      );
      onUnmounted(unwatch);
    });

    watch(
      () => route.name,
      (currentView) => {
        if (currentView == 'LogoutInactivity') {
          mostrarDatosEmpleador.value = false;
        }
      }
    );

    return {
      razonSocial,
      tipoIdentificacion,
      numeroIdentificacion,
      sucursal,
      dv,
      mostrarDatosEmpleador
    };
  },
};
