const requiredRule = (message: string) => ({
    required: true,
    message,
    trigger: ["blur", "change"],
});
 
const minLengthRule = (min: number, message: string) => ({
    min,
    message,
    trigger: ["blur", "change"],
});
 
const maxLengthRule = (max: number, message: string) => ({
    max,
    message,
    trigger: ["blur", "change"],
});
 
const patternRule = (pattern: RegExp, message: string) => ({
    pattern,
    message,
    trigger: ["blur", "change"],
});
 
export const validationRules = {
    correo: [
        requiredRule("Escribe tu e-mail"),
        {
            type: "email",
            message: "Ingresa un e-mail válido",
            trigger: ["blur", "change"],
        },
        minLengthRule(8, "Ingresa al menos 8 caracteres"),
        maxLengthRule(120, "Ingresa máximo 120 caracteres"),
    ],
    tipoIdentificacion: [
        requiredRule("Selecciona tipo de documento"),
    ],
    tipoIdentificacionTitular: [
        requiredRule("Selecciona tipo de documento"),
    ],
    numeroIdentificacion: [
        requiredRule("Ingresa tu número de documento"),
        minLengthRule(6, "Ingresa al menos 6 caracteres"),
        maxLengthRule(16, "Ingresa máximo 16 caracteres"),
        patternRule(/^[a-zA-Z0-9_.-]*$/, "No se admiten espacios o caracteres especiales"),
    ],
    numeroIdentificacionTitular: [
        requiredRule("Ingresa tu número de documento"),
        minLengthRule(6, "Ingresa al menos 6 caracteres"),
        maxLengthRule(16, "Ingresa máximo 16 caracteres"),
        patternRule(/^[a-zA-Z0-9_.-]*$/, "No se admiten espacios o caracteres especiales"),
    ],
    celular: [
        requiredRule("Ingresa tu número de celular"),
        minLengthRule(10, "Ingresa al menos 10 caracteres"),
        maxLengthRule(10, "Ingresa máximo 10 caracteres"),
        patternRule(/^\d*$/, "No se admiten espacios o caracteres especiales"),
    ],
    banco: [
        requiredRule("Selecciona un banco"),
    ],
    nombreORazonSocial: [
        requiredRule("Ingresa la razón social"),
        maxLengthRule(64, "Ingresa máximo 64 caracteres"),
    ],
    razonSocialTitular: [
        requiredRule("Ingresa la razón social"),
        maxLengthRule(64, "Ingresa máximo 64 caracteres"),
    ],
    tipoPersona: [],
};