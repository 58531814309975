import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class IncapacidadesValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const inabilities = reactive({
      items: store.state.DetalleNomina.JsonNomina.Inabilities,
    });
    const incapacidades = reactive(
      store.state.DetalleNomina.JsonNomina.Inabilities
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          InabilityTypeCode: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE126`,
              required),
          },
          Quantity: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE125`,
              required
            ),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE125`,
              maxValue(99)
            ),
          },
          Total: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE127`,
              required
            ),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE127`,
              maxValue(maxInt)
            ),
          },
        }),
      },
    };

    return [inabilities, incapacidades, reglas] as const;
  }
}

export default IncapacidadesValidacion;
