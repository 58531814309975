
import { onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import {useStore} from "vuex";
import Spinner from "@/components/layout/Spinner.vue"

const images = require.context('../assets/img/', false, /\.png$/);

export default {
  name:"Dashboard",
    components:{Spinner},
     methods: {
      imgUrl: function (path: string) {
        if(path === 'InicioDebidaDiligencia.png')
        {
          return images('./' + path)
        }
        return path;        
      }
    },
    setup() {     
      var urlImg = ref("");
      const store= useStore();
      const nombre = "Banner Debida Diligencia.png";
      function getImage(){
        store.dispatch('GetImages',nombre).then(data=>{          
        urlImg.value = data.data;
      }).catch(error=>{
        console.log(error);
        });
      }   
      onMounted(() => {  
        getImage(); 
      });
    return {
      getImage,
      urlImg,
    };
  },   
};
