
import InputNumber from "primevue/inputnumber";
import CABValidacion from "@/validation/PayrollTransmission/ComisionesAuxiliosBonificaciones";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { onBeforeMount } from "vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    //Comisiones, Auxilios, Bonificaciones

    let [CAB, validaciones] = new CABValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, CAB, {
      $scope: props.nomina,
    });

    const ValidarAuxilios = () => {
      if (CAB.Assistances.length == 0) {
        CAB.Assistances.push({
          Total: null,
          NonSalaryTotal: null,
        });
      }
    };
    const ValidarBonificaciones = () => {
      if (CAB.Bonuses.length == 0) {
        CAB.Bonuses.push({
          Total: null,
          NonSalaryTotal: null,
        });
      }
    };

    onBeforeMount(() => {
      ValidarAuxilios();
      ValidarBonificaciones();
    });

    return {
      CAB,
      v$,
    };
  },
};
