
import router from "@/router";
import { useStore } from "vuex";
import { useMutations } from "@/store/helper";
import { onMounted, ref } from "vue";
import ValidacionTransmision from "@/components/TransmisionNomina/ValidacionTransmision.vue";

export default {
  components: {
    ValidacionTransmision,
  },
  props: {
    empleadoId: null,
    nominaEmpleado: null,
  },
  setup(props) {
    const store = useStore();
    const token = localStorage.getItem("Tokensin");
    const { setStateLoading } = useMutations(["setStateLoading"]);
    const { setDetalleNomina } = useMutations(["setDetalleNomina"]);
    const { showErrorDialog } = useMutations(["showErrorDialog"]);
    const { showTranssmission } = useMutations(["showTranssmission"]);
    const periodo = store.state.DetalleEntradaNomina.Periodo;
    const tipoError = ref("");

    const ValidarCamposNulos = (nominaEmpleado: string): string => {
      let nomina = JSON.parse(nominaEmpleado);

      if (nomina.LaborUnions.length > 0) {
        const { Percentage, Total } = nomina.LaborUnions[0];
        if (!(Percentage && Total)) {
          nomina.LaborUnions = [];
        }
      }

      nomina.Commissions = nomina.Commissions?.filter(
        (comission: number) => comission !== null
      );

      nomina.PrepaidAccruals = nomina.PrepaidAccruals?.filter(
        (prepaidAccrual: number) => prepaidAccrual !== null
      );

      nomina.PrepaidDeductions = nomina.PrepaidDeductions?.filter(
        (prepaidDeduction: number) => prepaidDeduction !== null
      );

      nomina.OtherDeductions = nomina.OtherDeductions?.filter(
        (otherDeduction: number) => otherDeduction !== null
      );

      nomina.ThirdPartyPaymentAccruals =
        nomina.ThirdPartyPaymentAccruals?.filter(
          (thirdPartyPaymentAccrual: number) =>
            thirdPartyPaymentAccrual !== null
        );

      nomina.ThirdPartyPaymentDeductions =
        nomina.ThirdPartyPaymentDeductions?.filter(
          (thirdPartyPaymentDeduction: number) =>
            thirdPartyPaymentDeduction !== null
        );

      nomina.PaymentDates = nomina.PaymentDates?.filter(
        (paymentDate: string) => paymentDate !== null && paymentDate !== ""
      );

      return JSON.stringify(nomina);
    };

    function TransmitirNomina(empleadoId: string, nominaEmpleado: string) {
      setStateLoading(true);
      showTranssmission(false);

      nominaEmpleado = ValidarCamposNulos(nominaEmpleado);

      let entradaTransmision = {
        NominaEmpleado: nominaEmpleado,
        PeriodoTransmision: periodo,
        EmpleadoId: empleadoId,
      };

      let data = {
        token: token,
        entradaTransmision: entradaTransmision,
      };

      store
        .dispatch("TransmitirNomina", data)
        .then((response) => {
          if (response.request.status == 204) {
            setStateLoading(false);
            setDetalleNomina({
              TransmisionAjuste: true,
            });
            router.push({
              name: "Transmission",
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          setStateLoading(false);

          if (error.status == 400) {
            tipoError.value = error.response;
            showErrorDialog(true);
          }
        });
    }

    onMounted(() => {
      TransmitirNomina(props.empleadoId, props.nominaEmpleado);
    });

    return {
      store,
      tipoError,
    };
  },
};
