<template>
  <div>
    <h1
      class="Transmision_title"
      v-if="entradaNomina.EntradaNominaId != '' && cantidadDocumentos > 0"
    >
      Resumen Validación Nómina Electrónica
    </h1>
  </div>

  <TransmissionHeader
    v-if="entradaNomina.EntradaNominaId != '' && cantidadDocumentos > 0"
    :cantidad="cantidadDocumentos"
    :periodo="periodo"
    :titulo="'Documentos'"
  >
  </TransmissionHeader>

  <DataTable
    id="tablePrime"
    v-if="nominas.length > 0"
    responsiveLayout="scroll"
    :filters="datos?.contiene"
    :rows="5"
    :value="nominas"
    :paginator="true"
    :rowsPerPageOptions="[5, 10, 20, 50, 100]"
  >
    <Filtro ref="datos" />

    <Column
      field="tipoIdentificacion"
      header="Tipo ID"
      :sortable="true"
      bodyClass="datatable-text-center"
      style="white-space: nowrap;"
    >
    </Column>

    <Column
      field="numeroIdentificacion"
      header="No. ID"
      :sortable="true"
      bodyClass="datatable-text-center"
    >
    </Column>

    <Column
      field="nombre"
      header="Nombre"
      :sortable="true"
      bodyClass="datatable-text-center"
    >
    </Column>

    <Column
      field="cune"
      header="CUNE"
      :sortable="true"
      bodyClass="datatable-text-center"
    >
    </Column>

    <Column
      field="fechaInicioNomina"
      v-if="entradaNomina.EntradaNominaId != ''"
      header="Fecha inicio nómina"
      :sortable="true"
      bodyClass="datatable-text-center"
    >
    </Column>

    <Column
      field="fechaProcesamiento"
      header="Fecha procesamiento"
      :sortable="true"
      bodyClass="datatable-text-center"
    >
    </Column>

    <Column
      field="estado"
      :sortable="true"
      header="Estado"
      bodyClass="datatable-text-center"
    >
      <template #body="{ data }">
        <el-tooltip
          content="Aprobado"
          placement="right"
          open-delay="0"
          v-if="data.estado == estado.AprobadoDIAN && !data.nominaNovedad"
        >
          <img src="../assets/img/aprobado.png" />
        </el-tooltip>

        <el-tooltip
          content="Novedad"
          placement="right"
          open-delay="0"
          v-if="data.estado == estado.AprobadoDIAN && data.nominaNovedad"
        >
          <img src="../assets/img/novedad.png" />
        </el-tooltip>

        <el-tooltip
          content="Eliminado"
          placement="right"
          open-delay="0"
          v-if="data.estado == estado.Eliminada"
        >
          <img src="../assets/img/eliminado.png" />
        </el-tooltip>

        <el-tooltip
          content="Modificado"
          placement="right"
          open-delay="0"
          v-if="data.estado == estado.Reemplazada"
        >
          <img src="../assets/img/modificado.png" />
        </el-tooltip>
      </template>
    </Column>

    <Column
      field="opciones"
      header="Opciones"
      bodyClass="datatable-text-center"
      v-if="consultaPorEN"
    >
      <template #body="{ data }">
        <a
          v-if="
            data.estado !== estadoNomina.Eliminada &&
            data.estado !== estadoNomina.Reemplazada
          "
          @click="
            Redirect(data.detalleNominaId, data.empleadoId, 'NominaAjuste')
          "
          class="crud-icon"
        >
          <font-awesome-icon :icon="['fas', 'pen']" size="lg" />
        </a>
        <a
          @click="
            Redirect(data.detalleNominaId, data.empleadoId, 'PayrollDetail')
          "
          class="crud-icon"
        >
          <font-awesome-icon :icon="['fas', 'search']" size="lg" />
        </a>
      </template>
    </Column>
  </DataTable>

  <div class="row tac">
    <input
      v-if="cantidadDocumentos"
      type="button"
      @click="GotoTransmission()"
      value="Volver al inicio"
      class="Button_BColorDegraded Button_fontButtonError"
    />
  </div>

  <NominaAjuste
    v-if="store.state.ConfirmDialog && empleadoId"
    :empleadoId="empleadoId"
  >
  </NominaAjuste>

  <ValidacionTransmision
    v-if="store.state.ErrorDialog"
    :tipoError="tipoError"
  />
</template>

<script>

import router from "@/router";
import { onMounted } from "vue";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import Filtro from "@/components/layout/Filtro.vue";
import { useMutations } from "@/store/helper";
import Dialog from "primevue/dialog";
import ValidacionTransmision from "@/components/TransmisionNomina/ValidacionTransmision.vue";
import TransmissionHeader from "@/components/DetalleTransmission/TransmissionHeader.vue";
import NominaAjuste from "@/components/TransmisionNomina/NominaAjuste.vue";
import {
  EstadoDetalleNomina,
  TipoNomina,
  TipoNota,
} from "@/models/Payroll/Enums";

export default {
  components: {
    TransmissionHeader,
    Filtro,
    TipoNomina,
    TipoNota,
    NominaAjuste,
    Dialog,
    ValidacionTransmision,
    EstadoDetalleNomina,
  },
  setup() {
    
    const store = useStore();
    const { setStateLoading } = useMutations(["setStateLoading"]);
    const { setDetalleNomina } = useMutations(["setDetalleNomina"]);
    const { showConfirmDialog } = useMutations(["showConfirmDialog"]);
    const { showErrorDialog } = useMutations(["showErrorDialog"]);

    const datos = ref();
    const nominas = ref("");
    const periodo = ref("");
    const cantidadDocumentos = ref(0);
    const transmisionRef = ref({});
    const empleadoId = ref("");
    const estadoNomina = ref(EstadoDetalleNomina);
    const tipoError = ref("");
    const estado = EstadoDetalleNomina;
    const entradaNomina = store.state.EntradaNomina;
    const token = localStorage.getItem("Tokensin");
    const consultaPorEN = ref(false);

    function GotoTransmission() {
      router.push({
        name: "Transmission",
      });
    }

    function DisabledBackNavigation() {
      window.onpopstate = function () {
        history.go(1);
      };
    }

    function Redirect(detalleNominaId, empleado, pagina) {
      setDetalleNomina({
        DetalleNominaId: detalleNominaId,
        TipoNomina: TipoNomina.NominaAjuste,
      });

      empleadoId.value = empleado;

      if (pagina == "PayrollDetail") {
        router.push({
          name: pagina,
        });
      } else {
        setStateLoading(true);
        store
          .dispatch("ValidarTransmision", localStorage.getItem("Tokensin"))
          .then((response) => {
            if (response.status == 204 && pagina == "NominaAjuste") {
              showConfirmDialog(true);
            }
            setStateLoading(false);
          })
          .catch((error) => {
            if (error.request.status == 400) {
              showErrorDialog(true);
              tipoError.value = error.request.response;
            }
            setStateLoading(false);
            console.log(error.request.response);
          });
      }
    }

    function ObtenerResumenTransmision() {
      setStateLoading(true);

      store
        .dispatch("ObtenerResumenTransmision", token)
        .then((resumen) => {
          if (entradaNomina.EntradaNominaId == "") {
            consultaPorEN.value = true;
            cantidadDocumentos.value =
              store.state.DetalleEntradaNomina.CantidadAprobados;
            periodo.value = store.state.DetalleEntradaNomina.Periodo;
          } else {
            cantidadDocumentos.value =
              store.state.EntradaNomina.CantidadAprobados;
            periodo.value = store.state.EntradaNomina.Periodo;
          }

          nominas.value = resumen.data.resumenValidacionNomina;

          if (!nominas.value.length > 0) {
            GotoTransmission();
          }
          setStateLoading(false);
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error.request.response);

          if (error.response.status == 404) {
            GotoTransmission();
          }
        });
    }

    onMounted(() => {
      showConfirmDialog(false);
      DisabledBackNavigation();
      ObtenerResumenTransmision();
    });

    return {
      nominas,
      cantidadDocumentos,
      periodo,
      estado,
      GotoTransmission,
      Redirect,
      entradaNomina,
      datos,
      TipoNomina,
      TipoNota,
      transmisionRef,
      store,
      empleadoId,
      estadoNomina,
      tipoError,
      consultaPorEN
    };
  },
};
</script>