import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { ValidateAccess } from '@/router/VerifyAcces'
import Dashboard from '../views/Dashboard.vue'
import Information from '../views/Information.vue'
import Initialize from '../views/Initialize.vue'
import Qualification from '../views/Qualification.vue'
import DueDiligenceR from '../views/RejectedDueDiligence.vue'
import LoadFile from '../views/LoadFile.vue'
import Transmission from '../views/Transmission.vue'
import ErrorTransmission from '../views/ErrorTransmission.vue'
import SummaryTransmission from '../views/SummaryTransmission.vue'
import PayrollDetail from '../views/PayrollDetail.vue'
import Contract from '../views/Contract.vue'
import DetalleTransmision from '../views/DetalleTransmision.vue'
import PayrollTransmission from '../views/PayrollTransmission.vue'
import Plans from '../views/Plans.vue'
import KushkiPaymentForm from '../views/KushkiPaymentForm.vue'
import PaylinePaymentForm from '../views/PaylinePaymentForm.vue'
import PlanControl from '../views/PlanControl.vue'
import KushkiBankResponse from '../views/KushkiBankResponse.vue'
import PaylineBankResponse from '../views/PaylineBankResponse.vue'
import WaitingPayment from '../views/WaitingPayment.vue'
import TransactionStory from '../views/TransactionStory.vue'
import UnAutorized from '../views/UnAutorized.vue'
import DueDiligence from '../views/DueDiligence.vue'
import EmployerContact from '../views/EmployerContact.vue'
import validationNeL from '../views/validationNeL.vue'
import LogoutInactivity from '../views/LogoutInactivity.vue'
import PayrollProcessingValidation from '../views/PayrollProcessingValidation.vue'
import Configuration from '../views/Configuration.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/Dashboard',
    name: 'Dashboard',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Dashboard
  },
  {
    path: '/Initialize/:id/:apliOrigen?',
    name: 'Initialize',
    component: Initialize
  },
  {
    path: '/Information',
    name: 'Information',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Information
  }, {
    path: '/LoadFile',
    name: 'LoadFile',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: LoadFile
  }, {
    path: '/Transmission',
    name: 'Transmission',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Transmission
  },
  {
    path: '/ErrorTransmission',
    name: 'ErrorTransmission',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: ErrorTransmission
  },
  {
    path: '/SummaryTransmission',
    name: 'SummaryTransmission',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: SummaryTransmission
  },
  {
    path: '/PayrollTransmission',
    name: 'PayrollTransmission',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: PayrollTransmission
  },
  {
    path: '/PayrollDetail',
    name: 'PayrollDetail',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: PayrollDetail
  },
  {
    path: '/Qualification',
    name: 'Qualification',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Qualification
  },
  {
    path: '/DueDiligenceR',
    name: 'DueDiligenceR',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: DueDiligenceR
  }, {
    path: '/Contract',
    name: 'Contract',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Contract
  },
  {
    path: '/Plans',
    name: 'Plans',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: Plans
  },
  {
    path: '/KushkiPaymentForm',
    name: 'KushkiPaymentForm',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    props: true,
    component: KushkiPaymentForm
  },
  {
    path: '/PaylinePaymentForm',
    name: 'PaylinePaymentForm',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    props: true,
    component: PaylinePaymentForm
  },
  {
    path: '/PlanControl',
    name: 'PlanControl',
    // beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    props: true,
    component: PlanControl
  },
  {
    path: '/TransactionStory',
    name: 'TransactionStory',
    // beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    props: true,
    component: TransactionStory
  },
  {
    path: '/WaitingPayment',
    name: 'WaitingPayment',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    props: true,
    component: WaitingPayment
  },
  {
    path: '/KushkiBankResponse',
    name: 'KushkiBankResponse',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: KushkiBankResponse
  },
  {
    path: '/PaylineBankResponse',
    name: 'PaylineBankResponse',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: PaylineBankResponse
  },
  {
    path: '/DetalleTransmision',
    name: 'DetalleTransmision',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: DetalleTransmision
  },
  {
    path: '/DueDiligence',
    name: 'DueDiligence',
    beforeEnter: ValidateAccess,
    meta: {
      requiresAuth: true
    },
    component: DueDiligence
  },
  {
    path: '/EmployerContact',
    name: 'EmployerContact',
    meta: {
      requiresAuth: true
    },
    component: EmployerContact
  },
  {
    path: '/UnAutorized',
    name: 'UnAutorized',
    meta: {
      requiresAuth: true
    },
    component: UnAutorized
  },
  {
    path: '/validationNeL',
    name: 'validationNeL',
    meta: {
      requiresAuth: true
    },
    component: validationNeL
  },
  {
    path: '/LogoutInactivity',
    name: 'LogoutInactivity',
    meta: {
      requiresAuth: false
    },
    component: LogoutInactivity
  },
  {
    path: '/PayrollProcessingValidation',
    name: 'PayrollProcessingValidation',
    meta: {
      requiresAuth: true
    },
    component: PayrollProcessingValidation
  },
  {
    path: '/Configuration',
    name: 'Configuration',
    meta: {
      requiresAuth: true
    },
    component: Configuration
  },
  { path: '/:pathMatch(.*)*', component: UnAutorized },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

/////// Valida la sesion
router.beforeEach((to) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem("Perfil") == null) {
      /*
      Conforme a la vulnerabilidad reportada por fortify, se revisa y se determina de que es un caso
      de 'falso positivo', por ende el código se puede dejar de esta manera
      */
      location.replace(process.env.VUE_APP_PortalEmpleador);
    }
  }
});


export default router
