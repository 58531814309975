import { computed, reactive } from 'vue'
import { required, helpers, requiredIf, maxLength, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";
import { CampoNuloOVacio, DatetimeToDate, DateToPeriod } from '@/helpers/helpers';

class NominaElectronicaValidacion {

    public ObtenerReglasValidacion() {
       
    const store = useStore();
    const { JsonNomina } = store.state.DetalleNomina;
    const periodo = store.state.DetalleEntradaNomina.Periodo;

    const nominaElectronica = reactive(JsonNomina);
    const mensajeError = "Campo obligatorio.";
    const maxInt = 2147483647;

    const ErrorFechasDePago = (paymentDates: string[]) => {
      return paymentDates.every(function (paymentDate: string) {
        return paymentDate == null || paymentDate == "";
      });
    };

    const ErrorPeriodoFecha = (fecha: string): boolean => {
      return DateToPeriod(fecha) !== periodo && !CampoNuloOVacio(fecha);
    };

    const ErrorFechaIngreso = (
      admissionDate: string,
      startSettlementDate: string
    ): boolean => {
      return (
        !CampoNuloOVacio(admissionDate) &&
        !CampoNuloOVacio(startSettlementDate) &&
        DatetimeToDate(DateToPeriod(admissionDate)) ==
          DatetimeToDate(DateToPeriod(startSettlementDate)) &&
        DatetimeToDate(admissionDate) != DatetimeToDate(startSettlementDate)
      );
    };

    const reglasNE = computed(() => {
      return {
        PayrollPeriodCode: {
          required: helpers.withMessage(`${mensajeError} NIE029`, required),
        },
        PayrollStartSettlementDate: {
          required: helpers.withMessage(
            `${mensajeError} NIE004`,
            requiredIf(CampoNuloOVacio(nominaElectronica.StartSettlementDate))
          ),
          period: helpers.withMessage(
            `La fecha seleccionada, no coincide con el periodo de transmisión.`,
            requiredIf(ErrorPeriodoFecha(nominaElectronica.StartSettlementDate))
          ),
          admissionDate: helpers.withMessage(
            `La fecha de ingreso: ${DatetimeToDate(
              nominaElectronica.AdmissionDate
            )}, debe ser igual a la fecha inicio pago NE`,
            requiredIf(
              ErrorFechaIngreso(
                nominaElectronica.AdmissionDate,
                nominaElectronica.StartSettlementDate
              )
            )
          ),
        },
        PayrollEndSettlementDate: {
          required: helpers.withMessage(
            `${mensajeError} NIE005`,
            requiredIf(CampoNuloOVacio(nominaElectronica.EndSettlementDate))
          ),
          period: helpers.withMessage(
            `La fecha seleccionada, no coincide con el periodo de transmisión.`,
            requiredIf(ErrorPeriodoFecha(nominaElectronica.EndSettlementDate))
          ),
        },
        PayrollPaymentDates: {
          required: helpers.withMessage(
            "Debe ingresar al menos una fecha de pago. NIE203",
            requiredIf(ErrorFechasDePago(nominaElectronica.PaymentDates))
          ),
        },
        PaymentMeanCode: {
          required: helpers.withMessage(`${mensajeError} NIE064`, required),
        },
        PaymentMethodCode: {
          required: helpers.withMessage(`${mensajeError} NIE065`, required),
        },
        Bank: {
          required: helpers.withMessage(
            "Longitud máxima de 100 caracteres. NIE066",
            maxLength(100)
          ),
        },
        AccountNumber: {
          required: helpers.withMessage(
            "Longitud máxima de 60 caracteres. NIE068",
            maxLength(60)
          ),
        },
        CurrencyCode: {
          required: helpers.withMessage(`${mensajeError} NIE030`, required),
        },
        Trm: {
          required: helpers.withMessage(
            "Valor inválido. NIE200",
            maxValue(maxInt)
          ),
        },
      };
    });

    return [nominaElectronica, reglasNE] as const;

    }
}

export default NominaElectronicaValidacion;