

import useVuelidate from '@vuelidate/core';
import { defineComponent, onUpdated } from 'vue';
import InfoAccionista_JuridicoValidacion from '../../validation/InfoAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';
import func from '@/validation/GeneralRules';

export default defineComponent({
    props: {
        scopeInfoAccionista_Juridico: String,
        parametricas: {}
    },
    setup(props) {
        
        let [objetoInfoAccionista_Juridico, reglasValidacion] = new InfoAccionista_JuridicoValidacion().ObtenerReglasValidacion();

        const AgregarFilaAJ = () => {

            objetoInfoAccionista_Juridico.Aj.push({
                NombreAJ: '',
                TipoIdAJ: '',
                NumeroIdentificacionAJ: '',
                ParticipacionCapitalAJ: '',
                PaisAJ: ''
            }); 
        }

        const EliminarFilaAJ = (index: number) => {
            objetoInfoAccionista_Juridico.Aj.splice(index, 1);
        }


        const reiniciarObjetoInfoAccionista_Juridico = () => {
          objetoInfoAccionista_Juridico.Aj = [];
          AgregarFilaAJ();
        }

        const CharPercentage = (e:any,index:number) => {
          if(e != '')
          {
              if(!e.includes('%'))
            {
                objetoInfoAccionista_Juridico.Aj[index].ParticipacionCapitalAJ = objetoInfoAccionista_Juridico.Aj[index].ParticipacionCapitalAJ + ' %';
            }     
          }         
        }

        const validacionInfoAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoAccionista_Juridico, { $scope: props.scopeInfoAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onUpdated(() => {
            window.scrollTo(0, 0);
})


        return { 
          AgregarFilaAJ, 
          EliminarFilaAJ,
          validacionInfoAccionista_Juridico, objetoInfoAccionista_Juridico, reiniciarObjetoInfoAccionista_Juridico,func,CharPercentage,
          tiposIdentificacion
        }
    },
})

