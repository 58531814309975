

import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import InfoRepresentanteSuplenteValidacion from '../../validation/InfoRepresentanteSuplente';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentanteSuplente: String,
        parametricas: {}
    },
    setup(props) {

        let [objetoInfoRepresentanteSuplente, reglasValidacion] = new InfoRepresentanteSuplenteValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLSuplente = () => {

            objetoInfoRepresentanteSuplente.RLSuplente.push({
                NombreRLSuplente: '',
                TipoIdRLSuplente: '',
                NumeroIdentificacionRLSuplente: '',
                CargoEmpresaRLSuplente: '',
                CorreoRLSuplente: '',
                EsPEPRLSuplente: '',
                VinculosFamPEP_RLSuplente: ''

            }); 
        }

        const EliminarFilaRLSuplente = (index: number) => {
            objetoInfoRepresentanteSuplente.RLSuplente.splice(index, 1);
        }

        const validacionInfoRepresentanteSuplente = useVuelidate(reglasValidacion, objetoInfoRepresentanteSuplente, { $scope: props.scopeRepresentanteSuplente })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        return { 
          AgregarFilaRLSuplente,  
          EliminarFilaRLSuplente, 
          objetoInfoRepresentanteSuplente,
          validacionInfoRepresentanteSuplente,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPRS()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoRepresentanteSuplente.RLSuplente.length; i++)
            {
                if(this.objetoInfoRepresentanteSuplente.RLSuplente[i].EsPEPRLSuplente.includes("Si") || this.objetoInfoRepresentanteSuplente.RLSuplente[i].VinculosFamPEP_RLSuplente.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepRS',Result)

        }
    }

})

