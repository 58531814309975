<template>

    <span class="p-input-icon-left">
      <i class="pi pi-search" />
      <input type="text" v-model="contiene['global'].value"  class='input-search'/>
    </span>

</template>

<script>

import { ref } from "@vue/reactivity";
import {FilterMatchMode} from 'primevue/api';

export default {

  setup() {

    const contiene = ref({'global': 
    {
      value: null, 
      matchMode: FilterMatchMode.CONTAINS
    }});

    return {
      contiene
    };
  },
};

</script>
 