<template>

  <ResumenEmpleado
    v-if="empleado && detalle"
    :empleado="empleado[0]"
    :detalle="detalle"
    :fechaIngreso="fechaIngreso"
    :fechaAprobacion="fechaAprobacion">
  </ResumenEmpleado>

  <DataTable 
    class="big-table"
    :rows="5" 
    :value="nomina" 
    :paginator="true" 
     responsiveLayout="scroll" 
    :rowsPerPageOptions="[5,10,20,50,100]">

    <Column
      :sortable="true"
      field="TipoHoraExtra"
      header="Tipo hora extra"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="CantidadHorasExtras"
      header="Cantidad horas extras"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="PagoHoraExtra"
      header="Pago horas extras"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="TipoIncapacidad"
      header="Tipo de incapacidad"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="PagoIncapacidad"
      header="Pago incapacidad"
      bodyClass="datatable-text-center">
    </Column>


    <Column
      :sortable="true"
      field="TipoVacaciones"
      header="Tipo vacaciones"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="InicioVacaciones"
      header="Inicio vacaciones"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="FinVacaciones"
      header="Fin vacaciones"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="PagoVacaciones"
      header="Pago vacaciones"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="TipoLicencia"
      header="Tipo licencia"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="InicioLicencia"
      header="Inicio licencia"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="FinLicencia"
      header="Fin licencia"
      bodyClass="datatable-text-center">
    </Column>

    <Column
      :sortable="true"
      field="PagoLicencia"
      header="Pago licencia"
      bodyClass="datatable-text-center">
    </Column>

  </DataTable>

</template>

<script>

import moment from "moment";
import { onMounted, ref } from "vue";
import { AsignarFormatoPesos } from "@/helpers/helpers";
import ResumenEmpleado from "@/components/DetalleNomina/ResumenEmpleado.vue";

export default {
  name: "DeduccionesNomina",
  components: {
    ResumenEmpleado,
  },
  props: {
    empleado: {},
    deducciones: {},
    detalle: {},
    fechaIngreso: String,
    fechaAprobacion: String
  },
  setup(props) {

    const empleado = ref();
    const deducciones = ref();
    const detalle = ref();
    const nomina = ref([]);
    const fechaIngreso = props.fechaIngreso;
    const fechaAprobacion = props.fechaAprobacion;

    onMounted(() => {
      
      empleado.value = props.empleado;
      deducciones.value = props.deducciones;
      detalle.value = props.detalle;

      //Horas extra.
      deducciones.value.ExtraHours?.forEach((horaExtra) => {
        nomina.value.push({
          TipoHoraExtra: horaExtra.SurchargeTypeCode,
          CantidadHorasExtras: horaExtra.Quantity,
          PagoHoraExtra: AsignarFormatoPesos(horaExtra.Total),
        });
      });

      //Incapacidades
      deducciones.value.Inabilities?.forEach((incapacidad) => {
        nomina.value.push({
          TipoIncapacidad: incapacidad.InabilityTypeCode,
          PagoIncapacidad: AsignarFormatoPesos(incapacidad.Total),
        });
      });

      //Vacaciones
      deducciones.value.Holidays?.forEach((vacaciones) => {
        nomina.value.push({
          TipoVacaciones: vacaciones.HolidayTypeCode,
          InicioVacaciones: vacaciones.StartDate
            ? moment(vacaciones.StartDate).format("YYYY-MM-DD") : "",
          FinVacaciones: vacaciones.EndDate
            ? moment(vacaciones.EndDate).format("YYYY-MM-DD") : "",
          PagoVacaciones: AsignarFormatoPesos(vacaciones.Total),
        });
      });

      //Licencias
      deducciones.value.Licenses?.forEach((licencia) => {
        nomina.value.push({
          TipoLicencia: licencia.LicenseTypeCode,
          InicioLicencia: licencia.StartDate
            ? moment(licencia.StartDate).format("YYYY-MM-DD") : "",
          FinLicencia: licencia.EndDate
            ? moment(licencia.EndDate).format("YYYY-MM-DD") : "",
          PagoLicencia: AsignarFormatoPesos(licencia.Total ?? ""),
        });
      });
    });

    return { empleado, nomina, detalle,fechaIngreso, fechaAprobacion };
  },
};
</script>
