import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class VacacionesValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const holidays = reactive({
      items: store.state.DetalleNomina.JsonNomina.Holidays,
    });
    const vacaciones = reactive(
      store.state.DetalleNomina.JsonNomina.Holidays
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido. NIE109 a NIE116";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          HolidayTypeCode: {
            required: helpers.withMessage(campoObligatorio, required),
          },
          Quantity: {
            required: helpers.withMessage(`${campoObligatorio} NIE111`, required),
            maxValue: helpers.withMessage(valorInvalido, maxValue(99)),
          },
          Total: {
            required: helpers.withMessage(`${campoObligatorio} NIE112`, required),
            maxValue: helpers.withMessage(
              "Valor inválido. NIE112-NIE116",
              maxValue(maxInt)
            ),
          },
        }),
      },
    };

    return [holidays, vacaciones, reglas] as const;
  }
}

export default VacacionesValidacion;
