
import InputNumber from "primevue/inputnumber";
import TotalNominaValidacion from "@/validation/PayrollTransmission/TotalNomina";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [totalNomina, validaciones] =
      new TotalNominaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, totalNomina, {
      $scope: props.nomina,
    });

    return {
      totalNomina,
      v$,
    };
  },
};
