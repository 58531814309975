

import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import InfoRepresentantePepValidacion from '../../validation/InfoRepresentantePep';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentantePep: String,
        HabilitaCamposPep: Boolean,
        parametricas: {}
    },
    setup(props) {

        let [objetoInfoRepresentantePep, reglasValidacion] = new InfoRepresentantePepValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLPEP = () => {

            objetoInfoRepresentantePep.RLPep.push({
                NombreRLPEP: '',
                NombrePEP: '',
                TipoIdPEPRL: '',
                NumeroIdentificacionPEPRL: '',
                TipoVinculoPEPRL: '',
                TipoPEPRL: ''
            }); 
        }

        const EliminarFilaRLPEP = (index: number) => {
            objetoInfoRepresentantePep.RLPep.splice(index, 1);
        }

        const reiniciarObjetoInfoPepRepresentante = () => {
          objetoInfoRepresentantePep.RLPep = [];
          AgregarFilaRLPEP();
        }

        const validacionInfoRepresentantePep = useVuelidate(reglasValidacion, objetoInfoRepresentantePep, { $scope: props.scopeRepresentantePep })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]


        return { 
          AgregarFilaRLPEP,  
          EliminarFilaRLPEP, 
          reiniciarObjetoInfoPepRepresentante,
          validacionInfoRepresentantePep, objetoInfoRepresentantePep,
          tiposIdentificacion
        }
    }
})

