
  import { useStore } from "vuex"
  import { useMutations } from '@/store/helper'
  import { ref, reactive } from 'vue'

  export default {
    name: "Rejected",
    components: {
    },
    setup(props: any, { emit }: any) {
      let messageError = ref(ObtenerMensaje());
      let ValidationVisible = ref(ObtenerEstadoModal());
      let ruleForm = reactive({
        TestSetValue: ''
      });
      let rules = reactive({
        TestSetValue: [
          { required: true, message: 'El código de habilitación no puede estar vacío', trigger: 'blur' },
          { min: 1, message: 'El código de habilitación no puede estar vacío', trigger: ['blur', 'change'] },
          { pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i, message: 'El código de habilitación no es válido. Por favor verifica que no contenga espacios en blanco o caracteres especiales diferentes al guion', trigger: 'blur' }
        ],
      });
      const formRef = ref<HTMLFormElement>();
      const store = useStore();
      const { setTestSet } = useMutations(['setTestSet']);

      function EviarTestset() {
        formRef.value?.validate((valid: boolean) => {
          if (valid) {
            var data = {
              Identificacion: localStorage.getItem('Identificacion'),
              Tipoidentificacion: localStorage.getItem('TipoIdentificacion'),
              TestSetDian: ruleForm.TestSetValue,
            }
            setTestSet(data);
            emit("onFire", "EnProceso")
            store.dispatch('SendTestSet', localStorage.getItem('Tokensin')).then(data => {
            }).catch(error => {
              if (error.response.status == 400) {
                localStorage.setItem("mensajeErrorHabilitacion", error.request.response);
                localStorage.setItem("mostrarModal", "true");
                emit("onFire", "Rechazado");
              }
            });
          }
        });
      };
      function ObtenerMensaje(): string {
        let mensajeError = localStorage.getItem("mensajeErrorHabilitacion");
        if (mensajeError != null) {
          localStorage.removeItem("mensajeErrorHabilitacion");
          return mensajeError;
        }
        return "";
      };
      function ObtenerEstadoModal(): boolean {
        let modalActivo = (localStorage.getItem("mostrarModal") == "true");
        if (modalActivo != null) {
          localStorage.removeItem("mostrarModal");
          return modalActivo;
        }
        return false;
      };
      return {
        EviarTestset,
        rules,
        ruleForm,
        formRef,
        messageError,
        ValidationVisible
      };
    }
  };
