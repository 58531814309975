<template>

    <div class="Table_tableHeader">
    <table class="Table_tableContent">
      <thead>
        <tr>
          <th class="Table_xxs Table_tGreen">{{ titulo }}</th>
          <th class="Table_xs Table_tColorDegraded">Periodo</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="Table_xs Table_tgray">{{ cantidad  }}</td> 
          <td class="Table_xs Table_tgray">{{ periodo }}</td>
        </tr>
      </tbody>
    </table>
  </div>

</template>

<script>

import { onMounted } from "vue";
import { ref } from "@vue/reactivity";

export default {
  name: "TransmissionHeader",
  props: {
    cantidad: Number,
    periodo: String,
    titulo: String,
  },
  setup(props) {

    const cantidad = ref(0);
    const periodo = ref('');
    const titulo = ref('');

    onMounted(() => {
      cantidad.value = props.cantidad;
      periodo.value = props.periodo;
      titulo.value = props.titulo
    })

    return {
      cantidad,
      periodo,
      titulo
    }
  },
}

</script>
