<template>

   <DataTable 
    responsiveLayout="scroll" 
    :filters="datos?.contiene"
    :rows="5" 
    :value="errores" 
    :paginator="true" 
    :rowsPerPageOptions="[5,10,20,50,100]">

    <div class="divContainerButton" v-if="featureFlag">
      <input
        type="button"
        value="Preguntas frecuentes"
        class="Button_BColorDegradedOrange Button_DimensionOrange"
        @click="LandingPage()"
        />
        <Filtro ref="datos"></Filtro>
        <DescargarExcel  
        :data="errores"
        :name="'Errores_Transmisión_' + periodo"
        :json_fields="json_fields">
        </DescargarExcel>
    </div>
    <div v-else>
      <Filtro ref="datos"></Filtro>
    </div>

    <Column field="TipoIdentificacionEmpleado" header="Tipo ID" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="NumeroIdentificacionEmpleado" header="No. ID" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="HojaArchivo" header="Ubicación" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="ValorErrado" header="Valor reportado" :sortable="true"></Column>
    <Column field="MensajeErrorUsuario" header="Descripción" :sortable="true"></Column>

  </DataTable>

</template>

<script>

import { onMounted } from 'vue';
import { ref } from "@vue/reactivity";
import Filtro from '../layout/Filtro.vue';
import { useStore } from "vuex";
import DescargarExcel from "@/components/DetalleTransmission/DescargarExcel.vue";

export default {
  name: "ErrorValidacionNomina",
  components: {
    Filtro,
    DescargarExcel
  },
  props: {
    errores: {},
    periodo: String,
  },
  setup(props) {
    const store = useStore();
    const errores = ref('');
    const datos = ref();
    const periodo = ref('');
    
    const LandingPage = () => {
      const urls =
      process.env.VUE_APP_PreguntasFrecuentes;
      window.open(urls, "_blank");
    };
    let featureFlag = ref(false);

    function GetFeatureFlag()
    {
    const data = 
    {
      token: localStorage.getItem('Tokensin'),
      featureFlagName: 'PBI-232825'
    }
    store.dispatch('GetStatusFeatureFlag', data).then(response => {
      featureFlag.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
  }
    onMounted(() => {
      periodo.value = props.periodo;
      if (props.errores?.mensajeError != null) {
        errores.value = JSON.parse(props.errores.mensajeError);
      }
      GetFeatureFlag();
    });

    return {
      errores,
      periodo,
      datos,
      LandingPage,
      featureFlag,
      GetFeatureFlag,
      json_fields: {
                "Tipo ID": "TipoIdentificacionEmpleado",
                "No. ID": "NumeroIdentificacionEmpleado",
                "Ubicación": "HojaArchivo",
                "Valor reportado": "ValorErrado",
                "Descripción": "MensajeErrorUsuario"
              }     
    };
  },
};

</script>
 