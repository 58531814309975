export const DocumentTypes = [
    {
        code: "CC",
        documentName: "Cedula de ciudadanía",
    },
    {
        code: "NIT",
        documentName: "NIT",
    },
    {
        code: "CE",
        documentName: "Cedula de extranjería",
    },
    {
        code: "TI",
        documentName: "Tarjeta de identidad",
    },
    {
        code: "PP",
        documentName: "Pasaporte",
    },
];
