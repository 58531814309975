import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class CABValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const epctv = reactive(
      store.state.DetalleNomina.JsonNomina.EpctvBonuses
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasEpctv = computed(() => {
      return {
        Total: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE151`,
            requiredIf(epctv.length > 0 ? epctv[0].Total > maxInt : false)
          ),
        },
        NonSalaryTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE152`,
            requiredIf(
              epctv.length > 0 ? epctv[0].NonSalaryTotal > maxInt : false
            )
          ),
        },
        FeedingTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE153`,
            requiredIf(
              epctv.length > 0 ? epctv[0].FeedingTotal > maxInt : false
            )
          ),
        },
        NonSalaryFeedingTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE154`,
            requiredIf(
              epctv.length > 0 ? epctv[0].NonSalaryFeedingTotal > maxInt : false
            )
          ),
        },
      };
    });

    return [epctv, reglasEpctv] as const;
  }
}

export default CABValidacion;