import axios from 'axios'
import { Module } from "vuex"
const apiPagos = process.env.VUE_APP_Gateway_API + "Pago/";

const paymentModule: Module<any, any> = {
    actions: {
        GetPublicMerchantId({ commit, dispatch }, token) {
            let config = {
                headers: {
                    Token: token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}GetPublicMerchantId`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetStatePILARegister({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.Token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}GetEstadoRegistroPila?TipoIdentificacion=${data.TipoIdentificacion}&Identificacion=${data.Identificacion}`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetStatePlan({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.Token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}GetDocumentosTransmitidos?TipoIdentificacion=${data.TipoIdentificacion}&Identificacion=${data.Identificacion}`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetHistorialTransacciones({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.Token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}GetHistorialTransacciones?TipoIdentificacion=${data.TipoIdentificacion}&Identificacion=${data.Identificacion}`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetPaymentInfo({ commit, dispatch }, data) {
            let tokenAEL = data.tokenAEL
            let config = {
                headers: {
                    Token: tokenAEL
                }
            }

            data.tokenAEL = null;
            return new Promise((resolve, reject) => {
                axios.post(`${apiPagos}GetInformacionPago`, data, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetUrlPayment({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.tokenHeader
                }
            }
            return new Promise((resolve, reject) => {
                axios
                    .post(`${apiPagos}IniciarTransaccion`, data, config)
                    .then((response: any) => {
                        resolve(response);
                    })
                    .catch((err) => {
                        reject(err)
                    });
            })
        },
        GetListPlans({ commit, dispatch }, token) {
            let config = {
                headers: {
                    Token: token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}GetPlanes`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        ObtenerTransaccionMasReciente({ commit, dispatch }, entradaConsulta) {
            let config = {
                headers: {
                    Token: entradaConsulta.token,             
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}ObtenerTransaccionMasReciente?proveedorPago=${entradaConsulta.proveedorPago}`, config)
                    .then((response) => {
                        resolve(response);
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        GetPasarelaIdentificationTypes({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}ObtenerTiposIdentificacionPasarela?tipoIdentificacion=${data.tipoIdentificacion}&numeroIdentificacion=${data.identificacion}`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        },
        GetPasarelaBanks({ commit, dispatch }, data) {
            let config = {
                headers: {
                    Token: data.token
                }
            }
            return new Promise((resolve, reject) => {
                axios.get(`${apiPagos}ObtenerBancosPasarela?tipoIdentificacion=${data.tipoIdentificacion}&numeroIdentificacion=${data.identificacion}`, config)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err)
                    })
            })
        }
    }
}

export default paymentModule;