import { reactive } from 'vue'
import { required, helpers, email } from '@vuelidate/validators'

class InfoRepresentanteSuplenteValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentanteSuplente = reactive({
             RLSuplente:[{
                NombreRLSuplente: '',
                TipoIdRLSuplente: '',
                NumeroIdentificacionRLSuplente: '',
                CargoEmpresaRLSuplente: '',
                CorreoRLSuplente: '',
                EsPEPRLSuplente: '',
                VinculosFamPEP_RLSuplente: ''
             }]
         });

         const emailMessage = 'valide formato e-mail';
         
         const reglasValidacion = {
            RLSuplente: {
                 $each: helpers.forEach({
                    CorreoRLSuplente: {
                        email: helpers.withMessage(emailMessage, email)
                     },
                })
             }     
         };

        return [objetoInfoRepresentanteSuplente,reglasValidacion] as const;
    }
}

export default InfoRepresentanteSuplenteValidacion;