
import { onMounted, ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import SelectButton from "primevue/selectbutton";
import { TipoDeduccion } from "@/models/Payroll/Enums";
import { TabLiquidacion } from "@/models/Payroll/Enums";
import Sspta from "@/components/TransmisionNomina/Sspta.vue";
import FondoSP from "@/components/TransmisionNomina/FondoSP.vue";
import SaludPension from "@/components/TransmisionNomina/SaludPension.vue";
import OtrasDeducciones from "@/components/TransmisionNomina/OtrasDeducciones.vue";

export default {
  components: {
    TabView,
    TabPanel,
    FondoSP,
    Sspta,
    SelectButton,
    SaludPension,
    OtrasDeducciones,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const tabs = TabLiquidacion;
    const activePanel = ref();
    const activeTab = ref(tabs.Empleado);
    const scopeNomina = props.nomina;
    const selectedButton = ref();

    const firstOption = {
      value: TipoDeduccion.SaludPension,
      number: 1,
      tooltip: "Salud y pensión",
    };

    const options = ref([
      firstOption,
      {
        value: TipoDeduccion.FondoSP,
        number: 2,
        tooltip: "Fondo de seguridad pensional",
      },
      {
        value: TipoDeduccion.SSPTA,
        number: 3,
        tooltip: "Sindicato, sanciones, pago tercero y anticpo",
      },
      {
        value: TipoDeduccion.OtrasDeducciones,
        number: 4,
        tooltip: "Otras deducciones",
      },
    ]);

    const SelectedOption = () => {
      if (selectedButton.value?.value >= TipoDeduccion.SaludPension) {
        activePanel.value = selectedButton.value?.value;
      }
    };

    onMounted(() => {
      activePanel.value = TipoDeduccion.SaludPension;
      selectedButton.value = firstOption;
      selectedButton.value.value = activePanel.value;
    });

    return {
      options,
      activeTab,
      scopeNomina,
      activePanel,
      TipoDeduccion,
      selectedButton,
      SelectedOption,
    };
  },
};
