
import InputNumber from "primevue/inputnumber";
import EpctvValidacion from "@/validation/PayrollTransmission/Epctv";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";

import { onBeforeMount } from "vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [epctv, validaciones] = new EpctvValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, epctv, {
      $scope: props.nomina,
    });

    const ValidarEpctv = () => {
      if (epctv.length == 0) {
        epctv.push({
          Total: null,
          NonSalaryTotal: null,
          FeedingTotal: null,
          NonSalaryFeedingTotal: null,
        });
      }
    };

    onBeforeMount(() => {
      ValidarEpctv();
    });

    return {
      epctv,
      v$,
    };
  },
};
