
import {useStore} from "vuex"
import{useMutations} from '@/store/helper'
import { ref } from '@vue/reactivity';
import { watch} from '@vue/runtime-core';
import router from '@/router';
export default {
  name:"Approved",
    components:{
    },
    setup() {
      const store= useStore();
      const dialogVisible=ref(false);
      const checkTerminos= ref(false);
      const Error= ref(false);
      const GotoCorreoProteccionDeDatos= ref("mailto:protecciondedatos@aportesenlinea.com");
      const Gotoaportes= ref("https://www.aportesenlinea.com");
      const styleCheckError= ref({'border': '3px solid red' });
      const{setTratamientoDatos} = useMutations(['setTratamientoDatos']);
      const aplicativo = localStorage.getItem('AplicativoOrigen');

      function goToDashboard(){
        var data={
            Identificacion:localStorage.getItem('Identificacion'),
            Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
            EstadoAporbacion:checkTerminos      
         }  
        setTratamientoDatos(data);
          store.dispatch('SendStateQualificationEmpleador',localStorage.getItem('Tokensin')).then(()=>{      
            store.dispatch('obtenerUrlDebidaDiligencia').then((response) => {
              window.open(response,"_self");
            }).catch(error=>{
                  console.log(error);
            });
          }).catch(error=>{
                  console.log(error);
          });
           
        }

      function Validarcheck(){
        if(aplicativo == '1'){
          router.push({name:'validationNeL'});
        }
        else{
          if(checkTerminos.value){
             goToDashboard();
          }else{
             Error.value=true;
          }
        }
      }
      watch(()=>checkTerminos.value,()=>{
        if(checkTerminos.value){
            Error.value=false;
          }else{
            Error.value=true;
          }
      }); 
    return{
      dialogVisible,
      checkTerminos,
      styleCheckError,
      Error,
      Validarcheck,
      Gotoaportes,
      GotoCorreoProteccionDeDatos,
      aplicativo
    };
  }
    
};
