<template>
    <footer class="footer">
        <div class="row">
            <div >
                <div class="footer_navigation">
                    <div>
                        <ul class="footer_list">
                            <li class="footer_item"><a :href="url+'QuienesSomos.aspx'" class="footer_link" target="_blank">¿Quiénes somos?</a></li>
                            <li class="footer_item"><a :href="urllandingpage" class="footer_link" target="_blank">¿Qué es Nómina Electrónica?</a></li>
                            <li class="footer_item"><a :href="url+'Contacto.aspx?MItem=Cntct'" class="footer_link" target="_blank">Preguntas frecuentes</a></li>
                            <li class="footer_item"><a :href="url+'NormasRelevantes.aspx'" class="footer_link" target="_blank">Documentos y normas</a></li>
                        </ul>
                        <ul class="footer_list">
                            <li class="footer_item"><a :href="url+'ServiciosQueOfrecemos.aspx'" class="footer_link" target="_blank">Servicios que ofrecemos</a></li>
                            <li class="footer_item"><a :href="url+'porqueAporteEnLinea.aspx'" class="footer_link" target="_blank">¿Por qué elegirnos?</a></li>
                            <li class="footer_item"><a :href="url+'Contacto.aspx'" class="footer_link" target="_blank">Contáctenos</a></li>
                        </ul>
                    </div>
                    <div class="footer_image">
                        <a :href="url+'Contacto.aspx?MItem=Cntct'" target="_blank"><img src="../../assets/img/preguntenos.png" alt="logo" class="footer_image_item" ></a>
                        <a rel="noopener noreferrer" href="https://www.facebook.com/aportesenlinea.noticias" target="_blank"><img src="../../assets/img/facebook.png" alt="logo" class="footer_image_item" ></a>
                        <a rel="noopener noreferrer" href="https://twitter.com/APORTESENLINEA" target="_blank"><img src="../../assets/img/twitter.png" alt="logo" class="footer_image_item"></a>
                        <a rel="noopener noreferrer" href="https://www.youtube.com/user/aportesenlinea" target="_blank"><img src="../../assets/img/youtube.png" alt="logo" class="footer_image_item"></a>
                    </div>
                    <div class="col">
                        <p class="footer_copyright">
                            Copyright &copy; {{year}} Aportes en Línea. Todos los derechos reservados.
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </footer>
</template>

<script>
export default {
    name:"Footer",
    setup() {
        let year=new Date().getFullYear();
        let url= process.env.VUE_APP_PortalEmpleador+"/Home/";
        let urllandingpage=process.env.VUE_APP_LandingPage;
    return{
      year,
      url,
      urllandingpage
    };
  }
};
</script>
