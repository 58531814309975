
import {useStore} from "vuex";
import { onMounted,ref} from 'vue';
import{ useRoute } from 'vue-router';
import router from '@/router';
import Spinner from "@/components/layout/Spinner.vue";
import { PaymentState } from '@/store/Payment/plans';
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";
import EstadoEmpleador from "../models/EstadoEmpleador";
import { useMutations } from '@/store/helper';
import { GetAndSetStatusFeatureFlag } from "@/helpers/helpers";
export default {
  name:"Initialize",
    components:{
      Spinner
    },
    setup() {
      const loading = ref(false);
      const store= useStore();
      const { updateContent } = useMutations(['updateContent']);
      const route = useRoute();
      var Error=ref('');
      var UrlDebidaDiligencia = ref('');
      const estadoActualNE = ref('');
      const apliOrigen = ref('');
      localStorage.setItem('Tokensin',""+route.params.id+"");
        
      function statePaymentRedirect(isRestrictedProfile: boolean){
          let params = {
            Token : route.params.id,
            TipoIdentificacion: localStorage.getItem('TipoIdentificacion'),
            Identificacion:  localStorage.getItem('Identificacion')
          }
          let paymentToPlan = [PaymentState.SinProceso, PaymentState.RechazadoPago]
          store.dispatch('GetStatePILARegister', params).then(
            (res) => {
              if(isRestrictedProfile) {
                if(res.data == PaymentState.AprobadoPago){
                  contactDataRedirect('Transmission');
                } else {
                  router.push({name:'WaitingPayment', params: {
                    isRestricted: "true"
                  }})
                }
              } else {
                if(paymentToPlan.includes(res.data)){
                  contactDataRedirect('Plans');
                } else if(res.data == PaymentState.EnProceso) {
                  router.push({name:'WaitingPayment'});
                } else if(res.data == PaymentState.AprobadoPago){
                  contactDataRedirect('Transmission');
                }
              }
            }
          ).catch((err) => console.log(err))
      }
      function ValidarDebidaDiligencia()
      {
        store.dispatch('ObtenerTokenDD',route.params.id).then(data=>{
          loading.value = true;
          ValidarEstadoDebidaDiligencia(data.data);
        }).catch(error=>{
                 loading.value = false;
                 console.log(error);
                 router.push({name:'UnAutorized'});
                 });
      }
      function ValidarEstadoDebidaDiligencia(tokenDD:string)
      {
        let ParametrosEmpleadorDD = {
                        TipoDocumento: localStorage.getItem('TipoIdentificacion'),
                        NumeroDocumento: localStorage.getItem('Identificacion'),  
                        TokenDD: tokenDD               
                    }
        store.dispatch('ObtenerEstadoEmpleadorDD',ParametrosEmpleadorDD).then(i=>{       
          const estadoMapping = {         
            [EstadoDebidaDiligencia.CreadaDD]: EstadoEmpleador.FormularioDebidaDiligencia,         
            [EstadoDebidaDiligencia.AprobadaDD]: EstadoEmpleador.AprobadoDebidaDiligencia,         
            [EstadoDebidaDiligencia.RechazadaDD]: EstadoEmpleador.RechazadoDebidaDiligencia,         
            [EstadoDebidaDiligencia.ParaCrearNuevaDD]: EstadoEmpleador.AceptoTratamientoDatos,         
            [EstadoDebidaDiligencia.NoDefinido]: () => ConstruirUrlParaDebidaDiligencia(tokenDD)
          };     

          const estadoDD = i.data;     
          const accion = estadoMapping[estadoDD];

          if(estadoActualNE.value === "AceptoTyC")
          {
            statePaymentRedirect(false);
          }else{
            if (accion) {         
              if(typeof accion === 'function'){
                accion()
              }else{
                ActualizarEstadoEmpleador(accion, tokenDD);     
              }           
            }
          }                 
        }).catch(error=>{
            loading.value = false;
            console.log(error);
            router.push({name:'UnAutorized'});
      });
    }
    function ActualizarEstadoEmpleador(nuevoEstado:number,tokenDD:string) 
    {
      let parametrosActualizarEmpleador = {
                      tipoDocumento: localStorage.getItem('TipoIdentificacion'),
                      numeroDocumento: localStorage.getItem('Identificacion'),
                      nuevoEstado: nuevoEstado,
                      token: route.params.id
                  }

        store.dispatch('UpdateEmployeerStatus',parametrosActualizarEmpleador).then((j) => {       
                 
              if(j.status == 200 
                && parametrosActualizarEmpleador.nuevoEstado == 8)
                {      
                  loading.value = false;          
                  router.push({ name: "Dashboard" });
                }
                else if(j.status == 200 
                    && parametrosActualizarEmpleador.nuevoEstado == 4)     
                {
                  loading.value = false;
                  router.push({ name: "Contract" });
                }
                else if(j.status == 200 
                && parametrosActualizarEmpleador.nuevoEstado == 5)
                {
                  loading.value = false;
                  router.push({name:'DueDiligenceR'});
                } 
                else if(j.status == 200 
                && parametrosActualizarEmpleador.nuevoEstado == 6)
                {
                  ConstruirUrlParaDebidaDiligencia(tokenDD); 
                }          
        })
        .catch((error) => {
          console.log(error);
        });
    }

      function ConstruirUrlParaDebidaDiligencia(tokenDD:string)
      {
        let ParametrosParaCifrar = {
              TipoDocumento: localStorage.getItem('TipoIdentificacion'),
              NumeroDocumento: localStorage.getItem('Identificacion'), 
              RazonSocial: localStorage.getItem('RazonSocial'),
              IdProducto: 1, 
              TokenDD: tokenDD 
              }

        store.dispatch('CifrarDatosEmpleadorParaDD',ParametrosParaCifrar).then((k) => {
            UrlDebidaDiligencia.value = process.env.VUE_APP_PortalDebidaDiligencia+"DueDiligence/"
            +k.data.valorCifrado
            
            loading.value = false;
            window.open(UrlDebidaDiligencia.value,"_self");
        })
        .catch(error=>{
                 loading.value = false;
                 console.log(error);
                 router.push({name:'UnAutorized'});
                 });
      }
      
      
      function contactDataRedirect(viewName:string){
        store.dispatch('ValidateContactData', route.params.id).then(
          (res) => {
            localStorage.setItem('TieneDatosContacto', res.data);
            localStorage.setItem('DatosContactoRedireccion', viewName);
            if (res.data){
              router.push({name:viewName});
            } else {
              router.push({name:'EmployerContact'});
            }
          }
        ).catch((err) => {
          console.log(err);
          router.push({name:viewName});
        })
      } 
      onMounted(() => {
        loading.value = true;    
        var params = {
              Token: route.params.id,
              apliOrigen: route.params.apliOrigen};

        store.dispatch('GetAccessProfiles',params).then(data=>{  
          localStorage.setItem('Identificacion', data.data.DatosEmpleador.NumeroIdentificacion);
          localStorage.setItem('RazonSocial', data.data.DatosEmpleador.RazonSocial);
          localStorage.setItem('RazonSocialPila', data.data.DatosEmpleador.RazonSocialPila);
          localStorage.setItem('TipoIdentificacion', data.data.DatosEmpleador.TipoIdentificacion);  
          localStorage.setItem('Sucursal', data.data.DatosEmpleador.Sucursal); 
          localStorage.setItem('DV', data.data.DatosEmpleador.DigitoVerificacion); 
          localStorage.setItem('Perfil', data.data.Perfil);
          localStorage.setItem('AplicativoOrigen', data.data.DatosEmpleador.AplicativoOrigen);
          updateContent(true);

          if(data.data.Permiso=="Todos")
            {       
              store.dispatch('GetStateEmpleador',route.params.id).then(data=>{
              loading.value = false;
               if(data.data=="Registrado"||data.data=="RegistradoPT"||data.data=="Habilitado"){
                  router.push({name:'Information'});  
                }
                else
                {
                  estadoActualNE.value = data.data;
                  ValidarDebidaDiligencia();
                }
                }).catch(error=>{
                 loading.value = false;
                 console.log("Ocurrio un error al consultar el estado del empleador.");
                 });                           
            }else if(data.data.Permiso=="Restringido"){
              store.dispatch('GetStateEmpleador',route.params.id).then(data=>{
                loading.value = false;
                if(data.data=="AceptoTyC"){
                  statePaymentRedirect(true);
              }else{
                    router.push({name:'Dashboard'});
              } 
              }).catch(error=>{
                loading.value = false;
              console.log(error);
                });    
            }else{
               location.replace(process.env.VUE_APP_PortalEmpleador);
            }     
        }).catch(error=>{
          loading.value = false;
          localStorage.clear();
          updateContent(true);
          if(error.response.status==400){
                Error.value="No es posible ingresar al Portal de Nómina Electrónica, por favor vuelve a intentar.";
          }else{
            location.replace(process.env.VUE_APP_PortalEmpleador);
          }
        } );

        GetAndSetStatusFeatureFlag("232870");
        GetAndSetStatusFeatureFlag("246302");

      });
    return{
        Error,
        loading,
    };
  }
    
};
