
import InputNumber from "primevue/inputnumber";
import HuelgasLegalesValidacion from "@/validation/PayrollTransmission/HuelgasLegales";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { onMounted } from "vue";
import { DatetimeToDate } from "@/helpers/helpers";

export default {
  components: {
    InputNumber,
    Calendar,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [strikes, huelgasLegales, validaciones] =
      new HuelgasLegalesValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, strikes, {
      $scope: props.nomina,
    });

    const AgregarFila = () => {
      const strike = {
        StartDate: null,
        EndDate: null,
        Quantity: null,
      };

      strikes.items.push(strike);
      huelgasLegales.push(strike);
    };

    const EliminarHuelgaLegal = (index: number) => {
      huelgasLegales.splice(index, 1);
      strikes.items.splice(index, 1);
    };

    const FormatearFechas = () => {
      strikes.items = strikes.items.map((strike: any) => {
        strike.StartDate = DatetimeToDate(strike.StartDate);
        strike.EndDate = DatetimeToDate(strike.EndDate);
        return strike;
      });
    };

    onMounted(() => {
      FormatearFechas();
    });

    return {
      strikes,
      v$,
      EliminarHuelgaLegal,
      FormatearFechas,
      AgregarFila,
    };
  },
};
