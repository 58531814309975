<template>

  <ResumenEmpleado
    v-if="empleado && detalle"
    :empleado="empleado[0]"
    :detalle="detalle"
    :fechaIngreso="fechaIngreso"
    :fechaAprobacion="fechaAprobacion">
  </ResumenEmpleado>

  <DataTable responsiveLayout="scroll" :value="nomina">
     <Column field="DiasLaborados" header="Días laborados" bodyClass="datatable-text-center"></Column>
     <Column field="Sueldo" header="Sueldo" bodyClass="datatable-text-center"></Column>
     <Column field="SueldoTrabajado" header="Sueldo trabajado" bodyClass="datatable-text-center"></Column>
     <Column field="Auxilios" header="Auxilios" bodyClass="datatable-text-center"></Column>
     <Column field="ValorFSP" header="Valor FSP" bodyClass="datatable-text-center"></Column>
     <Column field="FinPagoNomina" header="Fin pago nómina" bodyClass="datatable-text-center"></Column>
     <Column field="DevengosTotal" header="Devengos total" bodyClass="datatable-text-center"></Column>
     <Column field="DeduccionesTotal" header="Deducciones total" bodyClass="datatable-text-center"></Column>
     <Column field="ComprobanteTotal" header="Comprobante total" bodyClass="datatable-text-center"></Column>
  </DataTable>

</template>

<script>

import moment from "moment";
import { onMounted, ref } from "vue";
import { AsignarFormatoPesos } from "@/helpers/helpers";
import ResumenEmpleado from "@/components/DetalleNomina/ResumenEmpleado.vue";

export default {
  name: "LiquidacionNomina",
  components: {
    ResumenEmpleado,
  },
  props: {
    empleado: {},
    liquidacion: {},
    detalle: {},
    fechaIngreso: String,
    fechaAprobacion: String
  },
  setup(props) {
    
    const empleado = ref();
    const liquidacion = ref();
    const detalle = ref();
    const nomina = ref([]);
    const fechaIngreso = props.fechaIngreso;
    const fechaAprobacion = props.fechaAprobacion;

    onMounted(() => {

      empleado.value = props.empleado;
      liquidacion.value = props.liquidacion;
      detalle.value = props.detalle;

      nomina.value.push({
        DiasLaborados: liquidacion.value.GeneralAccruals?.WorkedDays,
        Sueldo: AsignarFormatoPesos(liquidacion.value.Salary),
        SueldoTrabajado: AsignarFormatoPesos(
          liquidacion.value.GeneralAccruals?.SalaryWorked
        ),
        FinPagoNomina: liquidacion.value.EndSettlementDate
          ? moment(liquidacion.value.EndSettlementDate).format("YYYY-MM-DD") : "",
        Auxilios: AsignarFormatoPesos(
          (liquidacion.value.Assistances[0]?.Total ?? "") +
            (liquidacion.value.Assistances[0]?.NonSalaryTotal ?? "")
        ),
        ValorFSP: AsignarFormatoPesos(
          (liquidacion.value.GeneralDeductions?.SolidarityPensionDeduction ?? "") +
            (liquidacion.value.GeneralDeductions?.SubsistenceDeduction ?? "")
        ),
        DevengosTotal: AsignarFormatoPesos(liquidacion.value.AcrrualsTotal),
        DeduccionesTotal: AsignarFormatoPesos(
          liquidacion.value.DeductionsTotal
        ),
        ComprobanteTotal: AsignarFormatoPesos(liquidacion.value.Total),
      });
    });

    return { empleado, nomina, detalle, fechaIngreso, fechaAprobacion };
  },
};
</script>
