<template>

    <Dialog
    :visible="true"
    modal
    :closable="false"
    header="Importante"
    :draggable="false"
    id="p-dialog"
  >
    <el-row :gutter="24" justify="space-evenly">
        <el-col :xs="4" :sm="4" :md="4" :lg="4" :xl="4">
            <img src="../../assets/img/Warning.png" width="100" height="100" alt="warning"/>
        </el-col>
        <el-col :xs="20" :sm="20" :md="20" :lg="20" :xl="20">
            <div class="popUpDD">
            <p>Lamentamos informarte que tu pago no pudo completarse. Queremos asegurarnos de que puedas finalizar tu compra sin inconvenientes. 
                Por favor intenta realizar tu pago nuevamente.
            </p>
            </div>  
        </el-col>
    </el-row> 

    <template #footer>
      <Button icon="pi pi-times" @click="RedirectToKhuskiClick" text>Aceptar</Button>
    </template>
  </Dialog>

</template>

<script>
import {defineComponent} from 'vue'
import {useStore} from "vuex";
import Dialog from "primevue/dialog";
import router from '@/router';
import '../../assets/sass/components/DueDiligence/_popUpRenovacionDebidaDiligencia.scss';

export default defineComponent({
name: 'RedirectToKhuski',
components: {
    Dialog,
},
props: {
    valuePlan: Number,
    documentsCount: Number
},
setup(props) {     
      const store= useStore();  
      const RedirectToKhuskiClick = () => {
      router.push({
        name: 'KushkiPaymentForm',
        params: {
            valuePlan: Number(props.valuePlan),
            documentsCount: Number(props.documentsCount),
            featureFlag: Boolean(true)
          },
      })
    };
       
    return {
      store,
      RedirectToKhuskiClick
    };
  },   
});
</script>


