import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class LibranzasValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const drafts = reactive({
      items: store.state.DetalleNomina.JsonNomina.Drafts,
    });

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          Description: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE175`, 
              required),
          },
          Total: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE176`,
              required
            ),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE176`,
              maxValue(maxInt)
            ),
          },
        }),
      },
    };

    return [drafts, reglas] as const;
  }
}

export default LibranzasValidacion;
