
import { ref } from "@vue/reactivity";
import {useStore} from "vuex";
import '../../assets/sass/components/DueDiligence/_bannerRenovacionDebidaDiligencia.scss';

export default {
name: 'BannerRenovacionDebidaDiligencia',
props: {
  estadoDD: Number,
},
setup() {     
      const store= useStore();
      const UrlDebidaDiligencia = ref();
      
      store.dispatch('obtenerUrlDebidaDiligencia').then((response) => 
      {
        UrlDebidaDiligencia.value = response;
      });
       
    return {
      store,
      UrlDebidaDiligencia
    };
  },   
};
