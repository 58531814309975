
import { useStore } from "vuex";
import { Split, CampoNuloOVacio } from "@/helpers/helpers";
import useVuelidate from "@vuelidate/core";
import { computed, onMounted, ref, watch } from "vue";
import Dropdown from "primevue/dropdown";
import DivipolaValidacion from "@/validation/Layout/DivipolaDian";
export default {
  components: {
    Dropdown,
  },
  props: {
    paisDefecto: String,
    dptoDefecto: String,
    municipioDefecto: String,
    divipola: String,
    footerPais: String,
    requiredAsterisk: Boolean,
    divipolaModel: {
      type: Object,
      required: false,
      default: () => ({
        Pais: "",
        Departamento: "",
        Municipio: "",
      }),
    },
  },
  setup(props) {
    const store = useStore();
    const paises = ref([{ nombre: "CO-Colombia" }]);
    const codigoPais = ref("");
    const codigoDpto = ref("");
    const codigoMunicipio = ref("");
    const departamentos = ref();
    const municipios = ref();

    let [divipolas, reglasDivipolas] =
      new DivipolaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(reglasDivipolas, divipolas, {
      $scope: props.divipola,
    });

    watch(
      () => props.divipolaModel,
      (newVal) => {
        Object.assign(divipolas, newVal);
      },
      { immediate: true }
    );

    const asterisk = (name: String) => {
      return "<span class='required-asterisk'>*</span>" + name;
    };

    const PaisSeleccionado = () => {
      v$.value.Pais.$touch();
      if (divipolas.Pais) {
        codigoPais.value = "CO";
      } else {
        codigoPais.value = "";
        codigoDpto.value = "";
        codigoMunicipio.value = "";
        divipolas.Pais = "";
        divipolas.Departamento = "";
        divipolas.Municipio = "";
      }
    };
    const DptoSeleccionado = (eliminarMunicipio: boolean) => {
       v$.value.Departamento.$touch();
      if (eliminarMunicipio) {
        divipolas.Municipio = "";
        codigoMunicipio.value = "";
      }
      if (divipolas.Departamento) {
        codigoDpto.value = Split(divipolas.Departamento, 0, "-");
      } else {
        divipolas.Departamento = "";
        codigoDpto.value = "";
      }
    };
    const MunicipioSeleccionado = () => {
       v$.value.Municipio.$touch();
      if (divipolas.Municipio) {
        codigoMunicipio.value = Split(divipolas.Municipio, 0, "-");
      } else {
        codigoMunicipio.value = "";
        divipolas.Municipio = "";
      }
    };
    const municipiosPorDpto = computed(() => {
      if (codigoDpto.value) {
        return municipios.value
          .filter((municipio: any) => {
            return municipio.codigoDepartamento == codigoDpto.value;
          })
          .sort((a: any, b: any) =>
            a.nombreMunicipio.localeCompare(b.nombreMunicipio)
          );
      }
    });
    function ObtenerDivipolas() {
      let consultaDivipolas = {
        token: localStorage.getItem("Tokensin"),
      };
      store
        .dispatch("ObtenerDepartamentosYMunicipiosDian", consultaDivipolas)
        .then((response) => {
          if (response.request.status == 200) {
            departamentos.value = response.data.Departamentos;
            municipios.value = response.data.Municipios;
            PaisSeleccionado();
            DptoSeleccionado(false);
            MunicipioSeleccionado();
          }
        })
        .catch((error) => {
          console.log(error.request.response);
        });
    }
    onMounted(() => {
      ObtenerDivipolas();
    });
    return {
      v$,
      paises,
      departamentos,
      municipiosPorDpto,
      divipolas,
      codigoPais,
      codigoDpto,
      codigoMunicipio,
      CampoNuloOVacio,
      PaisSeleccionado,
      DptoSeleccionado,
      MunicipioSeleccionado,
      props,
      asterisk,
    };
  },
};
