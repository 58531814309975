
import OtrosConceptosValidacion from "@/validation/PayrollTransmission/OtrosConceptos";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";

export default {
  components: {
    InputText,
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },

  setup(props) {
    let [otherConcepts, validaciones] =
      new OtrosConceptosValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, otherConcepts, {
      $scope: props.nomina,
    });

    const AgregarConcepto = () => {
      const concept = {
        Description: null,
        Total: null,
        NonSalaryTotal: null,
      };

      otherConcepts.items.push(concept);
    };

    const EliminarConcepto = (index: number) => {
      otherConcepts.items.splice(index, 1);
    };

    return {
      otherConcepts,
      v$,
      EliminarConcepto,
      AgregarConcepto,
    };
  },
};
