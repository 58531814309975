import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class OtrosConceptosValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const otherConcepts = reactive({
      items: store.state.DetalleNomina.JsonNomina.OtherConcepts
    });

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          Description: {
            required: helpers.withMessage(`${campoObligatorio} NIE146`, required),
          },
          Total: {
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE147`,
              maxValue(maxInt)
            ),
          },
          NonSalaryTotal: {
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE148`,
              maxValue(maxInt)
            ),
          },
        }),
      },
    };

    return [otherConcepts, reglas] as const;
  }
}

export default OtrosConceptosValidacion;
