import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "about" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      class: "information_image_size",
      alt: "Imagen",
      src: require('@/assets/img/LogOutInactivity.png'),
      width: "",
      height: ""
    }, null, 8, _hoisted_2)
  ]))
}