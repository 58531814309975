import  store from '@/store/index'
//////Valida el acceso a la ruta
export async function ValidateAccess(to:any, from:any,next:any){
    await store.dispatch('GetVeifyAccess',to.name).then(data=>{
        next();
    }).catch(error=>{
        /*
      Conforme a la vulnerabilidad reportada por fortify, se revisa y se determina de que es un caso
      de 'falso positivo', por ende el código se puede dejar de esta manera
      */
        location.replace(process.env.VUE_APP_Front+"UnAutorized");
      });
}