<template>
  <div v-if="detalle?.nominas?.length > 0 || error?.cantidadErrores > 0">
    <h1 class="Transmision_title">
      {{
        tabView?.d_activeIndex == tabs.Rechazada
          ? "Detalle Validación Nómina Electrónica"
          : ""
      }}
    </h1>
    <h1 class="Transmision_title">
      {{
        tabView?.d_activeIndex == tabs.Aprobada
          ? "Resumen Validación Nómina Electrónica"
          : ""
      }}
    </h1>
    <h1 class="Transmision_title">
      {{
        tabView?.d_activeIndex == tabs.Historico
          ? "Resumen Transmisión Nómina Electrónica"
          : ""
      }}
    </h1>
  </div>

  <TransmissionHeader
    v-if="
      detalle?.nominas?.length > 0 && tabView?.d_activeIndex == tabs.Aprobada
    "
    :cantidad="detalle?.cantidadDocumentos"
    :periodo="detalle?.periodo"
    :titulo="'Documentos'"
  >
  </TransmissionHeader>

  <TransmissionHeader
    v-if="
      error?.cantidadErrores > 0 && tabView?.d_activeIndex == tabs.Rechazada
    "
    :cantidad="error?.cantidadErrores"
    :periodo="error?.periodo"
    :titulo="'Errores'"
  >
  </TransmissionHeader>

  <TransmissionHeader
    v-if="tabView?.d_activeIndex == tabs.Historico"
    :cantidad="datos?.historial?.length"
    :periodo="detalle?.periodo ?? error?.periodo"
    :titulo="'Transmisiones'"
  >
  </TransmissionHeader>

  <TabView
    ref="tabView"
    :activeIndex="activeTab"
    v-show="detalle?.nominas?.length > 0 || error?.cantidadErrores > 0"
  >
    <TabPanel header="Nóminas aprobadas" :disabled="disabledApproved">
      <DetalleTransmision v-if="!disabledApproved" ref="detalle">
      </DetalleTransmision>
    </TabPanel>
    <TabPanel header="Nóminas rechazadas" :disabled="disabledRejected">
      <ErrorTransmission v-if="!disabledRejected" ref="error">
      </ErrorTransmission>
    </TabPanel>
    <TabPanel header="Histórico">
      <TransmissionHistory ref="datos"> </TransmissionHistory>
    </TabPanel>
  </TabView>

  <Spinner
    v-if="
      (estado === 'Transmitida parcialmente' && !detalle.nominas?.length > 0) ||
      error?.erroresNE == ''
    "
    type="modern"
  >
  </Spinner>
</template>

<script>
import router from "@/router";
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { onMounted } from "@vue/runtime-core";
import { Tab } from "@/models/Payroll/Enums";
import Spinner from "@/components/layout/Spinner.vue";
import "@/assets/sass/views/_SummaryTransmission.scss";
import ErrorTransmission from "@/views/ErrorTransmission.vue";
import DetalleTransmision from "@/views/DetalleTransmision.vue";
import TransmissionHistory from "@/components/DetalleTransmission/TransmissionHistory.vue";
import TransmissionHeader from "@/components/DetalleTransmission/TransmissionHeader.vue";

export default {
  name: "SummaryTransmission",
  components: {
    DetalleTransmision,
    TransmissionHistory,
    ErrorTransmission,
    TransmissionHeader,
    TabView,
    TabPanel,
    Spinner,
  },
  setup() {
    const store = useStore();
    const totales = store.state.DetalleEntradaNomina;
    const estado = store.state.DetalleEntradaNomina.Estado;

    const detalle = ref({});
    const error = ref({});
    const datos = ref();

    const tabs = Tab;
    const tabView = ref();
    const activeTab = ref(tabs.Aprobada);
    const disabledRejected = ref(true);
    const disabledApproved = ref(true);

    function GotoTransmission() {
      router.push({
        name: "Transmission",
      });
    }

    function DisabledBackNavigation() {
      window.onpopstate = function () {
        history.go(1);
      };
    }

    function StateValidate() {
      if (estado === "") {
        GotoTransmission();
      }
      if (totales.CantidadErrores > 0 || totales.ErroresValidacion > 0) {
        disabledRejected.value = false;
        activeTab.value = tabs.Rechazada;
      }
      if (totales.CantidadAprobados > 0) {
        disabledApproved.value = false;
        activeTab.value = tabs.Aprobada;
      }
    }

    onMounted(() => {
      DisabledBackNavigation();
      StateValidate();
    });

    return {
      estado,
      GotoTransmission,
      detalle,
      error,
      DisabledBackNavigation,
      tabView,
      activeTab,
      tabs,
      disabledRejected,
      disabledApproved,
      datos,
    };
  },
};
</script>