import { helpers, maxValue, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class SaludPensionValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const saludPension = reactive(
      store.state.DetalleNomina.JsonNomina.GeneralDeductions
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido."
    const campoObligatorio = "Campo obligatorio."

    const reglasSaludPension = computed(() => {
      return {
        HealthPercentage: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE161`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(
            `${campoObligatorio} NIE161`,
            requiredIf(saludPension.HealthPercentage == null)
          ),
        },
        HealthDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE163`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(
            `${campoObligatorio} NIE163`,
            requiredIf(saludPension.HealthDeduction == null)
          ),
        },
        PensionPercentage: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE164`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(
            `${campoObligatorio} NIE164`,
            requiredIf(saludPension.PensionPercentage == null)
          ),
        },
        PensionDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE166`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(
            `${campoObligatorio} NIE166`,
            requiredIf(saludPension.PensionDeduction == null)
          ),
        },
      };
    });

    return [saludPension, reglasSaludPension] as const;
  }
}

export default SaludPensionValidacion;