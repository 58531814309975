<template>
  <h2 class="Transmision_title">Validación de procesamiento de nómina</h2>

  <div style="border-radius: 8px; background-color: #F5F5F5; padding: 10px; box-shadow: 1px 3px 5px rgba(0, 0, 0, 0.1); ">
    <table class="Table" summary="Nóminas en procesamiento y en cola" >
      <thead>
        <tr>
          <th class="Table_th">No ID</th>
          <th class="Table_th">Nombre</th>
          <th class="Table_th">Fecha de carga</th>
          <th class="Table_th">Período</th>
          <th class="Table_th">Método de transmisión</th>
          <th class="Table_th">Estado</th>
          <th class="Table_th">Opciones</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in nominasEnProcesamiento" :key="item.id">
          <td class="Table_td">{{ item.numeroIdentificacion }}</td>
          <td class="Table_td">{{ item.nombreArchivo }}</td>
          <td class="Table_td">{{ item.fechaCarga }}</td>
          <td class="Table_td">{{ item.periodo }}</td>
          <td class="Table_td">{{ item.metodoTransmision }}</td>
          <td class="Table_td">{{ item.estado }}</td>
          <td class="Table_td centrarContenido">
            <div class="opcionNominasEnCola" 
              @click="ConfirmRemovePayrollFromQueue(item)"
              v-if="item.estado == 'En Cola' && (item.metodoTransmision == 'Excel' || item.metodoTransmision == 'XML') ">
              <a
                ><font-awesome-icon :icon="['fas', 'trash-alt']"
              /></a>
            </div>
            <div class="opcionNominasEnCola opcionNominasEnCola-disabled" v-else>
              <a
                ><font-awesome-icon :icon="['fas', 'trash-alt']"
              /></a>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="row tac">
    <input
      type="button"
      @click="GotoTransmission()"
      value="Volver al inicio"
      class='Button_BColorDegraded Button_fontButtonError'
    />
  </div>

  <ConfirmDialog id="confirm-dialog" class="confirm-modal" :draggable="false">
    <template #message="props" style="height: auto;">
      <img src="../assets/img/Warning.png" alt="icono" width="80" height="80" style="margin-right: 25px" />
      <div >
        <p class="gray-text" style="text-align: justify">
          {{ props.message.message }}
        </p>
      </div>
    </template>
  </ConfirmDialog>

  <Dialog
    v-model:visible="mostrarModalInformacion"
    modal
    :closable="false"
    header="Importante"
    :draggable="false"
    id="p-dialog"
    class="error-dialog"
  >
    <img src="../assets/img/Warning.png" alt="icono" width="60" height="60" />
    <p>
      En este momento no es posible eliminar la nómina seleccionada de la cola de transmisión.
    </p>
    <template #footer>
      <Button icon="pi pi-times" @click="CerrarModal" text>← Volver</Button>
    </template>
  </Dialog>

  <Spinner v-if="loading" type="modern"></Spinner>

</template>

<script>
import { onMounted } from "vue";
import { useMutations } from "@/store/helper";
import { useStore } from "vuex";
import { useRoute } from 'vue-router';
import { useConfirm } from "primevue/useconfirm";
import { ref } from "@vue/reactivity";
import Spinner from "@/components/layout/Spinner.vue";
import router from "@/router";
import ConfirmDialog from "primevue/confirmdialog";
import Dialog from "primevue/dialog";

export default {
  components: {
    Spinner,
    ConfirmDialog,
    Dialog
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const loading = ref(false);
    const mostrarModalInformacion = ref(false);
    const confirm = useConfirm();
    const nominasEnProcesamiento = ref([]);
    const { setStateMenu } = useMutations(["setStateMenu"]);
    setStateMenu(true);
    var intervalState;

    function GotoTransmission() {
      closeInterval();
      router.push({name: "Transmission"});
    }

    function GetListPayrollProcessing() {
      loading.value = true;
      var params = {
        Token: localStorage.getItem("Tokensin")
      };
      store
        .dispatch("ObtenerNominasEnProcesamiento", params)
        .then((data) => {
          if (data.data.length > 0) {
            nominasEnProcesamiento.value = data.data;
            reloadPage();
          } else {
            GotoTransmission();
          }
          loading.value = false;
        })
        .catch((error) => {
          loading.value = false;
          console.error("Ocurrió un error al intentar obtener el listado de nóminas en procesamiento.");
        });
    }

    function reloadPage() {
      if (route.name == "PayrollProcessingValidation") {
        intervalState = window.setTimeout(() => {
          GetListPayrollProcessing();
        }, 60000);
      } else {
        closeInterval();
      }
    }
    function closeInterval() {
      window.clearTimeout(intervalState);
    }

    function RemovePayrollFromQueue(entradaNominaId){
      loading.value = true;
      var requestData = {
        token: localStorage.getItem("Tokensin"),
        entradaNominaId: entradaNominaId
      };
      store
        .dispatch("EliminarNominaColaTransmision", requestData)
        .then((data) => {
          loading.value = false;
          closeInterval();
          GetListPayrollProcessing();
        })
        .catch((error) => {
          loading.value = false;
          console.error("Ocurrió un error al intentar eliminar la nómina de la cola de transmisión.");
          closeInterval();
          GetListPayrollProcessing();
          mostrarModalInformacion.value = true;
        }
      );
    }

    function ConfirmRemovePayrollFromQueue(nomina) {
      confirm.require({
        message: "Estás a punto de eliminar de la cola el documento "+nomina.nombreArchivo+", una vez eliminado no podrás reanudar su proceso de validación y deberás cargar nuevamente el archivo",
        header: "Importante",
        acceptClass: "p-button-text",
        acceptLabel: "Confirmar",
        rejectLabel: "← Volver",
        accept: () => {
          RemovePayrollFromQueue(nomina.entradaNominaId);
        },
        reject: () => {
          confirm.close();
        },
        onHide: () => {
          confirm.close();
        },
      });
    }

    const CerrarModal = () => {
      mostrarModalInformacion.value = false;
    };

    onMounted(() => {
      GetListPayrollProcessing();
    });

    return {
      loading,
      GetListPayrollProcessing,
      GotoTransmission,
      reloadPage,
      closeInterval,
      nominasEnProcesamiento,
      ConfirmRemovePayrollFromQueue,
      mostrarModalInformacion,
      CerrarModal
    }
  },
}
</script>