

import useVuelidate from '@vuelidate/core';
import { defineComponent,ref, onUpdated } from 'vue';
import InfoRepresentanteTitularValidacion from '../../validation/InfoRepresentanteTitular';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
props: {
        scopeRepresentanteTitular: String,
        parametricas: {}
    },
    setup(props) {

        let [objetoInfoRepresentanteTitular, reglasValidacion] = new InfoRepresentanteTitularValidacion().ObtenerReglasValidacion();

        const AgregarFilaRLTitular = () => {

            objetoInfoRepresentanteTitular.RLTitular.push({
                NombreRLTitular: '',
                TipoIdRLTitular: '',
                NumeroIdentificacionRLTitular: '',
                CargoEmpresaRLTitular: '',
                CorreoRLTitular: '',
                EsPEPRLTitular: '',
                VinculosFamPEP_RLTitular: ''

            }); 
        }

        const EliminarFilaRLTitular = (index: number) => {
            objetoInfoRepresentanteTitular.RLTitular.splice(index, 1);
        }

        const validacionInfoRepresentanteTitular = useVuelidate(reglasValidacion, objetoInfoRepresentanteTitular, { $scope: props.scopeRepresentanteTitular })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]

        onUpdated(() => {
            window.scrollTo(0, 0);
})

        return { 
          AgregarFilaRLTitular,  
          EliminarFilaRLTitular, 
          validacionInfoRepresentanteTitular, objetoInfoRepresentanteTitular,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEP()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoRepresentanteTitular.RLTitular.length; i++)
            {
                if(this.objetoInfoRepresentanteTitular.RLTitular[i].EsPEPRLTitular.includes("Si") || this.objetoInfoRepresentanteTitular.RLTitular[i].VinculosFamPEP_RLTitular.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            console.log(Result);
            this.$emit('MostrarSeccionPepRT',Result)

        }
    }
})

