

export default {
  props: {
    message: String,
    visible: Boolean,
  },
  setup(props) {
    
    const message = props.message;
    const isTooltipVisible = props.visible;

    return {
      isTooltipVisible,
      message,
    };
  },
};
