import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import ElementPlus from 'element-plus';
import 'element-plus/lib/theme-chalk/index.css';
import VueAxios from 'vue-axios'
import './assets/sass/main.scss';
import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import PrimeVue from 'primevue/config';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import ConfirmationService from 'primevue/confirmationservice';
import JsonExcel from "vue-json-excel3";

library.add(fas);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);

/*Componentes Primevue*/
app.use(PrimeVue, { ripple: true });
app.use(ConfirmationService);
app.component('DataTable', DataTable);
app.component('Column', Column);
/*Componentes Primevue*/

/*Componentes JsonExcel*/
app.component("downloadExcel", JsonExcel);
/*Componentes JsonExcel*/
app.use(store);
app.use(router);
app.use(VueAxios, axios);
//app.use(ElementPlus);
app.use(ElementPlus, { size: 'small', zIndex: 3000 });

app.mount('#app');
