
import InputNumber from "primevue/inputnumber";
import IncapacidadesValidacion from "@/validation/PayrollTransmission/Incapacidades";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { Incapacidades } from "@/helpers/Parametricas";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { onMounted, ref } from "vue";
import { DatetimeToDate, Split } from "@/helpers/helpers";

export default {
  components: {
    InputNumber,
    Calendar,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const listaIncapacidades = ref();

    let [inabilities, incapacidades, validaciones] =
      new IncapacidadesValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, inabilities, {
      $scope: props.nomina,
    });

    const AgregarFila = () => {
      const incapacidad = {
        InabilityTypeCode: null,
        StartDate: null,
        EndDate: null,
        Quantity: null,
        Total: null,
      };

      inabilities.items.push(incapacidad);
      incapacidades.push(incapacidad);
    };

    const EliminarIncapacidad = (index: number) => {
      incapacidades.splice(index, 1);
      inabilities.items.splice(index, 1);
    };

    const FormatearFechas = () => {
      inabilities.items = inabilities.items.map((incapacidad: any) => {
        incapacidad.StartDate = DatetimeToDate(incapacidad.StartDate);
        incapacidad.EndDate = DatetimeToDate(incapacidad.EndDate);
        return incapacidad;
      });
    };

    const NombreIncapacidad = () => {
      inabilities.items = inabilities.items.map((ih: any) => {
        ih.TipoIncapacidad = listaIncapacidades.value
          .filter((incapacidad: any) => {
            return incapacidad.codigo == ih.InabilityTypeCode;
          })
          .shift()?.abreviatura;
        return ih;
      });
    };

    const CodigoIncapacidad = (incapacidad: string, index: number) => {
      if (incapacidad) {
        inabilities.items[index].InabilityTypeCode = Split(incapacidad, 0, "-");
      } else {
        inabilities.items[index].InabilityTypeCode = "";
      }
    };

    onMounted(() => {
      listaIncapacidades.value = Incapacidades();
      NombreIncapacidad();
      FormatearFechas();
    });

    return {
      inabilities,
      listaIncapacidades,
      v$,
      EliminarIncapacidad,
      FormatearFechas,
      AgregarFila,
      CodigoIncapacidad,
    };
  },
};
