import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class LicenciasValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const licenses = reactive({
      items: store.state.DetalleNomina.JsonNomina.Licenses,
    });
    const licencias = reactive(
      store.state.DetalleNomina.JsonNomina.Licenses
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          LicenseTypeCode: {
            required: helpers.withMessage(campoObligatorio, required),
          },
          Quantity: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE128 a NIE138`,
              required
            ),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE138`,
              maxValue(99)
            ),
          },
          Total: {
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE131-NIE135`,
              maxValue(maxInt)
            ),
          },
        }),
      },
    };
    
    return [licenses, licencias, reglas] as const;
  }
}

export default LicenciasValidacion;
