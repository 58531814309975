
import { onMounted, ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import SelectButton from "primevue/selectbutton";
import { TipoDevengo } from "@/models/Payroll/Enums";
import { TabLiquidacion } from "@/models/Payroll/Enums";
import Prima from "@/components/TransmisionNomina/Prima.vue";
import Epctv from "@/components/TransmisionNomina/Epctv.vue";
import Empleado from "@/components/TransmisionNomina/Empleado.vue";
import Cesantias from "@/components/TransmisionNomina/Cesantias.vue";
import Transporte from "@/components/TransmisionNomina/Transporte.vue";
import DatosEmpresa from "@/components/TransmisionNomina/DatosEmpresa.vue";
import Compensaciones from "@/components/TransmisionNomina/Compensaciones.vue";
import NominaElectronica from "@/components/TransmisionNomina/NominaElectronica.vue";
import PagoTercerosAnticipos from "@/components/TransmisionNomina/PagoTercerosAnticipos.vue";
import ComisionesAuxiliosBonificaciones from "@/components/TransmisionNomina/ComisionesAuxiliosBonificaciones.vue";

export default {
  components: {
    TabView,
    TabPanel,
    Empleado,
    DatosEmpresa,
    NominaElectronica,
    Transporte,
    Prima,
    Epctv,
    Cesantias,
    Compensaciones,
    PagoTercerosAnticipos,
    SelectButton,
    ComisionesAuxiliosBonificaciones,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const activePanel = ref();
    const tabs = TabLiquidacion;
    const activeTab = ref(tabs.Empleado);
    const scopeNomina = props.nomina;
    const selectButton = ref();

    const SelectedOption = () => {
      if (selectButton.value?.value >= TipoDevengo.Transporte) {
        activePanel.value = selectButton.value?.value;
      }
    };

    const firstOption = {
      value: TipoDevengo.Transporte,
      number: 1,
      tooltip: "Transporte",
    };

    const options = ref([
      firstOption,
      {
        value: TipoDevengo.Comisiones,
        number: 2,
        tooltip: "Comisiones, auxilios y bonificaciones",
      },
      {
        value: TipoDevengo.Prima,
        number: 3,
        tooltip: "Prima",
      },
      {
        value: TipoDevengo.Cesantias,
        number: 4,
        tooltip: "Cesantías",
      },
      {
        value: TipoDevengo.Compensaciones,
        number: 5,
        tooltip: "Compensaciones",
      },
      {
        value: TipoDevengo.Bonos,
        number: 6,
        tooltip: "Bono electrónico, papel servicio, cheque, tarjeta, vale, etc",
      },
      {
        value: TipoDevengo.PagoTerceros,
        number: 7,
        tooltip: "Pago terceros y anticipos",
      },
    ]);

    onMounted(() => {
      activePanel.value = TipoDevengo.Transporte;
      selectButton.value = firstOption;
      selectButton.value.value = activePanel.value;
    });

    return {
      activeTab,
      scopeNomina,
      activePanel,
      TipoDevengo,
      options,
      selectButton,
      SelectedOption,
    };
  },
};
