
import InputNumber from "primevue/inputnumber";
import CesantiasValidacion from "@/validation/PayrollTransmission/Cesantias";
import useVuelidate from "@vuelidate/core";
import Tooltip from "@/components/layout/TooltipTransmission.vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [cesantias, validaciones] =
      new CesantiasValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, cesantias, {
      $scope: props.nomina,
    });

    return {
      cesantias,
      v$,
    };
  },
};
