import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class TransporteValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const transporte = reactive(
      store.state.DetalleNomina.JsonNomina.TransportationAssistances
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasTransporte = computed(() => {
      return {
        TransportationAssistance: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE071`,
            requiredIf(
              transporte.length > 0
                ? transporte[0].TransportationAssistance > maxInt
                : false
            )
          ),
        },
        SalarialViatic: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE072`,
            requiredIf(
              transporte.length > 0
                ? transporte[0].SalarialViatic > maxInt
                : false
            )
          ),
        },
        NonSalarialViatic: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE073`,
            requiredIf(
              transporte.length > 0
                ? transporte[0].NonSalarialViatic > maxInt
                : false
            )
          ),
        },
      };
    });

    return [transporte, reglasTransporte] as const;
  }
}

export default TransporteValidacion;