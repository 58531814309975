<template>
    <div v-if="visible" class="loading"> 
        <div v-if="type === 'modern'">
            <div class="moderns">
                <div class="modern"></div>
                <div class="modern"></div>
                <div class="modern"></div>
                <div class="modern"></div>
                <div class="modern"></div>
            </div>

            <svg version="1.1" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur in="SourceGraphic" result="blur" stdDeviation="12" ></feGaussianBlur>
                        <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0	0 1 0 0 0	0 0 1 0 0	0 0 0 18 -7" result="goo" ></feColorMatrix>
                        <!--<feBlend in2="goo" in="SourceGraphic" result="mix" ></feBlend>-->
                    </filter>
                </defs>
            </svg>
        </div>
        <div class="spinner" v-if="type === 'dynamic'">
            <div class="dynamic">
                <span id="dynamic_1">
                </span>
                <span id="dynamic_2">
                </span>
                <span id="dynamic_3">
                </span>
            </div>
        </div> 
        <div class="spinner" v-if="type === 'classic'">
            <div class="classic">
                <div class="wBall" id="wBall_1">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_2">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_3">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_4">
                    <div class="wInnerBall"></div>
                </div>
                <div class="wBall" id="wBall_5">
                    <div class="wInnerBall"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Spinner',
    props: {
        type:{
			type: String,
            required: false,
            default: () => 'modern'
		},	
        visible: {
			type: Boolean,
            required: false,
            default: () => true
		},	
    }
}
</script>