
import { defineComponent, onMounted, ref } from "vue";
import { useStore } from "vuex";
import Checkbox from "@/components/layout/Checkbox.vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";
import { useMutations } from "@/store/helper";
import Dialog from "primevue/dialog";

export default defineComponent({
  name: "Configuration",
  components: {
    Checkbox,
    Dialog,
    ConfirmDialog,
  },
  setup() {
    const confirm = useConfirm();
    const store = useStore();
    const { setStateLoading } = useMutations(["setStateLoading"]);
    const { setStateMenu } = useMutations(["setStateMenu"]);
    const categoriasConReglas = ref<CategoriasConReglas[]>([]);
    const cargando = ref(true);
    const sinCambios = ref(false);

    setStateMenu(true);

    const MarcarCambioDeEstado = (
      categoriaId: number,
      reglaId: number,
      nuevoEstado: boolean
    ) => {
      const categoria = categoriasConReglas.value.find(
        (c) => c.categoriaId === categoriaId
      );
      const regla = categoria?.reglasPorCategoria.find(
        (r) => r.reglaId === reglaId
      );
      if (regla) {
        regla.activo = nuevoEstado;
        regla.cambiado = regla.activo !== regla.activoOriginal;
      }
    };

    const ConfirmarEnvioDeCambios = (reglas: any) => {
      confirm.require({
        message:
          "Modificaste las reglas de configuración, ¿Deseas aplicar los cambios?",
        header: "Importante",
        acceptClass: "p-button-danger",
        rejectClass: "p-button-danger",
        acceptLabel: "Aceptar",
        rejectLabel: "← Volver",
        accept: () => {
          RegistrarHistorialReglas(reglas);
        },
        reject: () => {
          confirm.close();
        },
        onHide: () => {
          confirm.close();
        },
      });
    };

    const ValidarReglasCambiadas = () => {
      const reglas = ObtenerReglasCambiadas();
      if (reglas.length > 0) {
        ConfirmarEnvioDeCambios(reglas);
      } else {
        sinCambios.value = true;
      }
    };

    function ObtenerReglasCambiadas(): any {
      let reglasCambiadas = categoriasConReglas.value
        .flatMap((categoria) => categoria.reglasPorCategoria)
        .filter((regla) => regla.cambiado)
        .map((regla) => {
          return {
            Activo: regla.activo,
            ConfiguracionReglasId: regla.reglaId,
          };
        });

      return reglasCambiadas;
    }

    function ObtenerReglasPorCategoria() {
      setStateLoading(true);
      let parametrosConsulta = {
        Token: localStorage.getItem("Tokensin"),
      };
      store
        .dispatch("ObtenerReglasPorCategoria", parametrosConsulta)
        .then((response) => {
          if (response.request.status == 200) {
            categoriasConReglas.value = response.data;
            categoriasConReglas.value.forEach((categoria) => {
              categoria.reglasPorCategoria.forEach((regla) => {
                regla.cambiado = false;
                regla.activoOriginal = regla.activo;
              });
            });
          }
          setStateLoading(false);
          cargando.value = false;
        })
        .catch((error) => {
          setStateLoading(false);
          cargando.value = false;
          console.log(error);
        });
    }

    function RegistrarHistorialReglas(reglasCambiadas: any) {
      setStateLoading(true);
      let parametrosConsulta = {
        Token: localStorage.getItem("Tokensin"),
        HistorialReglas: reglasCambiadas,
      };
      store
        .dispatch("RegistrarHistorialReglas", parametrosConsulta)
        .then((response) => {
          if (response.request.status == 204) {
            ObtenerReglasPorCategoria();
          }
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error);
        });
    }

    const CerrarModal = () => {
      sinCambios.value = false;
    };

    onMounted(() => {
      ObtenerReglasPorCategoria();
    });

    return {
      categoriasConReglas,
      MarcarCambioDeEstado,
      ValidarReglasCambiadas,
      cargando,
      sinCambios,
      CerrarModal,
      ConfirmarEnvioDeCambios,
    };
  },
});
