import { computed, reactive } from 'vue'
import { required, helpers, requiredIf, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class EmpladoValidacion {

    public ObtenerReglasValidacion() {

        const store = useStore();
        const { JsonNomina } = store.state.DetalleNomina;
        const empleado = reactive(JsonNomina);
        const mensajeError = "Campo obligatorio.";
        const maxInt = 2147483647;
    
        const reglasEmpleado = computed(() => {
          return {
            SalaryWorked: {
              maxValue: helpers.withMessage(
                "Valor inválido. NIE070",
                requiredIf(empleado.GeneralAccruals.SalaryWorked > maxInt)
              ),
              required: helpers.withMessage(
                `${mensajeError} NIE070`,
                requiredIf(empleado.GeneralAccruals.SalaryWorked == null)
              ),
            },
            WorkedDays: {
              maxValue: helpers.withMessage(
                "Valor inválido. NIE069",
                requiredIf(empleado.GeneralAccruals.WorkedDays > 99)
              ),
              required: helpers.withMessage(
                `${mensajeError} NIE069`,
                requiredIf(empleado.GeneralAccruals.WorkedDays == null)
              ),
            },
            AdmissionDate: {
              required: helpers.withMessage(`${mensajeError} NIE002`, required),
            },
            Salary: {
              required: helpers.withMessage(
                "Valor inválido. NIE062",
                maxValue(maxInt)
              ),
            },
          };
        });
        
        return [empleado, reglasEmpleado] as const;
    }
}

export default EmpladoValidacion;