
import { PaymentState } from "@/store/Payment/plans";
import { useStore } from "vuex";
import { defineComponent, onMounted, ref } from "vue";
import router from "@/router";

export default defineComponent({
  name: "WaitingPayment",
  props: {
    isRestricted: Boolean,
  },
  setup(props) {
    const store = useStore();
    const icono = ref("redo-alt");
    let respuestaBanco = ref();
    const mensaje = ref("");
    const estadoPago = ref("En transacción");
    let intervalo: number;
    function esperaPorCambio(vista: string) {
      icono.value = "spinner";
      mensaje.value =
        vista == "Plans"
          ? "Redirigiendo a planes..."
          : "¡Ya puedes transmitir!";
      estadoPago.value = vista == "Plans" ? "Rechazado" : "Aprobado";
      window.clearInterval(intervalo);
      window.setTimeout(() => {
        router.push({ name: vista });
      }, 10000);
    }
    function statePaymentRedirect(isRestrictedProfile: boolean) {
      let params = {
        Token: localStorage.getItem("Tokensin"),
        TipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
        Identificacion: localStorage.getItem("Identificacion"),
      };
      let paymentToPlan = [PaymentState.SinProceso, PaymentState.RechazadoPago];
      store
        .dispatch("GetStatePILARegister", params)
        .then((res) => {
          if (isRestrictedProfile) {
            if (res.data == PaymentState.AprobadoPago) {
              esperaPorCambio("Transmission");
            }
          } else {
            if(res.data == PaymentState.EnProceso){
               ConsultarEstadoPagoKushki();
            } else if (paymentToPlan.includes(res.data)) {
              esperaPorCambio("Plans");
            } else if (res.data == PaymentState.AprobadoPago) {
              esperaPorCambio("Transmission");
            }
          }
        })
        .catch((err) => console.log(err));
    }
    function newFunction() {
      intervalo = window.setInterval(function () {
        statePaymentRedirect(props.isRestricted);
      }, 15000);
    }
    function ConsultarEstadoPagoKushki() {

      let data = {
        token: null,
        tokenAEL: localStorage.getItem("Tokensin"),
        tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
        numeroIdentificacion: localStorage.getItem("Identificacion"),
      };

      store.dispatch("GetPaymentInfo", data)
        .then((serviceResponse) => {
          respuestaBanco.value = serviceResponse.data;
          newFunction();
        })
        .catch((error) => console.error(error));
    }
    onMounted(() => {
      newFunction();
    });
    return {
      icono,
      mensaje,
      estadoPago,
      respuestaBanco
    };
  },
});
