

import useVuelidate from '@vuelidate/core';
import { defineComponent,ref, onMounted  } from 'vue';
import PepValidacion from '../../validation/Pep';
import '../../assets/sass/components/DueDiligence/_pep.scss'
import { useStore } from "vuex";

export default defineComponent({
    props: {
        scopePep: String,
        parametricas: {}
    },
    setup(props) {
        
        const store = useStore();
        let [objetoPep, reglasValidacion] = new PepValidacion().ObtenerReglasValidacion();
        const AgregarFila = () => {

            objetoPep.campos.push({
                Nombre: '',
                TipoId: '',
                NumeroIdentificacion: '',
                TipoVinculo: '',
                TipoPep: ''
            }); 
        }   
        const EliminarFila = (index: number) => {
            objetoPep.campos.splice(index, 1);
        }

        const reiniciarObjetoPEP = () => {
          objetoPep.campos = [];
          AgregarFila();
        }
        const validacionPep = useVuelidate(reglasValidacion, objetoPep, { $scope: props.scopePep })   
        return { 
          AgregarFila, EliminarFila, validacionPep, objetoPep, reiniciarObjetoPEP,
          store
        }
    },
})

