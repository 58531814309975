<template>
  <div class="mensaje-cliente">
    <small>
      Si tienes alguna duda sobre tu proceso de compra, puedes escribirnos a la
      dirección de correo electrónico
      <a
        href="mailto:nominaelectronica@aportesenlinea.com?subject=Dudas proceso de compra de planes"
        >nominaelectronica@aportesenlinea.com</a
      >
      <!-- o al teléfono <a href="tel:+576017423900">+57 6017423900</a> -->
    </small>
  </div>
</template> 

<script>
export default {
  name: "MessageToClient",
  setup() {},
};
</script>