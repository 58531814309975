<template>

  <DataTable 
    responsiveLayout="scroll" 
    :filters="datos?.contiene"
    :rows="5" 
    :value="historial" 
    :paginator="true" 
    :rowsPerPageOptions="[5,10,20,50,100]">

    <Filtro ref="datos"></Filtro>
    
    <Column field="fechaTransmision" header="Fecha proceso" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="cantidadDocumentos" header="Cantidad documentos" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="cantidadDocumentosAprobados" header="Documentos aprobados" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="cantidadDocumentosRechazados" header="Documentos rechazados" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="estadoUsuarioNomina" header="Estado" :sortable="true" bodyClass="datatable-text-center"></Column>
    <Column field="opciones" header="Opciones" bodyClass="datatable-text-center">
      <template #body="{data}">
        <a v-if="!data.enProceso" 
          @click="RedirectTo(data)" >
          <font-awesome-icon 
            :icon="['fas', 'search']"
            size="lg"/>
        </a>
        <a v-if="data.enProceso">
          <font-awesome-icon 
          icon="sync" 
          class="fa-spin"
          size= "lg"/>
        </a>
      </template>
    </Column>

  </DataTable>

  <div class="row tac">
    <input
      type="button"
      @click="GotoTransmission()"
      value="Volver al inicio"
      class="Button_BColorDegraded Button_fontButtonError"
      />
  </div>
    
</template>

<script>

import router from '@/router';
import { onMounted } from 'vue';
import { useStore } from "vuex";
import { ref } from "@vue/reactivity";
import Filtro from '../layout/Filtro.vue';
import{useMutations} from '@/store/helper';
import Spinner from "@/components/layout/Spinner.vue"
import ErrorTransmission from '@/views/ErrorTransmission.vue';
import DetalleTransmision from '@/views/DetalleTransmision.vue';

export default {
  name: "Transmission",
  components: {
    Spinner,
    DetalleTransmision,
    ErrorTransmission,
    Filtro
  },
  setup() {

    const store = useStore();
    const historial = ref('');
    const datos = ref();

    const token = localStorage.getItem('Tokensin');
    const { setEntradaNominaId } = useMutations(['setEntradaNominaId']);

    function GotoTransmission() {
      router.push({
        name: "Transmission"
      });
    }

    function ObtenerHistorialTransmisiones() {
      store.dispatch('ObtenerHistorialTransmisiones', token)
        .then(historyTransmission => {

          historial.value = historyTransmission.data;

        }).catch(error => {
          
          console.log(error.request.response);

          if (error.response.status == 404) {
            GotoTransmission();
          }
        });
    }

    function RedirectTo(nomina) {

      setEntradaNominaId({
        EntradaNominaId: nomina.entradaNominaId,
        CantidadDocumentos: nomina.cantidadDocumentos,
        CantidadAprobados: nomina.cantidadDocumentosAprobados,
        CantidadErrores: nomina.cantidadDocumentosRechazados,
        Periodo: `${nomina.anio}-${nomina.mes < 10 ? `0${nomina.mes}` : nomina.mes}`,
      });

      if (nomina.estadoUsuarioNomina === "Transmitida") {
        router.push({
          name: 'DetalleTransmision'
        });
      } else {
        router.push({
          name: 'ErrorTransmission'
        });
      }
    }

    onMounted(() => {
      ObtenerHistorialTransmisiones();
    });

    return {
      RedirectTo,
      historial,
      GotoTransmission,
      datos
    };
  },
};

</script>
