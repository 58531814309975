
import router from '@/router'
import{useMutations} from '@/store/helper'
export default {
  name:"Information",
    components:{
    },
    setup() {
      const{setStateMenu} = useMutations(['setStateMenu']);
      setStateMenu(false);
      var landing_page= process.env.VUE_APP_LandingPage;
       function goToQualification(){
           router.push({name:'Qualification'});
        }

    return{
      goToQualification,
      landing_page
    };
  }
    
};
