<template>
  <div>
  </div>
</template>
  
<script>
import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  data() {
    return {
      tiempoInactividad: process.env.VUE_APP_CierreSesionSegundos || 1200,
      temporizadorCierreSesionInactividad: null
    };
  },
  async mounted() {
    await this.esperarObtenerEstadoFF();
    const cierreSesionInactividad = sessionStorage.getItem('CierreSesionInactividad') === 'true';
    const showFeaturePBI232870 = sessionStorage.getItem("showFeaturePBI232870") === 'true';

    if (!cierreSesionInactividad && showFeaturePBI232870) {
      this.iniciarTemporizador();
      document.addEventListener('click', this.reiniciarTemporizador); // Evento click para reiniciar el temporizador
    }
  },
  methods: {
    iniciarTemporizador() {
      this.temporizadorCierreSesionInactividad = setTimeout(() => {
        this.CierreSesionInactividad();
      }, this.tiempoInactividad * 1000); // Convierte segundos a milisegundos
    },
    reiniciarTemporizador() {
      clearTimeout(this.temporizadorCierreSesionInactividad);
      this.iniciarTemporizador();
    },
    CierreSesionInactividad() {
      localStorage.removeItem("Tokensin");
      sessionStorage.setItem("CierreSesionInactividad", String(true));
      location.replace(process.env.VUE_APP_Front+"LogoutInactivity");
    },
    async esperarObtenerEstadoFF() {
      return new Promise((resolve) => {
        const verificarSessionStorage = () => {
          if (sessionStorage.getItem("showFeaturePBI232870")) {
            resolve();
          } else {
            setTimeout(verificarSessionStorage, 1000); // Espera 1 segundo y vuelve a verificar
          }
        }
        verificarSessionStorage();
      });
    }
  },
  beforeDestroy() {
    document.removeEventListener('click', this.reiniciarTemporizador);
    clearTimeout(this.temporizadorCierreSesionInactividad);
  },
  setup() {
    const route = useRoute();
    const excludedViews = ['Initialize', 'LogoutInactivity'];

    watch(
      () => route.name,
      (currentView) => {
        if (!excludedViews.includes(currentView) && sessionStorage.getItem('CierreSesionInactividad') === 'true') {
          location.replace(process.env.VUE_APP_Front + "LogoutInactivity");
        }
      }
    );
  },
};
</script>