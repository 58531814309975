
import { defineComponent, onMounted, ref } from "vue";
import { useMutations } from "@/store/helper";
import router from "@/router";
import { useStore } from "vuex";
import {
  normalizeNumberValidator,
  colombianCurrencyValidator,
  normalizeDateValidator,
} from "@/store/Payment/plans";
import Spinner from "@/components/layout/Spinner.vue";

export default defineComponent({
  name: "PlanControl",
  components: {
    Spinner,
  },
  setup() {
    let planInfo = ref();
    const store = useStore();
    const { setStateMenu } = useMutations(["setStateMenu"]);
    setStateMenu(true);

    function GetPlanInfo() {
      let params = {
        Token: localStorage.getItem("Tokensin"),
        TipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
        Identificacion: localStorage.getItem("Identificacion"),
      };
      store
        .dispatch("GetStatePlan", params)
        .then((resp) => {
          planInfo.value = resp.data;
        })
        .catch((err) => console.error(err));
    }
    function GotoTransmision() {
      router.push({ name: "Transmission" });
    }
    function GoToHistorical() {
      router.push({ name: "TransactionStory" });
    }
    onMounted(() => {
      if (localStorage.getItem("TieneDatosContacto") == 'false'){
        router.push({ name: "EmployerContact" });
      } else {
        GetPlanInfo();
      }
    });
    return {
      GotoTransmision,
      GoToHistorical,
      normalizeNumberValidator,
      colombianCurrencyValidator,
      normalizeDateValidator,
      planInfo,
    };
  },
});
