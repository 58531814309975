
import { defineComponent, onMounted, ref, reactive } from "vue";
import { useMutations } from "@/store/helper";
import router from "@/router";
import { useStore } from "vuex";
import Spinner from "@/components/layout/Spinner.vue";

export default defineComponent({
  name: "EmployerContact",
  components: {
    Spinner,
  },
  setup() {
    let employerData = ref();
    const store = useStore();
    const loading = ref(true);
    const formRef = ref<HTMLFormElement>();
    const { setStateMenu } = useMutations(["setStateMenu"]);
    setStateMenu(true);
    const rules = reactive({
      nombre: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: ["blur", "change"],
        },
      ],
      apellido: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: ["blur", "change"],
        },
      ],
      correo: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: "blur",
        },
        {
          type: "email",
          trigger: ["blur", "change"],
          message: "El correo no es válido",
        },
        {
          min: 8,
          trigger: ["blur", "change"],
          message: "El campo requiere al menos 8 caracteres",
        },
      ],
      celular: [
        {
          required: true,
          message: "Este campo es obligatorio",
          trigger: ["blur", "change"],
        },
        {
          min: 10,
          trigger: ["blur", "change"],
          message: "El campo requiere al menos 10 números",
        },
        {
          pattern: /^\d*[1-9]+\d*$/,
          trigger: ["blur", "change"],
          message: "El número de celular es inválido",
        },
      ],
    });
    const EmpleadorContactoModel = {
      nombre: "",
      apellido: "",
      correo: "",
      celular: "",
      registroPilaId: "",
    };
    const empleadorContacto = reactive({ ...EmpleadorContactoModel });
    let existenDatosContacto = ref(false);
    let botonGuardar = ref(true);
    let message = ref("");
    function GetEmployerData() {
      store
        .dispatch("GetEmployerData", localStorage.getItem("Tokensin"))
        .then((resp) => {
          employerData.value = resp.data;
          if (resp.data.nombre != ""){
            existenDatosContacto.value = true;
            empleadorContacto.nombre = resp.data.nombre;
            empleadorContacto.apellido = resp.data.apellido;
            empleadorContacto.correo = resp.data.correo;
            empleadorContacto.celular = resp.data.celular;
          }
          empleadorContacto.registroPilaId = resp.data.registroPilaId;
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
          console.error(err);
          message.value = "No es posible obtener los datos del empleador en este momento. Por favor inténtalo más tarde.";
        });
    }
    function SaveEmployerContact() {
      formRef.value?.validate((valid: boolean) => {
        if (valid) {
          loading.value = true;
          let data = {
            token: localStorage.getItem("Tokensin"),
            contactData: empleadorContacto
          };
          store
          .dispatch("SaveContactData", data)
          .then((resp) => {
            loading.value = false;
            if (resp.status == 200){
              message.value = resp.data;
              existenDatosContacto.value = true;
              botonGuardar.value = false;
              localStorage.setItem('TieneDatosContacto', 'true');
            }else{
              message.value = "No fue posible guardar los datos de contacto. Por favor inténtalo más tarde.";
            }
          })
          .catch((err) => {
            loading.value = false;
            console.error(err);
            message.value = "No fue posible guardar los datos de contacto. Por favor inténtalo más tarde.";
          });
        }
      });
    }
    function Redirect() {
      let viewName = 'Plans'; 
      if (localStorage.getItem("DatosContactoRedireccion") != null){
        viewName = localStorage.getItem("DatosContactoRedireccion")!;
      }
      router.push({ name: viewName });
    }
    onMounted(() => {
      GetEmployerData();
    });
    return {
      Redirect,
      empleadorContacto,
      employerData,
      SaveEmployerContact,
      loading,
      rules,
      formRef,
      existenDatosContacto,
      message,
      botonGuardar,
    };
  },
});
