import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "img-panel" }
const _hoisted_2 = { class: "number-section" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_SelectButton = _resolveComponent("SelectButton")!
  const _component_SaludPension = _resolveComponent("SaludPension")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_FondoSP = _resolveComponent("FondoSP")!
  const _component_Sspta = _resolveComponent("Sspta")!
  const _component_OtrasDeducciones = _resolveComponent("OtrasDeducciones")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_SelectButton, {
      modelValue: $setup.selectedButton,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.selectedButton) = $event)),
      options: $setup.options,
      onClick: $setup.SelectedOption
    }, {
      option: _withCtx((props) => [
        _createVNode(_component_el_tooltip, {
          content: props.option.tooltip,
          placement: "bottom",
          "open-delay": "10"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, _toDisplayString(props.option.number), 1)
          ]),
          _: 2
        }, 1032, ["content"])
      ]),
      _: 1
    }, 8, ["modelValue", "options", "onClick"]),
    _createVNode(_component_TabView, {
      activeIndex: $setup.activePanel,
      "onUpdate:activeIndex": _cache[1] || (_cache[1] = ($event: any) => (($setup.activePanel) = $event)),
      class: "radio-tabview",
      scrollable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, null, {
          default: _withCtx(() => [
            _createVNode(_component_SaludPension, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, null, {
          default: _withCtx(() => [
            _createVNode(_component_FondoSP, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, null, {
          default: _withCtx(() => [
            _createVNode(_component_Sspta, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, null, {
          default: _withCtx(() => [
            _createVNode(_component_OtrasDeducciones, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}