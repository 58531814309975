
import { defineComponent, onMounted, ref } from "vue";
import { useMutations } from "@/store/helper";
import {
  normalizeNumberValidator,
  colombianCurrencyValidator,
  normalizeDateValidator,
} from "@/store/Payment/plans";
import router from "@/router";
import { useStore } from "vuex";
import Spinner from "@/components/layout/Spinner.vue";

export default defineComponent({
  name: "TransactionStory",
  components: {
    Spinner,
  },
  setup() {
    let storyPlan = ref();
    const store = useStore();
    const loading = ref(true);
    const { setStateMenu } = useMutations(["setStateMenu"]);
    setStateMenu(true);
    function GetStoryPlan() {
      let params = {
        Token: localStorage.getItem("Tokensin"),
        TipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
        Identificacion: localStorage.getItem("Identificacion"),
      };
      store
        .dispatch("GetHistorialTransacciones", params)
        .then((resp) => {
          loading.value = false;
          let data = asignarNombreOperacion(resp.data);
          storyPlan.value = data;
          console.log(resp.data);
        })
        .catch((err) => {
        loading.value = false;
        console.error(err);
        });
    }
    function GoToPlanControl() {
      router.push({ name: "PlanControl" });
    }
    function asignarNombreOperacion(data) {
      if (data) {
        data.forEach(element => {
          // Transmisión
          if (element.operacion == 0) {
            element.operacionNombre = "Transmisión";
          // Compra
          } else if (element.operacion == 1) {
            element.operacionNombre = "Compra";
          }
          // Devolución
          else if (element.operacion == 2) {
            element.operacionNombre = "Devolución";
          }
          // Vencimiento
          else if (element.operacion == 3) {
            element.operacionNombre = "Vencimiento";
          }
          else {
            element.operacionNombre = "-";
          }
        });
      }
      return data;
    }
    onMounted(() => {
      if (localStorage.getItem("TieneDatosContacto") == 'false'){
        router.push({ name: "EmployerContact" });
      } else {
        GetStoryPlan();
      }
    });
    return {
      normalizeNumberValidator,
      colombianCurrencyValidator,
      normalizeDateValidator,
      GoToPlanControl,
      storyPlan,
      loading,
    };
  },
});
