
import InputNumber from "primevue/inputnumber";
import PrimaValidacion from "@/validation/PayrollTransmission/Prima";
import useVuelidate from "@vuelidate/core";
import Tooltip from "@/components/layout/TooltipTransmission.vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [prima, validaciones] = new PrimaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, prima, {
      $scope: props.nomina,
    });

    return {
      prima,
      v$,
    };
  },
};
