
<template>

  <div class="employee-text">
    <li><b> Empleado: </b> {{ empleado.nombres }} {{ empleado.apellidos }}</li>
    <li><b>CUNE:</b> {{ detalle?.cune }}</li>
    <li>
      <b>{{ empleado.tipoIdentificacion }}</b> :
      {{ empleado.numeroIdentificacion }}
    </li>
    <li><b>Periodo de transmisión:</b> {{ periodo }}</li>
    <li><b>Fecha de aprobación de nómina:</b> {{ fechaAprobacion }}</li>
    <li><b>Fecha de ingreso del empleado:</b> {{ fechaIngreso }}</li>
  </div>
  
</template>

<script>

import moment from 'moment';
import { useStore } from "vuex";
import { onMounted, ref } from "vue";
import { obtenerPeriodo } from "@/helpers/helpers";

export default {
  props: {
    empleado: String,
    detalle: String,
    fechaIngreso: String,
    fechaAprobacion: String
  },
  setup(props) {

    const empleado = ref("");
    const detalle = ref("");
    const store = useStore();
    const periodo = obtenerPeriodo(store.state.DetalleEntradaNomina.Periodo);
    const fechaIngreso = ref("");
    const fechaAprobacion = ref("");

    onMounted(() => {
      
      empleado.value = props?.empleado;
      detalle.value = props?.detalle;

      fechaAprobacion.value = props.fechaAprobacion
        ? moment(props.fechaAprobacion).format("YYYY/MM/DD") : "";
      fechaIngreso.value = props.fechaIngreso
        ? moment(props.fechaIngreso).format("YYYY/MM/DD") : "";
    });

    return { empleado, detalle, periodo, fechaAprobacion, fechaIngreso };
  },
};
</script>
