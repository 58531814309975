
import router from '@/router'
import {useStore} from "vuex"
import {ref, reactive} from 'vue'
import{useState, useMutations} from '@/store/helper'
export default {
  name:"Initial",
  emits:["onFire"],
    components:{
    },
    setup(props:any,{emit}:any) {
       const store= useStore();
       const formRef = ref<HTMLFormElement>();
      let ruleForm= reactive( {
          TestSetValue: ''});
      let rules= reactive( {
          TestSetValue: [
            { required: true, message: 'El código de habilitación no puede estar vacío', trigger: 'blur' },
            { min: 1, message: 'El código de habilitación no puede estar vacío', trigger: ['blur', 'change'] },
            { pattern: /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i, message: 'El código de habilitación no es válido. Por favor verifica que no contenga espacios en blanco o caracteres especiales diferentes al guion', trigger: 'blur' }
          ],})
       const{setTestSet} = useMutations(['setTestSet']);
       function EviarTestset(){
          formRef.value?.validate((valid: boolean) => {
            if(valid){
                var data={
                  Identificacion:localStorage.getItem('Identificacion'),
                  Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
                  TestSetDian:ruleForm.TestSetValue,
                  }  
                setTestSet(data);
                emit("onFire","EnProceso")
                store.dispatch('SendTestSet',localStorage.getItem('Tokensin')).then(data=>{
                  console.log(data);
                  }).catch(error=>{
                        console.log(error);
                  });
            }
          });
      };
      function goToDashboard(){
           router.push({name:'Dashboard'});
        }
    return{
      goToDashboard,
      EviarTestset,
      rules,
      ruleForm,
      formRef
    };
  }
    
};
