
import router from "@/router";
import { useStore } from "vuex";
import { reactive, ref, onMounted } from "vue";
import Dialog from "primevue/dialog";
import LiquidacionNomina from "@/components/TransmisionNomina/LiquidacionNomina.vue";
import DeduccionesNomina from "@/components/TransmisionNomina/DeduccionesNomina.vue";
import { TabTransmission } from "@/models/Payroll/Enums";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import ResumenEmpleado from "@/components/DetalleNomina/ResumenEmpleado.vue";
import useVuelidate from "@vuelidate/core";
import { useConfirm } from "primevue/useconfirm";
import "@/assets/sass/views/_PayrollTransmission.scss";
import NominaValidacion from "@/validation/PayrollTransmission/Empleado";
import TransmitirNomina from "@/components/TransmisionNomina/TransmitirNomina.vue";
import ConfirmDialog from "primevue/confirmdialog";
import { useMutations } from "@/store/helper";

export default {
  components: {
    LiquidacionNomina,
    DeduccionesNomina,
    ResumenEmpleado,
    TransmitirNomina,
    ConfirmDialog,
    TabView,
    TabPanel,
    Dialog,
  },
  beforeRouteEnter(to, from, next) {
    if (from.name) {
      next();
    } else {
      next("Transmission");
    }
  },
  setup() {
    const store = useStore();
    const confirm = useConfirm();
    const parametricas = ref({});
    const tabs = TabTransmission;
    const activeTab = ref(tabs.Liquidacion);
    const error = ref(false);
    const nominaEmpleado = ref("");
    const empleadoId = ref("");

    const { Empleado, Detalle: detalle } = store.state.DetalleNomina;
    const periodo = store.state.DetalleEntradaNomina.Periodo;
    const { showTranssmission } = useMutations(["showTranssmission"]);

    const empleado = reactive({
      nombres: `${Empleado.firstName} ${Empleado.otherNames}`,
      apellidos: `${Empleado.lastName} ${Empleado.secondLastName}`,
      tipoIdentificacion: Empleado.tipoIdentificacion,
      numeroIdentificacion: Empleado.documentNumber,
    });

    let [nomina, reglasNomina] =
      new NominaValidacion().ObtenerReglasValidacion();

    const fechaIngreso = nomina.AdmissionDate;
    const fechaAprobacion = detalle.fechaConsultaDIAN;
    const scopeNomina = "Nomina";

    const validacionNomina = useVuelidate(reglasNomina, nomina, {
      $scope: scopeNomina,
    });

    const urlvd = ref("");
    const visiblePopUpIndividual = ref(false);
    const VideoNominaAjustePortal = ref();
    let featureFlag = ref(false);

    const ConfirmarTransmision = () => {
      confirm.require({
        message: "",
        header: "Importante",
        acceptClass: "p-button-danger",
        acceptLabel: "Transmitir",
        rejectLabel: "← Volver",
        accept: () => {
          TransmitirNomina();
        },
        reject: () => {
          confirm.close();
        },
        onHide: () => {
          confirm.close();
        },
      });
    };

    const ValidarInformacion = () => {
      validacionNomina.value.$validate();
      if (validacionNomina.value.$error) {
        error.value = true;
      } else {
        ConfirmarTransmision();
      }
    };

    const TransmitirNomina = () => {
      showTranssmission(true);
      empleadoId.value = detalle.empleadoId;
      nominaEmpleado.value = JSON.stringify(
        store.state.DetalleNomina.JsonNomina
      );
    };

    const CerrarModal = () => {
      error.value = false;
    };

    const GoToSummary = () => {
      router.push({
        name: "SummaryTransmission",
      });
    };

    function urlVideo(nombreVideo: string) {
      var params = {
        Token: localStorage.getItem("Tokensin"),
        NombreVideo: nombreVideo,
      };
      store.dispatch('GetUrlVideo', params).then(data => {
        urlvd.value = data.data;
      }).catch(error => {
        console.log("Error al obtener el video en azure.");
      });
    }
    const detenerVideo = () => {
      if (VideoNominaAjustePortal.value) {
        VideoNominaAjustePortal.value.pause();
        visiblePopUpIndividual.value = false;
      }
    };

    function GetFeatureFlag() {
      const data =
      {
        token: localStorage.getItem('Tokensin'),
        featureFlagName: 'PBI-232826'
      }
      store.dispatch('GetStatusFeatureFlag', data).then(response => {
        featureFlag.value = response.data;
      })
        .catch((err) => {
          console.error(err);
        });
    };

    onMounted(() => {
      urlVideo("VideoNominaAjustePortal.mp4");
      GetFeatureFlag();
    });
    return {
      GoToSummary,
      nomina,
      detalle,
      empleado,
      activeTab,
      fechaIngreso,
      fechaAprobacion,
      ValidarInformacion,
      scopeNomina,
      parametricas,
      CerrarModal,
      error,
      store,
      periodo,
      nominaEmpleado,
      empleadoId,
      urlvd,
      urlVideo,
      visiblePopUpIndividual,
      VideoNominaAjustePortal,
      detenerVideo,
      GetFeatureFlag,
      featureFlag
    };
  },
};
