

import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import { TabLiquidacion } from "@/models/Payroll/Enums";
import Empleado from "@/components/TransmisionNomina/Empleado.vue";
import DatosEmpresa from "@/components/TransmisionNomina/DatosEmpresa.vue";
import NominaElectronica from "@/components/TransmisionNomina/NominaElectronica.vue";
import LiquidacionDevengos from "@/components/TransmisionNomina/LiquidacionDevengos.vue";
import LiquidacionDeducciones from "@/components/TransmisionNomina/LiquidacionDeducciones.vue";
import TotalNomina from "@/components/TransmisionNomina/TotalNomina.vue";

export default {
  components: {
    TabView,
    TabPanel,
    Empleado,
    TotalNomina,
    DatosEmpresa,
    NominaElectronica,
    LiquidacionDevengos,
    LiquidacionDeducciones,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    
    const tabs = TabLiquidacion;
    const activeTab = ref(tabs.Empleado);
    const scopeNomina = props.nomina;

    return {
      activeTab,
      scopeNomina
    };
  },
};
