<template>
  <div>
    <BannerRenovacionDebidaDiligencia
      v-if="mostrarBannerDD"
      :estadoDD="estadoDD"
    ></BannerRenovacionDebidaDiligencia>
  </div>
  <div>
    <h2 class="Transmision_title">Nómina Electrónica</h2>
    <center>
      <input
        class="Button_BColorDegraded Button_fontButtonPrincipal"
        type="button"
        @click="GotoUploadFile()"
        value="Transmitir"
      />
    </center>
  </div>
  <el-form label-position="top" label-width="auto" class="greenItem">
    <p v-if="backToFilter" class="txtT">
      Sí deseas consultar un periodo diferente podrás hacerlo seleccionando el
      año y el mes, de lo contrario haz clic en la opción X.
    </p>
    <p v-else class="txtT">
      A continuación encuentras los dos últimos periodos de transmisión que
      realizaste. En el caso que desees consultar la información de un periodo
      diferente, podrás hacerlo seleccionando el año y mes.
    </p>
    <el-row :gutter="24" justify="space-evenly" class="rowB">
      <el-col
        :xs="2"
        :sm="2"
        :md="2"
        :lg="2"
        :xl="2"
        style="padding-left: 35px"
      >
        <el-form-item label="Año" style="text-align: center">
          <el-select v-model="objetoPeriodo.Anio" placeholder="Seleccionar">
            <el-option
              v-for="item in anios"
              :key="item.parametricasNEId"
              :label="item.valor"
              :value="item.valor"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col :xs="3" :sm="3" :md="3" :lg="3" :xl="3">
        <el-form-item label="Mes">
          <el-select v-model="objetoPeriodo.Mes" placeholder="Seleccionar">
            <el-option
              v-for="item in meses"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
      </el-col>
      <el-col
        :xs="1"
        :sm="1"
        :md="1"
        :lg="1"
        :xl="1"
        style="padding-top: 50px; padding-left: 0px"
      >
        <a @click="GetNominaPeriodo()"
          ><font-awesome-icon :icon="['fas', 'search']"
        /></a>
        <a v-if="backToFilter" @click="CleanPeriodo()"
          ><font-awesome-icon
            :icon="['fas', 'times']"
            style="padding-left: 5px"
            size="lg"
        /></a>
      </el-col>
    </el-row>
  </el-form>
  <div>
    <table class="Table">
      <thead>
        <tr>
          <th class="Table_th">Periodo</th>
          <th class="Table_th">Cantidad documentos</th>
          <th class="Table_th">Documentos aprobados</th>
          <th class="Table_th">Documentos rechazados</th>
          <th class="Table_th">Estado</th>
          <th class="Table_th">Opciones</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in json"
          :key="item.id"
          :class="item.estado == 'En validación' ? 'tr-validacion' : 'Table_td'"
        >
          <td class="Table_td">{{ item.periodo }}</td>
          <td class="Table_td">{{ item.cantidadD }}</td>
          <td class="Table_td">{{ item.documentosAprobados }}</td>
          <td class="Table_td">{{ item.documentosRechazados }}</td>
          <td class="Table_td">{{ item.estado }}</td>
          <td class="Table_td Table_options">
            <a
              v-if="
                item.estado == 'Rechazada' ||
                item.estado == 'Transmitida parcialmente' ||
                item.estado == 'Transmitida'
              "
              @click="GotoError(item)"
              ><font-awesome-icon :icon="['fas', 'search']"
            /></a>

            <el-dropdown
              v-if="
                item.estado == 'Transmitida parcialmente' ||
                item.estado == 'Transmitida'
              "
            >
              <span>
                <font-awesome-icon
                  :icon="['fas', 'download']"
                  style="padding-left: 5px"
                  size="lg"
                />
              </span>
              <template #dropdown>
                <el-dropdown-menu class="menuOpcionesDescarga">
                  <el-dropdown-item @click="GetCertificate(item)">
                    <font-awesome-icon
                      :icon="['fas', 'file-pdf']"
                      style="color: #d12308"
                      size="lg"
                    />
                    Certificado de transmisión
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="DescargarArchivoNominasAprobadas(item, 'NominasAprobadasExcel')"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-excel']"
                      style="color: #1e6e24"
                      size="lg"
                    />
                    Nóminas aprobadas
                  </el-dropdown-item>
                  <el-dropdown-item
                    v-if="featureFlag"
                    @click="DescargarArchivoNominasAprobadas(item, 'NominasAprobadasXml')"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-archive']"
                      style="color: #1e6e24"
                      size="lg"
                    />
                    Nóminas aprobadas XML
                  </el-dropdown-item>
                  <el-dropdown-item
                    @click="DescargarArchivoNominasAprobadas(item, 'RepresentacionesGraficas')"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'file-alt']"
                      style="color: #d12308"
                      size="lg"
                    />
                    Representación gráfica
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>

            <a
              v-if="item.estado == 'En validación'"
              @click="GotoError(item)"
              class="crud-icon"
              ><font-awesome-icon :icon="['fas', 'search']"
            /></a>
            <span v-if="item.estado == 'En validación'" class="crud-icon"
              ><font-awesome-icon icon="sync" class="fa-spin"
            /></span>

            <a
              v-if="item.estado == 'En validación'"
              @click="GotoPayrollProcessingValidation()" 
              style="display: inline-block; height: 17.6px; padding: 0 3px;"
              >
              <img
                :src="require('@/assets/img/file-edit-icon.svg')"
                alt="icono"
                width="17"
                height="17"
                style="display: inline;"
              />
            </a>

          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <el-dialog
    :show-close="false"
    title="Error"
    v-model="dialogVisible"
    width="22%"
    center
  >
    <div class="bodyError">
      <span>
        {{ messageError }}
      </span>
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="(dialogVisible = false), CleanPeriodo()"
          ><span>Aceptar</span>
        </el-button>
      </span>
    </template>
  </el-dialog>

  <Dialog
    v-model:visible="nominasFinalizadas"
    modal
    :closable="false"
    header="Importante"
    :draggable="false"
    id="p-dialog"
    class="error-dialog"
  >
    <img src="../assets/img/Warning.png" alt="icono" width="60" height="60" />

    <p>
      No se encontraron nóminas aprobadas y/o rechazadas en el proceso de
      transmisión, todas las nóminas se encuentran en validación.
    </p>

    <template #footer>
      <Button icon="pi pi-times" @click="CerrarModal" text>← Volver</Button>
    </template>
  </Dialog>

  <Dialog
    v-model:visible="informeNominasAprobadasEnProgreso"
    modal
    :closable="false"
    header="Importante"
    :draggable="false"
    id="p-dialog"
    class="error-dialog"
  >
    <img src="../assets/img/Warning.png" alt="icono" width="60" height="60" />

    <p v-text="mensajeInformeEnProceso"></p>

    <template #footer>
      <Button icon="pi pi-times" @click="CerrarModalInformeEnProgreso" text
        >← Volver</Button
      >
    </template>
  </Dialog>

  <Spinner v-if="loading" type="modern"></Spinner>
</template>
<script>
import { ref } from "@vue/reactivity";
import { useMutations } from "@/store/helper";
import router from "@/router";
import { onBeforeMount, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import Spinner from "@/components/layout/Spinner.vue";
import BannerRenovacionDebidaDiligencia from "@/components/DueDiligence/BannerRenovacionDebidaDiligencia.vue";
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";
import Dialog from "primevue/dialog";
import { DescargarArchivo } from "@/helpers/helpers";

export default {
  name: "Transmission",
  components: {
    Spinner,
    BannerRenovacionDebidaDiligencia,
    Dialog,
  },
  setup() {
    var intervalState;
    const objetoPeriodo = reactive({
      Anio: "",
      Mes: "",
    });
    const dialogVisible = ref(false);
    const messageError = ref("");
    const loading = ref(false);
    const backToFilter = ref(false);
    const store = useStore();
    const { setStateMenu } = useMutations(["setStateMenu"]);
    const { setDetalleEntradaNomina } = useMutations([
      "setDetalleEntradaNomina",
    ]);
    const { setEntradaNominaId } = useMutations(["setEntradaNominaId"]);
    const { setDataEmpleador } = useMutations(["setDataEmpleador"]);
    const { setDetalleNomina } = useMutations(["setDetalleNomina"]);
    const meses = store.state.meses;
    const anios = ref([]);
    const mostrarBannerDD = ref(false);
    const estadoDD = ref(0);
    const nominasFinalizadas = ref(false);
    setStateMenu(true);
    const json = ref([]);
    let featureFlag = ref(false);

    const CerrarModal = () => {
      nominasFinalizadas.value = false;
    };
    const informeNominasAprobadasEnProgreso = ref(false);
    const CerrarModalInformeEnProgreso = () => {
      informeNominasAprobadasEnProgreso.value = false;
    };
    const mensajeInformeEnProceso = ref("");

    function GetListTransmission() {
      loading.value = true;
      var params = {
        Token: localStorage.getItem("Tokensin"),
        Anio: objetoPeriodo.Anio,
        Mes: ValidarMes(objetoPeriodo.Mes),
      };
      store
        .dispatch("GetListTransmission", params)
        .then((data) => {
          json.value = [];
          loading.value = false;
          data.data.forEach((element) => {
            var estado = "";
            if (element.estado == "TransmitidaParcialmente") {
              estado = "Transmitida parcialmente";
            } else if (element.estado == "EnValidacion") {
              estado = "En validación";
            } else {
              estado = element.estado;
            }
            var Nomina = {
              anio: element.anio,
              mes: element.mes,
              periodo: element.anio + "-" + GetFormattePeriodo(element.mes),
              cantidadD: element.cantidad,
              documentosAprobados: element.cantidadDocumentosAprobados,
              documentosRechazados: element.cantidadDocumentosRechazados,
              erroresValidacion: element.cantidadErroresValidacion,
              documentosEnValidacion: element.CantidadNominasValidacion,
              estado: estado,
            };

            json.value.push(Nomina);
            reloadPage(element.estado, loading.value);
          });
        })
        .catch((error) => {
          loading.value = false;
          if (objetoPeriodo.Anio != "" || objetoPeriodo.Mes != "") {
            messageError.value =
              "No se encontro Historial de Nominas para el periodo seleccionado.";
            dialogVisible.value = true;
          }
          console.log(error);
        });
    }

    function ValidarMes(mesSeleccionado) {
      if (mesSeleccionado && isNaN(mesSeleccionado)) {
        return meses
          .filter((mes) => {
            return mes.label == mesSeleccionado;
          })
          .shift().value;
      } else {
        return mesSeleccionado;
      }
    }

    function GetCertificate(DataEmpleador) {
      loading.value = true;
      setDataEmpleador({
        Anio: DataEmpleador.anio,
        Mes: DataEmpleador.mes,
        CantidadAprobados: DataEmpleador.documentosAprobados,
      });
      store
        .dispatch("GetCertificate", localStorage.getItem("Tokensin"))
        .then((response) => {
          loading.value = false;
          const linkSource = `data:application/pdf;base64,${response.data}`;
          const downloadLink = document.createElement("a");
          const fileName = "Certificado de transmisión.pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
          window.location.herf = downloadLink.href;
        })
        .catch((err) => {
          console.error(err);
          loading.value = false;
        });
    }

    function closeInterval() {
      window.clearTimeout(intervalState);
    }
    function reloadPage(estado, loading) {
      intervalState = window.setTimeout(() => {
        if (estado == "EnValidacion" && loading == false) {
          GetListTransmission();
        }
      }, 60000);
    }
    function GotoUploadFile() {
      closeInterval();
      router.push({ name: "LoadFile" });
    }
    function GotoError(nomina) {
      if (
        nomina.documentosAprobados == 0 &&
        nomina.documentosRechazados == 0 &&
        nomina.erroresValidacion == 0
      ) {
        nominasFinalizadas.value = true;
      } else {
        closeInterval();
        setDetalleEntradaNomina({
          Anio: nomina.anio,
          Mes: nomina.mes,
          CantidadDocumentos: nomina.cantidadD,
          CantidadAprobados: nomina.documentosAprobados,
          CantidadErrores: nomina.documentosRechazados,
          ErroresValidacion: nomina.erroresValidacion,
          Periodo: `${nomina.anio}-${
            nomina.mes < 10 ? `0${nomina.mes}` : nomina.mes
          }`,
          Estado: nomina.estado,
        });

        setEntradaNominaId({
          EntradaNominaId: "",
          CantidadDocumentos: 0,
          CantidadAprobados: 0,
          CantidadErrores: 0,
          Periodo: "",
        });

        router.push({ name: "SummaryTransmission" });
      }
    }
    function GetFormattePeriodo(dd) {
      if (dd < 10) {
        dd = "0" + dd;
      }
      return dd;
    }

    function GetNominaPeriodo() {
      if (objetoPeriodo.Anio == "" || objetoPeriodo.Mes == "") {
        messageError.value =
          "Se debe seleccionar el mes y año correspondiente al periodo de nómina que desea consultar.";
        dialogVisible.value = true;
      }
      backToFilter.value = true;
      GetListTransmission();
    }
    function CleanPeriodo() {
      objetoPeriodo.Anio = "";
      objetoPeriodo.Mes = "";
      backToFilter.value = false;
      GetListTransmission();
    }
    function GetParametricasNE() {
      store
        .dispatch("GetParametricasNE", localStorage.getItem("Tokensin"))
        .then((response) => {
          anios.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    function GetFeatureFlag() {
      loading.value = true;
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-251261",
      };
      store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          featureFlag.value = response.data;
          loading.value = false;
        })
        .catch((err) => {
          loading.value = false;
          console.error(err);
        });
    }

    function MostrarOcultarBannerDD() {
      store
        .dispatch("validarEstadoParaMostrarComponenteDD")
        .then((response) => {
          estadoDD.value = response;
          if (
            response != EstadoDebidaDiligencia.AprobadaDD &&
            response != EstadoDebidaDiligencia.NoDefinido &&
            response != EstadoDebidaDiligencia.CreadaDD
          ) {
            mostrarBannerDD.value = true;
          }
        });
    }

    function ValidarAjusteNomina() {
      if (store.state.DetalleNomina.TransmisionAjuste) {
        backToFilter.value = true;
        setDetalleNomina({
          TransmisionAjuste: false,
        });
        objetoPeriodo.Anio = store.state.DetalleEntradaNomina.Anio;
        objetoPeriodo.Mes = meses
          .filter((mes) => {
            return store.state.DetalleEntradaNomina.Mes.toString() == mes.value;
          })
          .shift().label;
      }
    }

    function DescargarArchivoNominasAprobadas(dataEmpleador, tipoInforme) {
      loading.value = true;
      setDataEmpleador({
        Anio: dataEmpleador.anio,
        Mes: dataEmpleador.mes,
      });
      const parametros = {
        Token: localStorage.getItem("Tokensin"),
        TipoInforme: tipoInforme,
      };
      store
        .dispatch("DescargarArchivoNominasAprobadas", parametros)
        .then((response) => {
          if (response.data.mensaje) {
            mensajeInformeEnProceso.value = response.data.mensaje;
            informeNominasAprobadasEnProgreso.value = true;
          }
          if (response.data.archivo) {
            DescargarArchivo(
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              response.data.archivo.archivo,
              response.data.archivo.nombre
            );
          } 
          loading.value = false;
        })
        .catch((err) => {
          console.error("DescargarArchivoNominasAprobadas", err);
          loading.value = false;
        });
    }

    function GotoPayrollProcessingValidation() {
      closeInterval();
      router.push({ name: "PayrollProcessingValidation" });
    }

    onMounted(() => {
      ValidarAjusteNomina();
      if (localStorage.getItem("TieneDatosContacto") == "false") {
        router.push({ name: "EmployerContact" });
      } else {
        GetListTransmission();
        GetParametricasNE();
      }
    });

    onBeforeMount(() => {
      GetFeatureFlag();
      MostrarOcultarBannerDD();
    });

    return {
      json,
      GotoUploadFile,
      GotoError,
      loading,
      reloadPage,
      closeInterval,
      objetoPeriodo,
      GetListTransmission,
      CleanPeriodo,
      backToFilter,
      GetNominaPeriodo,
      messageError,
      dialogVisible,
      meses,
      anios,
      GetCertificate,
      GetParametricasNE,
      mostrarBannerDD,
      estadoDD,
      CerrarModal,
      nominasFinalizadas,
      GetFeatureFlag,
      DescargarArchivo,
      DescargarArchivoNominasAprobadas,
      informeNominasAprobadasEnProgreso,
      CerrarModalInformeEnProgreso,
      mensajeInformeEnProceso,
      featureFlag,
      GotoPayrollProcessingValidation
    };
  },
};
</script>