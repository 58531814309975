
import { useStore } from "vuex";
import router from "@/router";
import { useMutations } from "@/store/helper";
import { computed, nextTick, onMounted, ref } from "vue";
import Spinner from "@/components/layout/Spinner.vue";
import Dialog from "primevue/dialog";
import PopUpConfirmarTransmision from '@/components/TransmisionNomina/PopUpConfirmarTransmision.vue';
import { useConfirm } from "primevue/useconfirm";
import ConfirmDialog from "primevue/confirmdialog";

export default {
  name: "LoadFile",
  components: { Spinner, Dialog, PopUpConfirmarTransmision, ConfirmDialog },
  setup(props, { emit }) {
    const loading = ref(false);
    const dialogVisible = ref(false);
    const ValidationVisible = ref(false);
    const buttonStatus = ref(false);
    const messageError = ref("");
    const errorModalTitle = ref("Error");
    const message = ref("");
    const year = ref("");
    const month = ref("");
    const store = useStore();
    const { setCargueArchivo } = useMutations(["setCargueArchivo"]);
    const { setStateMenu } = useMutations(["setStateMenu"]);
    const meses = store.state.meses;
    const anios = ref([]);
    const visiblePopUpBotones = ref(false);
    const visiblePopUpIndividual = ref(false);
    const visiblePopUpAjuste = ref(false);
    let pbi232826 = ref(false);
    let pbi236116 = ref(false);
    const urlvdIndividual = ref("");
    const urlvdAjuste = ref("");
    const videoIndividual = ref();
    const videoAjuste = ref();
    let accept = ref("");
    const fileInput = ref<HTMLInputElement | null>(null);
    const file = ref<File | null>(null);
    const mostrarPopUpConfirmarTransmision = ref(false);
    const confirm = useConfirm();    
    const notificacionFinalizacion = ref({});

    const inputId = `custom-file-input-${Math.random()
      .toString(36)
      .substr(2, 9)}`;

    const fileMessage = computed(() =>
      file.value
        ? file.value.name
        : `Seleccione un archivo en formato ${
            pbi236116.value ? ".xlsx o .zip" : ".xlsx"
          }`
    );

    const onFileChange = () => {
      if (
        fileInput.value &&
        fileInput.value.files &&
        fileInput.value.files.length > 0
      ) {
        const selectedFile = fileInput.value.files[0];
        if (isValidFile(selectedFile)) {
          file.value = selectedFile;
          emit("file-selected", selectedFile);
        } else {
          fileInput.value.value = "";
          file.value = null;
          messageError.value = `El archivo no es válido. Por favor, seleccione un archivo ${
            pbi236116.value ? ".xlsx o .zip" : ".xlsx"
          }`;
          dialogVisible.value = true;
        }
      }
    };

    const clearFile = () => {
      if (fileInput.value) {
        fileInput.value.value = "";
      }
      file.value = null;
      emit("file-selected", null);
    };

    const isValidFile = (file: File) => {
      const validExtensions = pbi236116.value ? [".xlsx", ".zip"] : [".xlsx"];
      const fileExtension = file.name.split(".").pop()?.toLowerCase();
      return fileExtension
        ? validExtensions.includes(`.${fileExtension}`)
        : false;
    };

    setStateMenu(true);
    function UploadExcel(upload: any) {
      file.value = upload.target.files[0];
    }

    const LandingPage = () => {
      const urls = process.env.VUE_APP_PreguntasFrecuentes;
      window.open(urls, "_blank");
    };

    function messageValidation() {
      if (buttonStatus.value == true) {
        message.value = "Cargando el archivo";
      } else {
        message.value = "";
      }
    }

    
    function ConfirmarTransmision(){
      GetFeatureFlag("PBI-240406").then((result) => {
        if(result)
        {      
          loading.value = false;
          mostrarPopUpConfirmarTransmision.value = true;
        }
        else
        {
          UploadFile();
        }
      });
    }

    function UploadFile() {
      mostrarPopUpConfirmarTransmision.value = false;
      if (year.value == "" || month.value == "") {
        messageError.value =
          "Se debe seleccionar el mes y año correspondiente al periodo de nómina";
        dialogVisible.value = true;
      } else if (file.value == null) {
        messageError.value = `Se debe seleccionar un archivo en formato ${
          pbi236116.value ? ".xlsx o .zip" : ".xlsx"
        }.`;
        dialogVisible.value = true;
      } else {
        var data = {
          NumeroIdentificacion: localStorage.getItem("Identificacion"),
          TipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          PeriodoTransmision: year.value + "-" + month.value,
          Archivo: file.value,
        };
        year.value = "";
        month.value = "";
        file.value = null;
        setCargueArchivo(data);
        loading.value = true;
        buttonStatus.value = true;
        store
          .dispatch(
            "CargarArchivoTransmision",
            localStorage.getItem("Tokensin")
          )
          .then((resp) => {
            ValidationVisible.value = true;
            buttonStatus.value = false;
            loading.value = false;
            message.value = "";
            if (resp.data != ""){
              notificacionFinalizacion.value = resp.data;
            }
          })
          .catch((error) => {
            dialogVisible.value = true;
            if (error.status == 400) {
              messageError.value = error.response;
              errorModalTitle.value = "Importante";
            } else {
              messageError.value = "Ocurrió un error al guardar el archivo de transmisión.";
              errorModalTitle.value = "Error";
            }
            buttonStatus.value = false;
            loading.value = false;
            message.value = "";
            clearFile();
          });
      }
    }

    function GoTransmission() {
      router.push({ name: "Transmission" });
    }

    function GotloadFile() {
      location.reload();
    }

    const Download = (nombreArchivo: string) => {
      const url =
        process.env.VUE_APP_Gateway_API +
        "Empleador/DownloadInstructive?nombre=" +
        nombreArchivo;
      window.open(url, "_blank");
    };

    function GetParametricasNE() {
      store
        .dispatch("GetParametricasNE", localStorage.getItem("Tokensin"))
        .then((response) => {
          anios.value = response.data;
        })
        .catch((err) => {
          console.error("Ocurrió un error al intentar obtener las parametricas.");
        });
    }

    function GetFeatureFlag(featureFlagName: String): Promise<boolean> {
      loading.value = true;
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: featureFlagName,
      };
      return store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          return response.data;
        })
        .catch((err) => {
          loading.value = false;
          console.error("Ocurrió un error al intentar validar la FF");
        });
    }

    function urlVideo(nombreVideo: string) {
      var params = {
        Token: localStorage.getItem("Tokensin"),
        NombreVideo: nombreVideo,
      };
      store
        .dispatch("GetUrlVideo", params)
        .then((data) => {
          if (nombreVideo == "VideoNominaIndividual.mp4") {
            urlvdIndividual.value = data.data;
          } else {
            urlvdAjuste.value = data.data;
          }
        })
        .catch((error) => {
          console.error("Error al obtener el video en azure.");
        });
    }

    const detenerVideo = (nombreVideo: string) => {
      if (nombreVideo == "videoIndividual") {
        if (videoIndividual.value) {
          videoIndividual.value.pause();
          visiblePopUpIndividual.value = false;
        }
      } else {
        if (videoAjuste.value) {
          videoAjuste.value.pause();
          visiblePopUpAjuste.value = false;
        }
      }
    };

    function NotificarFinalizacionTransmision(){
      loading.value = true;
      var requestData = {
        token: localStorage.getItem("Tokensin"),
        entradaNominaId: notificacionFinalizacion.value['entradaNominaId'],
      };
      store
        .dispatch("NotificarFinalizacionTransmision", requestData)
        .then(() => {
          loading.value = false;
          GoTransmission();
        })
        .catch(() => {
          console.error("Ocurrió un error al intentar activar la notificación de finalización de la transmisión.");
          loading.value = false;
          GoTransmission();
        }
      );
    }

    function ConfirmarNotificacionFinalizacion() {
      if (Object.keys(notificacionFinalizacion.value).length === 0) {
        GoTransmission();
      } else {
        ValidationVisible.value = false;
        confirm.require({
          message: notificacionFinalizacion.value['mensajeConfirmacion'],
          header: "Importante",
          acceptClass: "btn-confirm",
          rejectClass: "btn-confirm",
          acceptLabel: "Si",
          rejectLabel: "No",
          accept: () => {
            NotificarFinalizacionTransmision();
          },
          reject: () => {
            confirm.close();
            GoTransmission();
          },
          onHide: () => {
            confirm.close();
            GoTransmission();
          },
        })
      }
    }

    onMounted(() => {
      if (localStorage.getItem("TieneDatosContacto") == "false") {
        router.push({ name: "EmployerContact" });
      } else {
        GetParametricasNE();
      }
      nextTick(() => {
        fileInput.value = document.getElementById(
          inputId
        ) as HTMLInputElement | null;
      });
      GetFeatureFlag("PBI-232826").then((result) => {
        pbi232826.value = result;
        loading.value = false;
      });
      GetFeatureFlag("PBI-236116").then((result) => {
        pbi236116.value = result;
        accept.value = pbi236116.value ? "application/zip,.xlsx" : ".xlsx";
        loading.value = false;
      });
      urlVideo("VideoNominaIndividual.mp4");
      urlVideo("VideoNominaAjusteExcel.mp4");
    });

    return {
      Download,
      year,
      month,
      file,
      UploadFile,
      UploadExcel,
      dialogVisible,
      messageError,
      errorModalTitle,
      message,
      ValidationVisible,
      buttonStatus,
      messageValidation,
      GoTransmission,
      GotloadFile,
      loading,
      meses,
      anios,
      GetParametricasNE,
      LandingPage,
      pbi232826,
      pbi236116,
      GetFeatureFlag,
      visiblePopUpBotones,
      visiblePopUpIndividual,
      visiblePopUpAjuste,
      urlVideo,
      urlvdIndividual,
      urlvdAjuste,
      videoIndividual,
      detenerVideo,
      videoAjuste,
      accept,
      inputId,
      fileInput,
      fileMessage,
      onFileChange,
      clearFile,
      mostrarPopUpConfirmarTransmision,
      ConfirmarTransmision,
      notificacionFinalizacion,
      ConfirmarNotificacionFinalizacion,
      NotificarFinalizacionTransmision,
    };
  },
};
