import { computed, reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import { useStore } from "vuex";

class DatosEmpresaValidacion {

    public ObtenerReglasValidacion() {

        const store = useStore();
        const { CompanyLocation } = store.state.DetalleNomina.JsonNomina;
        const datosEmpresa = reactive(CompanyLocation);
        const mensajeError = "Campo obligatorio.";
    
        const reglasEmpresa = computed(() => {
          return {
            CountryCode: {
              required: helpers.withMessage(
                `${mensajeError} NIE013, NIE035`,
                required
              ),
            },
            DepartmentCode: {
              required: helpers.withMessage(
                `${mensajeError} NIE014, NIE036`,
                required
              ),
            },
            CityCode: {
              required: helpers.withMessage(
                `${mensajeError} NIE015, NIE037`,
                required
              ),
            },
            Address: {
              required: helpers.withMessage(`${mensajeError} NIE038`, required),
            },
          };
        });
    
        return [datosEmpresa, reglasEmpresa] as const;
    }
}

export default DatosEmpresaValidacion;