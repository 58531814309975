import { reactive } from 'vue'
import { required, helpers, maxValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class LicenciasValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const strikes = reactive({
      items: store.state.DetalleNomina.JsonNomina.Strikes,
    });
    const huelgasLegales = reactive(
      store.state.DetalleNomina.JsonNomina.Strikes
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          Quantity: {
            required: helpers.withMessage(
              `${campoObligatorio} NIE145`,
              required
            ),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE145`,
              maxValue(maxInt)
            ),
          },
        }),
      },
    };

    return [strikes, huelgasLegales, reglas] as const;
  }
}

export default LicenciasValidacion;
