import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class PTAValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const PTA = reactive(
      store.state.DetalleNomina.JsonNomina
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasPTA = computed(() => {
      return {
        PartyPaymentAccruals: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE193`,
            requiredIf(PTA.ThirdPartyPaymentAccruals[0] > maxInt)
          ),
        },
        PayrollPrepaidAccruals: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE194`,
            requiredIf(PTA.PrepaidAccruals[0] > maxInt)
          ),
        },
        Endowment: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE156`,
            requiredIf(PTA.GeneralAccruals.Endowment > maxInt)
          ),
        },
        SustainableSupport: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE157`,
            requiredIf(PTA.GeneralAccruals.SustainableSupport > maxInt)
          ),
        },
        Telecommuting: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE158`,
            requiredIf(PTA.GeneralAccruals.Telecommuting > maxInt)
          ),
        },
        RetirementBonus: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE159`,
            requiredIf(PTA.GeneralAccruals.RetirementBonus > maxInt)
          ),
        },
        Indemnification: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE160`,
            requiredIf(PTA.GeneralAccruals.Indemnification > maxInt)
          ),
        },
        RefundAccrual: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE201`,
            requiredIf(PTA.GeneralAccruals.RefundAccrual > maxInt)
          ),
        },
      };
    });

    return [PTA, reglasPTA] as const;
  }
}

export default PTAValidacion;