
  
  import router from "@/router";
  import { useStore } from "vuex";
  import { Kushki } from "@kushki/js";
  import useVuelidate from "@vuelidate/core";
  import { useMutations } from "@/store/helper";
  import Spinner from "@/components/layout/Spinner.vue";
  import Checkbox from "@/components/layout/Checkbox.vue";
  import Direccion from "@/components/layout/Direccion.vue";
  import DivipolaDian from "@/components/layout/DivipolaDian.vue";
  import { ref, reactive, defineComponent, onMounted } from "vue";
  import { validationRules } from "@/validation/KushkiPaymentForm";
  import Tooltip from "@/components/layout/TooltipTransmission.vue";
  import { DocumentTypes, PersonType, DatosPago } from "@/models/payment";
  import MessageToClient from "@/components/Payment/MessageToClient.vue";
  import ValidadorFranjaHoraria from "@/components/layout/ValidadorFranjaHoraria.vue";
  import { colombianCurrencyValidator, normalizeNumberValidator } from "@/store/Payment/plans";
  import { ColombianTokenRequest, UserType } from "@kushki/js/lib/types/remote/transfer_token_request";
  import ProveedorPago from "../models/payment/ProveedorPago";
  
  export default defineComponent({
    name: "KushkiPaymentForm",
    props: {
      valuePlan: Number,
      documentsCount: Number,
      featureFlag: Boolean
    },
    components: {
      Spinner,
      MessageToClient,
      DivipolaDian,
      Direccion,
      Tooltip,
      Checkbox,
      ValidadorFranjaHoraria,
    },
    setup(props) {
      const store = useStore();
      const bancos = ref([{ code: "", name: "" }]);
      const formRef = ref<HTMLFormElement>();
      const datosPago = reactive({ ...DatosPago });
      const { setStateMenu } = useMutations(["setStateMenu"]);
  
      const divipolaRef = ref();
      const direccionRef = ref();
  
      const expedirFactura = ref(false);
      const codigoPais = ref("");
      const codigoDpto = ref("");
      const codigoMunicipio = ref("");
      const direccion = ref("");
      const metadataDireccion = ref("");
      const objetoDireccion = ref();
      const objetoDivipola = ref();
      const franjaPagoNoDisponible = ref("");
      const mensajePagoNoDisponible = ref("");
      const pagoRestringido = ref(false);
  
      setStateMenu(false);
      const rules = reactive(validationRules);
      const processPayment = ref(true);
      const errorPayment = ref(false);
  
      const kushki = new Kushki({
        merchantId: localStorage.getItem("publicMerchantId") ?? "", // Your public merchant id
        inTestEnvironment: process.env.VUE_APP_EsKushkiPruebas == "true",
      });
  
      function getBanks() {
        if (props.valuePlan) {
          let callback = function (response: any) {
            if (!response.code) {
              bancos.value = response;
              localStorage.setItem("bancos", JSON.stringify(response));
              processPayment.value = false;
            } else {
              console.error(
                "Error: ",
                response.error,
                "Code: ",
                response.code,
                "Message: ",
                response.message
              );
            }
          };
  
          kushki.requestBankList(callback);
        } else {
          comebackToPlans();
        }
      }
  
      const validarTitularidad = () => {
        if (!expedirFactura.value) {
          datosPago.razonSocialTitular = "";
          datosPago.tipoIdentificacionTitular = "";
          datosPago.numeroIdentificacionTitular = "";
        }
      };
  
      const titularidadFactura = () => {
        let titularidadFactura;
        if (
          !datosPago.razonSocialTitular &&
          !datosPago.tipoIdentificacionTitular &&
          !datosPago.numeroIdentificacionTitular
        ) {
          titularidadFactura = "";
        } else {
          titularidadFactura = {
            identificacionSinonimo: datosPago.tipoIdentificacionTitular,
            numeroIdentificacion: datosPago.numeroIdentificacionTitular,
            nombreORazonSocial: datosPago.razonSocialTitular,
          };
        }
        return titularidadFactura;
      };
  
      const metadata = () => {
        return {
          nombreORazonSocial: datosPago.nombreORazonSocial,
          tipoIdentificacion: datosPago.tipoIdentificacion,
          numeroIdentificacion: datosPago.numeroIdentificacion,
          correo: datosPago.correo,
          tipoPersona: datosPago.tipoPersona,
          banco: bancos.value.find((x) => x.code === datosPago.banco)?.name,
        };
      };
  
      const datosGeneralesFactura = () => {
        return {
          pais: codigoPais.value,
          departamento: codigoDpto.value,
          municipio: codigoMunicipio.value,
          celular: datosPago.celular,
          direccion: direccion.value,
          metadataDireccion: metadataDireccion.value,
          ProveedorPagoId: ProveedorPago.Kushki,
        };
      };
  
      const amount = () => {
        return {
          subtotalIva: 0,
          subtotalIva0: props.valuePlan,
          iva: 0,
        };
      };
  
      const informacionAportante = () => {
        return {
          razonSocial: localStorage.getItem("RazonSocialPila"),
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          dv: localStorage.getItem("DV"),
        };
      };
  
      const informacionTransaccion = (tokenDePago: string) => {
        return {
          tokenHeader: localStorage.getItem("Tokensin"),
          token: tokenDePago,
          amount: JSON.stringify(amount()),
          metadata: JSON.stringify(metadata()),
          datosGenerales: JSON.stringify(datosGeneralesFactura()),
          titularidadFactura: JSON.stringify(titularidadFactura()),
          informacionAportante: JSON.stringify(informacionAportante()),
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          CantidadDocumentos: props.documentsCount,
        };
      };
  
      function iniciarTransaccion(tokenDePago: string) {
        store
          .dispatch("GetUrlPayment", informacionTransaccion(tokenDePago))
          .then((response: any) => {
            let test = response.data;
            if (test != null) {
              window.location.href = test.redirectUrl;
            } else {
              errorPayment.value = true;
            }
          })
          .catch((err) => {
            processPayment.value = false;
            errorPayment.value = true;
            console.log(err);
          });
      }
  
      const scopeDivipolas = "Divipolas";
      const validacionDivipolas = useVuelidate({}, {}, {
          $scope: scopeDivipolas,
        }
      );
  
      const scopeDireccion = "Direccion";
      const validacionDireccion = useVuelidate({}, {}, {
          $scope: scopeDireccion,
        }
      );
  
      function GetKeyVaultSecrets() {
        let parametrosConsulta = {
          token: localStorage.getItem("Tokensin"),
          secret1: "FranjaPagoNoDisponible",
          secret2: "MensajePagoNoDisponible",
        };
        store
          .dispatch("GetKeyVaultSecrets", parametrosConsulta)
          .then((response) => {
            if (response.request.status == 200) {
              franjaPagoNoDisponible.value = JSON.parse(response.data[0].value);
              mensajePagoNoDisponible.value = response.data[1].value;
            }
          })
          .catch((error) => {
            console.log(error.request.response);
          });
      }
  
      const obtenerUbicaciones = () => {
        codigoPais.value = divipolaRef.value.codigoPais;
        codigoDpto.value = divipolaRef.value.codigoDpto;
        codigoMunicipio.value = divipolaRef.value.codigoMunicipio;
        direccion.value = direccionRef.value.ObtenerDireccion();
        metadataDireccion.value = direccionRef.value.direccion;
      };
  
      const precargarBanco = () => {
        let banco = bancos.value.find((b) => b.name === datosPago.banco);
        if (banco) {
          datosPago.banco = banco.code;
        }
      };
  
      const informacionPago = (): ColombianTokenRequest => {
        let paymentInfo: ColombianTokenRequest = {
          bankId: datosPago.banco, // Requerido en Colombia. Opcional para Chile
          callbackUrl: window.location.origin + "/KushkiBankResponse", // No requerido para MX
          userType: datosPago.tipoPersona as UserType, //0 represents a natural person and 1 a company.
          documentType: datosPago.tipoIdentificacion as
            | "CC"
            | "NIT"
            | "CE"
            | "TI"
            | "PP",
          documentNumber: datosPago.numeroIdentificacion,
          paymentDescription: `Compra del plan de ${props.documentsCount} documentos`,
          email: datosPago.correo,
          currency: "COP",
          amount: {
            subtotalIva: 0, // Configúralo en 0 en caso de que la Tx no tenga impuestos
            subtotalIva0: props.valuePlan
              ? parseInt(props.valuePlan.toString())
              : -1, // Configura el monto total de la Tx en caso de que no tenga impuestos. De lo contrario, envíalo en 0.
            iva: 0, // Envíalo en 0 en caso de que la Tx no tenga impuestos.
          },
        };
        return paymentInfo;
      };
  
      function getPaymentToken() {
        if (!pagoRestringido.value) {
          obtenerUbicaciones();
          precargarBanco();
          validacionDivipolas.value.$validate();
          validacionDireccion.value.$validate();
          formRef.value?.validate((valid: boolean) => {
            if (
               valid &&
              !validacionDivipolas.value.$error &&
              !validacionDireccion.value.$error
            ) {
              processPayment.value = true;
              errorPayment.value = false;
  
              let callback = function (response: any) {
                if (!response.code) {
                  iniciarTransaccion(response.token);
                } else {
                  processPayment.value = false;
                  errorPayment.value = true;
                  console.error(
                    "Error: ",
                    response.error,
                    "Code: ",
                    response.code,
                    "Message: ",
                    response.message
                  );
                }
              };
              kushki.requestTransferToken(informacionPago(), callback); // También puedes configurar la función directamente
            }
          });
        }
      }

      function validarOrigenInformacionParaFormulario(){
        if(String(props.featureFlag) === 'true')
        {
          ObtenerInformacionParcialPasarela();
        }
        else
        {
          ObtenerTransaccionMasReciente();
        }
      }
  
      function ObtenerInformacionParcialPasarela() {
               Object.assign(datosPago, store.state.datosPagoTemp);
               datosPago.banco = "";
                if(datosPago.tipoIdentificacion == "PA")
                {
                 datosPago.tipoIdentificacion = "PP"
                }
                if(datosPago.tipoIdentificacionTitular == "PA")
                {
                 datosPago.tipoIdentificacionTitular = "PP"
                }
               expedirFactura.value = !!(
                 datosPago.razonSocialTitular &&
                 datosPago.tipoIdentificacionTitular &&
                 datosPago.numeroIdentificacionTitular
               );
               validarTitularidad();
               objetoDireccion.value = JSON.parse(datosPago.metadataDireccion);
               objetoDivipola.value = {
                 Pais: datosPago.pais,
                 Departamento: datosPago.departamento,
                 Municipio: datosPago.municipio,
               };     
      }

      function ObtenerTransaccionMasReciente() {
      let parametrosConsulta = {
        token: localStorage.getItem("Tokensin"),
        proveedorPago: ProveedorPago.Kushki
      };
      store
        .dispatch("ObtenerTransaccionMasReciente", parametrosConsulta)
        .then((response) => {
          if (response.request.status == 200) {
            Object.assign(datosPago, response.data);
            expedirFactura.value = !!(
              datosPago.razonSocialTitular &&
              datosPago.tipoIdentificacionTitular &&
              datosPago.numeroIdentificacionTitular &&
              datosPago.tipoRegistro == 'Usuario'
            );
            validarTitularidad();
            objetoDireccion.value = JSON.parse(datosPago.metadataDireccion);
            objetoDivipola.value = {
              Pais: datosPago.pais,
              Departamento: datosPago.departamento,
              Municipio: datosPago.municipio,
            };
          }
        })
        .catch((error) => {
          console.log(error.request.response);
        });
    }
  
      function comebackToPlans() {
        router.push({ name: "Plans" });
      }
  
      onMounted(() => {
        getBanks();
        GetKeyVaultSecrets();
        validarOrigenInformacionParaFormulario();
      });
      return {
        bancos,
        getPaymentToken,
        comebackToPlans,
        datosPago,
        divipolaRef,
        direccionRef,
        objetoDireccion,
        objetoDivipola,
        processPayment,
        errorPayment,
        rules,
        formRef,
        scopeDivipolas,
        scopeDireccion,
        colombianCurrencyValidator,
        normalizeNumberValidator,
        expedirFactura,
        franjaPagoNoDisponible,
        pagoRestringido,
        validarTitularidad,
        mensajePagoNoDisponible,
      };
    },
    computed: {
      DocumentTypes: () => DocumentTypes,
      PersonType: () => PersonType,
    },
  });
  