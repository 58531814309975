<template>
    <div class="plan-selected-container">
      <div v-if="respuestaBanco" class="data-plans">
        <div class="header-respuesta">
          <font-awesome-icon
            :icon="['fas', responseCodes[respuestaBanco.processorState].icon]"
            size="3x"
          />
          <div>
            <h2 class="transact-title">
              {{ responseCodes[respuestaBanco.processorState].title }}
            </h2>
            <label>{{
              responseCodes[respuestaBanco.processorState].message
            }}</label>
          </div>
        </div>
        <h3>Datos de la transacción</h3>
        <div class="bank-response">
          <div class="bank-response-row">
            <span>Tipo de identificación</span>
            <span>{{ respuestaBanco.documentType }}</span>
          </div>
          <div class="bank-response-row">
            <span>Número de identificación</span>
            <span>{{ respuestaBanco.documentNumber }}</span>
          </div>
          <div class="bank-response-row">
            <span>Número de referencia</span>
            <span>{{ respuestaBanco.ticketNumber }}</span>
          </div>
          <div class="bank-response-row">
            <span>Banco</span>
            <span>{{
              bancos.find((x) => x.code == respuestaBanco.bankId).name
            }}</span>
          </div>
          <div v-if="respuestaBanco.completedAt" class="bank-response-row">
            <span>Fecha y hora</span>
            <span>{{
              new Date(respuestaBanco.completedAt).toLocaleString()
            }}</span>
          </div>
          <div class="bank-response-row">
            <span>Monto pagado</span>
            <span
              ><strong>{{
                colombianCurrencyValidator(respuestaBanco.amount?.subtotalIva0)
              }}</strong></span
            >
          </div>
          <div class="bank-response-row">
            <span>CUS</span>
            <span>{{ respuestaBanco.trazabilityCode }}</span>
          </div>
          <div class="bank-response-row">
            <span>Descripción</span>
            <span>{{ respuestaBanco.paymentDescription }}</span>
          </div>
          <div class="footer-form">
            <button class="back-form" type="button" v-on:click="print">
              Imprimir
            </button>
            <button
              type="button"
              v-on:click="redirectDuePaymentState(respuestaBanco.processorState)"
            >
              Finalizar
            </button>
          </div>
        </div>
        <MessageToClient />
      </div>
      <Spinner v-if="!respuestaBanco" type="modern"></Spinner>
    </div>
  </template>
  
  <script>
  import { onMounted } from "@vue/runtime-core";
  import { useRoute } from "vue-router";
  import { ref, reactive } from "vue";
  import { useStore } from "vuex";
  import { colombianCurrencyValidator } from "@/store/Payment/plans";
  import Spinner from "@/components/layout/Spinner.vue";
  import router from "@/router";
  import MessageToClient from "@/components/Payment/MessageToClient.vue";
  import ProveedorPago from "../models/payment/ProveedorPago";
  
  const responseCodes = {
    FAILED: {
      title: "Transacción fallida",
      message: "Al finalizar podrás reintentar tu pago",
      icon: "times-circle",
      redirect: "Plans",
    },
    PENDING: {
      title: "Transacción pendiente",
      message: "Tu pago está en proceso de validación",
      icon: "redo-alt",
      redirect: "WaitingPayment",
    },
    NOT_AUTHORIZED: {
      title: "Transacción rechazada",
      message: "Al finalizar podrás reintentar tu pago",
      icon: "times-circle",
      redirect: "Plans",
    },
    OK: {
      title: "Transacción aprobada",
      message: "* Sujeto a verificación",
      icon: "check-circle",
      redirect: "Transmission",
    },
  };
  
  export default {
    name: "KushkiBankResponse",
    props: {},
    components: {
      Spinner,
      MessageToClient,
    },
    setup() {
      let respuestaBanco = ref();
      let paramsUrl = ref(null);
      const bancos = JSON.parse(localStorage.getItem("bancos"));
      function getPaymentInfoByToken() {
        const route = useRoute();
        const store = useStore();
  
        let data = {
          tokenAEL: localStorage.getItem("Tokensin"),
          token: route.query.token,
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          proveedorPago: ProveedorPago.Kushki
        };
  
        store
          .dispatch("GetPaymentInfo", data)
          .then((res) => {
            respuestaBanco.value = res.data;
            paramsUrl.value = new URLSearchParams(respuestaBanco.value.bankurl);
          })
          .catch((err) => console.error(err));
      }
      function print() {
        window.print();
      }
      function redirectDuePaymentState(state) {
        router.push({ name: responseCodes[state].redirect });
      }
      onMounted(() => {
        getPaymentInfoByToken();
      });
      return {
        respuestaBanco,
        paramsUrl,
        print,
        colombianCurrencyValidator,
        redirectDuePaymentState,
        bancos,
      };
    },
    computed: {
      responseCodes: () => responseCodes,
    },
  };
  </script>  