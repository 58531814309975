import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "pt-tabpanel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HorasExtras = _resolveComponent("HorasExtras")!
  const _component_TabPanel = _resolveComponent("TabPanel")!
  const _component_Incapacidades = _resolveComponent("Incapacidades")!
  const _component_Licencias = _resolveComponent("Licencias")!
  const _component_Vacaciones = _resolveComponent("Vacaciones")!
  const _component_HuelgasLegales = _resolveComponent("HuelgasLegales")!
  const _component_OtrosConceptos = _resolveComponent("OtrosConceptos")!
  const _component_Libranzas = _resolveComponent("Libranzas")!
  const _component_TabView = _resolveComponent("TabView")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_TabView, {
      ref: "tabView",
      activeIndex: $setup.activeTab,
      class: "payroll-tabview",
      scrollable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_TabPanel, { header: "Horas extras" }, {
          default: _withCtx(() => [
            _createVNode(_component_HorasExtras, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Incapacidades" }, {
          default: _withCtx(() => [
            _createVNode(_component_Incapacidades, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Licencias" }, {
          default: _withCtx(() => [
            _createVNode(_component_Licencias, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Vacaciones" }, {
          default: _withCtx(() => [
            _createVNode(_component_Vacaciones, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Huelgas legales" }, {
          default: _withCtx(() => [
            _createVNode(_component_HuelgasLegales, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Otros conceptos" }, {
          default: _withCtx(() => [
            _createVNode(_component_OtrosConceptos, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        }),
        _createVNode(_component_TabPanel, { header: "Libranzas" }, {
          default: _withCtx(() => [
            _createVNode(_component_Libranzas, { nomina: $setup.scopeNomina }, null, 8, ["nomina"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["activeIndex"])
  ]))
}