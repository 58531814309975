
import { defineComponent, ref, onUpdated } from "vue";
import CurrencyInput from "./CurrencyInput.vue";
import useVuelidate from '@vuelidate/core';
import FinancieraValidacion from '@/validation/InfoFinanciera';

export default defineComponent({
  components: { CurrencyInput },
  props: {
        scopeFinanciera: String,
        FinancieraRef: {}
    },
  setup(props) {

      let [objetoFinanciera] = new FinancieraValidacion().ObtenerReglasValidacion();
        
      // const validacionFinanciera = useVuelidate(reglasValidacion, objetoFinanciera, { $scope: props.scopeFinanciera })

      const reiniciarObjetoFinanciera = () => {
          objetoFinanciera.VentasIngresosOperacionales = '',
          objetoFinanciera.TotalActivos = '',
          objetoFinanciera.Egresos = '',
          objetoFinanciera.IngresosNoOperacionales = '',
          objetoFinanciera.OtrosActivos = '',
          objetoFinanciera.TotalPasivos = '',
          objetoFinanciera.TotalIngresos = '',
          objetoFinanciera.DescripcionOtrosActivos = '',
          objetoFinanciera.ActivosTotales2 = '',
          objetoFinanciera.GastosTotales = '',
          objetoFinanciera.GastosFinancieros = '',
          objetoFinanciera.DescripcionOtrosIngresos = '',
          objetoFinanciera.DescripcionIngresosNoOperacionales = ''
        }

        onUpdated(() => {
            window.scrollTo(0, 0);
})

    return { objetoFinanciera, reiniciarObjetoFinanciera };
  }
});
