export const PersonType = [
  {
    id: "0",
    value: "Persona Natural",
  },
  {
    id: "1",
    value: "Persona Jurídica",
  },
];
