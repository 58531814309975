import { helpers, maxValue } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class FondoSPValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const fondoSP = reactive(
      store.state.DetalleNomina.JsonNomina.GeneralDeductions
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido."

    const reglasFSP = computed(() => {
      return {
        SolidarityPensionPercentage: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE167`,
            maxValue(maxInt)
          ),
        },
        SolidarityPensionDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE168`,
            maxValue(maxInt)
          ),
        },
        SubsistencePercentage: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE169`,
            maxValue(maxInt)
          ),
        },
        SubsistenceDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE170`,
            maxValue(maxInt)
          ),
        },
      };
    });

    return [fondoSP, reglasFSP] as const;
  }
}

export default FondoSPValidacion;