import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ResumenEmpleado = _resolveComponent("ResumenEmpleado")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.empleado && $setup.detalleNomina)
      ? (_openBlock(), _createBlock(_component_ResumenEmpleado, {
          key: 0,
          empleado: $setup.empleado[0],
          detalle: $setup.detalleNomina,
          fechaIngreso: $setup.fechaIngreso,
          fechaAprobacion: $setup.fechaAprobacion
        }, null, 8, ["empleado", "detalle", "fechaIngreso", "fechaAprobacion"]))
      : _createCommentVNode("", true),
    _createVNode(_component_DataTable, {
      responsiveLayout: "scroll",
      value: $setup.empleado
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Column, {
          field: "codigoTrabajador",
          header: "Código trabajador",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "tipoIdentificacion",
          header: "Tipo ID",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "numeroIdentificacion",
          header: "No ID",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "nombres",
          header: "Nombres",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "apellidos",
          header: "Apellidos",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "correo",
          header: "Correo",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "direccion",
          header: "Dirección",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "ciudad",
          header: "Ciudad",
          bodyClass: "datatable-text-center"
        }),
        _createVNode(_component_Column, {
          field: "tipoContrato",
          header: "Tipo de contrato",
          bodyClass: "datatable-text-center"
        })
      ]),
      _: 1
    }, 8, ["value"])
  ], 64))
}