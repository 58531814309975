
import { computed, reactive } from 'vue'
import { required, helpers, email, url, integer, requiredIf } from '@vuelidate/validators'
import func from '@/validation/GeneralRules'

class ContactoValidacion {

    public ObtenerReglasValidacion() {

        const objetoContacto = reactive({
            TipoPersona: '',
            TipoDocumento: '',
            Nombre: '',
            No_Identificacion: '',
            dv: '',
            ClaseSociedad: '',
            TipoEmpresa: '',
            OtroTipoEmpresa: '',
            Direccion: '',
            Telefono: '',
            Extension: '',
            Celular: '',
            Ciudad: '',
            Departamento: '',
            Pais: '',
            Correo: '',
            PaginaWeb: '',
            NombreContactoCliente: '',
            CargoContactoCliente: '',
            CorreoContactoCliente: '',
            TelefonoContactoCliente: '',
            ExtensionContactoCliente: '',
            CelularContactoCliente: ''
        })

        const ValidaFormatoDocumento = (tipodoc: string) => helpers.withParams(
            {
                type: 'TipoDocumento',
                value: tipodoc
            }, (numdoc: string) => func.ValidateRegex(numdoc, tipodoc)
        )

        const reglasValidacion = computed(() => {
            return {
                TipoPersona: {
                    required: helpers.withMessage('Seleccione tipo persona', required)
                },
                TipoDocumento: {
                    required: helpers.withMessage('Seleccione', required)
                },
                Nombre: {
                    required: helpers.withMessage('Ingrese razón social', required)
                },
                No_Identificacion: {
                    required: helpers.withMessage('Ingrese número ID', required),
                    ExpresionRegular_TipoDocumento: helpers.withMessage('Valide formato del documento', ValidaFormatoDocumento(objetoContacto.TipoDocumento))
                },
                ClaseSociedad: {
                    required: helpers.withMessage('Seleccione clase sociedad', required)
                },
                TipoEmpresa: {
                    required: helpers.withMessage('Seleccione tipo empresa', required)
                },
                OtroTipoEmpresa: {
                    required: helpers.withMessage('Ingrese tipo empresa', requiredIf(objetoContacto.TipoEmpresa === "Otra"))
                },
                Pais: {
                    required: helpers.withMessage('Seleccione país', required)
                },
                Departamento: {
                    required: helpers.withMessage('Seleccione departamento', requiredIf(objetoContacto.Pais === "Colombia"))
                },
                Ciudad: {
                    required: helpers.withMessage('Seleccione ciudad', requiredIf(objetoContacto.Pais === "Colombia"))
                },
                Correo: {
                    email: helpers.withMessage("Ingrese un email válido", email)
                },
                CorreoContactoCliente: {
                    email: helpers.withMessage("Ingrese un email válido", email)
                }
            }
        })

        return [objetoContacto, reglasValidacion] as const;
    }
}

export default ContactoValidacion;
