

import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import InfoPepAccionista_NaturalValidacion from '../../validation/InfoPepAccionista_Natural';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoPepAccionista_Natural: String,
        HabilitaCamposPepAN: Boolean,
        parametricas: {}
    },
    setup(props) {
        
        let [objetoInfoPepAccionista_Natural, reglasValidacion] = new InfoPepAccionista_NaturalValidacion().ObtenerReglasValidacion();

        const AgregarFilaPEPAN = () => {

            objetoInfoPepAccionista_Natural.PepAN.push({
                NombreAN_PEP: '',
                NombrePEP: '',
                TipoIdPEPAN: '',
                NumeroIdentificacionPEPAN: '',
                TipoVinculoPEPAN: '',
                TipoPEPAN: ''
            }); 
        }

        const EliminarFilaPEPAN = (index: number) => {
            objetoInfoPepAccionista_Natural.PepAN.splice(index, 1);
        }

        const reiniciarObjetoInfoPepAccionista_Natural = () => {
          objetoInfoPepAccionista_Natural.PepAN = [];
          AgregarFilaPEPAN();
        }
        const validacionInfoPepAccionista_Natural = useVuelidate(reglasValidacion, objetoInfoPepAccionista_Natural, { $scope: props.scopeInfoPepAccionista_Natural })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]


        return { 
          AgregarFilaPEPAN, 
          EliminarFilaPEPAN, 
          validacionInfoPepAccionista_Natural, objetoInfoPepAccionista_Natural, reiniciarObjetoInfoPepAccionista_Natural,
          tiposIdentificacion
        }
    },
})

