
import { useStore } from "vuex";
import { ElMessage } from 'element-plus'
import router from '@/router';
import { computed, ref, defineComponent,onBeforeMount } from "vue";
import useVuelidate from "@vuelidate/core";
import Seccion from "../models/DueDiligence/Seccion";
import InfoContacto from "@/components/DueDiligence/InfoContacto.vue";
import Pep from "@/components/DueDiligence/Pep.vue";
import InfoCliente from "@/components/DueDiligence/InfoCliente.vue";
import InfoFinanciera from "../components/DueDiligence/InfoFinanciera.vue";
import InfoAdicional from "../components/DueDiligence/InfoAdicional.vue";
import InfoRepresentanteTitular from "../components/DueDiligence/InfoRepresentanteTitular.vue";
import InfoRepresentanteSuplente from "../components/DueDiligence/InfoRepresentanteSuplente.vue";
import InfoRepresentantePep from "../components/DueDiligence/InfoPEPRepresentante.vue";
import InfoAccionistaNatural from "../components/DueDiligence/InfoAccionista_Natural.vue";
import InfoPepAccionistaNatural from "../components/DueDiligence/InfoPepAccionista_Natural.vue";
import InfoAccionistaJuridico from "../components/DueDiligence/InfoAccionista_Juridico.vue";
import InfoBeneficiarioAccionistaJuridico from "../components/DueDiligence/InfoBeneficiarioAccionista_Juridico.vue";
import InfoPepAccionistaJuridico from "../components/DueDiligence/InfoPepAccionista_Juridico.vue";
import Spinner from "@/components/layout/Spinner.vue";

export default defineComponent({
  components: {
    InfoContacto,
    InfoCliente,
    Pep,
    InfoFinanciera,
    InfoAdicional,
    InfoRepresentanteTitular,
    InfoRepresentanteSuplente,
    InfoRepresentantePep,
    InfoAccionistaNatural,
    InfoPepAccionistaNatural,
    InfoAccionistaJuridico,
    InfoBeneficiarioAccionistaJuridico,
    InfoPepAccionistaJuridico,
    Spinner
  },
  setup() {
    const store = useStore();
    const SECCION = ref(Seccion); 
    const finalizar = ref(false);
    const mensajeErrorDD = "Recuerde llenar todos los campos obligatorios (*)";
    const PasosNatural = [
          {
            value: "1",
            label: "Información general",
          },
          {
            value: "2",
            label: "Información PEP",
          },
          {
            value: "3",
            label: "Información cliente",
          },
          {
            value: "4",
            label: "Información financiera",
          },
          {
            value: "5",
            label: "Información adicional"
          }
        ];

        const PasosJuridico = [
          {
            value: "1",
            label: "Información general",
          },
          {
            value: "2",
            label: "Información cliente",
          },
          {
            value: "3",
            label: "Información legal",
          },
          {
            value: "4",
            label: "Información accionista",
          },
          {
            value: "5",
            label: "Información financiera"
          },
          {
            value: "6",
            label: "Información adicional"
          },

        ];

    const mostrarSpinner = ref(false);

    //CONTACTO:
    const contactoRef = ref();
    const scopeContacto = "contacto";
    const validacionContacto = useVuelidate(
      {},
      {},
      {
        $scope: scopeContacto,
      }
    );
    const validaTipoPersona = ref("Jurídica");

    //PEP:
    const pepRef = ref();
    const scopePep = "pep";
    const mostrarPEP = ref(false);
    const pepDialogVisible = ref(false);
    const validacionPep = useVuelidate(
      {},
      {},
      {
        $scope: scopePep,
      }
    );
    const RegistraPep = ref();

    //CLIENTE
    const clienteRef = ref();
    const mostrarCliente = ref(false);
    const scopeCliente = "cliente";
    const validacionCliente = useVuelidate(
      {},
      {},
      {
        $scope: scopeCliente,
      }
    );

    //INFO FINANCIERA
    const FinancieraRef = ref();
    const scopeFinanciera = "financiera";
    const validacionFinanciera = useVuelidate(
      {},
      {},
      {
        $scope: scopeFinanciera,
      }
    );


  //INFO ADICIONAL
    const AdicionalRef = ref();
    const scopeAdicional = 'adicional'
    const validacionAdicional = useVuelidate({},{},{
        $scope: scopeAdicional
    })

    //INFO REPRESENTANTE TITULAR (INFO LEGAL)
    const RepresentanteTitularRef = ref();
    const scopeRepresentanteTitular = "Representante Titular";
    const validacionInfoRepresentanteTitular = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentanteTitular,
      }
    );
    const EstadoPEPRT = ref(false);

    //INFO REPRESENTANTE SUPLENTE (INFO LEGAL)
    const RepresentanteSuplenteRef = ref();
    const scopeRepresentanteSuplente = "Representante Suplente";
    const EstadoPEPRS = ref(false);
    const validacionInfoRepresentanteSuplente = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentanteSuplente,
      }
    );

    //INFO REPRESENTANTE PEP (INFO LEGAL)
    const mostrarSeccionPep = ref(false);
    const RepresentantePepRef = ref();
    const scopeRepresentantePep = "Representante PEP";
    const validacionInfoRepresentantePep = useVuelidate(
      {},
      {},
      {
        $scope: scopeRepresentantePep,
      }
    );

    //POP UP VALIDAR ACCIONISTAS
    const AccionistasDialogVisible = ref(false);
    const radioAccionistas = ref(2);

    //MOSTRAR/OCULTAR ACCIONISTAS
    const mostrarAccionistasNaturales = ref(false);
    const mostrarAccionistasJuridicos = ref(false);

    //INFO ACCIONISTAS NATURALES (INFO ACCIONISTAS)
    const InfoAccionista_NaturalRef = ref();
    const scopeInfoAccionista_Natural = "Accionista Natural";
    const validacionInfoAccionista_Natural = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoAccionista_Natural,
      }
    );

    //INFO PEP ACCIONISTAS NATURALES (INFO ACCIONISTAS)
    const mostrarSeccionPepAN = ref(false);
    const InfoPepAccionista_NaturalRef = ref();
    const scopeInfoPepAccionista_Natural = "Pep Accionista Natural";
    const validacionInfoPepAccionista_Natural = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoPepAccionista_Natural,
      }
    );

    //INFO ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const InfoAccionista_JuridicoRef = ref();
    const scopeInfoAccionista_Juridico = "Accionista Juridico";
    const validacionInfoAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoAccionista_Juridico,
      }
    );

    //INFO BENEFICIARIOS ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const InfoBeneficiarioAccionista_JuridicoRef = ref();
    const scopeInfoBeneficiarioAccionista_Juridico = "Beneficiario Accionista Juridico";
    const validacionInfoBeneficiarioAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoBeneficiarioAccionista_Juridico,
      }
    );

    //INFO PEP ACCIONISTAS JURIDICOS (INFO ACCIONISTAS)
    const mostrarSeccionPepAJ = ref(false);
    const InfoPepAccionista_JuridicoRef = ref();
    const scopeInfoPepAccionista_Juridico = "Pep Accionista Juridico";
    const validacionInfoPepAccionista_Juridico = useVuelidate(
      {},
      {},
      {
        $scope: scopeInfoPepAccionista_Juridico,
      }
    );

    ///AVANCE Y RETROCESO DEL STEPPER:
    const stepperProgress = computed(() => {
      return (100 / 3) * (store.state.ddModule.step.value - 1) + "%";
    });

    const parametricas = ref({});
    function GetParametricas() {
      let params = {
        Token: localStorage.getItem("Tokensin")
      };
    store.dispatch("GetParametricasDebidaDiligencia", params).then(resp=> {
        parametricas.value = store.state.ddModule.parametricas;
        }).catch((err) => console.error(err));
    }

    window.onbeforeunload = function(e) {
        e.preventDefault();
        return e.returnValue = '';
    };

    onBeforeMount(() => { 
            GetParametricas();   
      });
    
    return {
      store, stepperProgress, SECCION, finalizar, mostrarSpinner,mensajeErrorDD, PasosNatural, PasosJuridico,
      scopeContacto, contactoRef, validacionContacto, validaTipoPersona,
      scopePep, pepRef, validacionPep,
      pepDialogVisible, mostrarPEP, RegistraPep,
      scopeCliente, clienteRef, validacionCliente, mostrarCliente,
      scopeFinanciera,FinancieraRef,validacionFinanciera,
      scopeAdicional,AdicionalRef,validacionAdicional,
      scopeRepresentanteTitular,RepresentanteTitularRef,validacionInfoRepresentanteTitular,EstadoPEPRT,
      scopeRepresentanteSuplente,RepresentanteSuplenteRef,EstadoPEPRS, validacionInfoRepresentanteSuplente,
      scopeRepresentantePep,RepresentantePepRef,validacionInfoRepresentantePep,mostrarSeccionPep,
      AccionistasDialogVisible,radioAccionistas,
      mostrarAccionistasNaturales,mostrarAccionistasJuridicos,
      scopeInfoAccionista_Natural,InfoAccionista_NaturalRef,validacionInfoAccionista_Natural,
      scopeInfoPepAccionista_Natural,InfoPepAccionista_NaturalRef,validacionInfoPepAccionista_Natural,mostrarSeccionPepAN,
      scopeInfoAccionista_Juridico, InfoAccionista_JuridicoRef, validacionInfoAccionista_Juridico,
      scopeInfoBeneficiarioAccionista_Juridico, InfoBeneficiarioAccionista_JuridicoRef,validacionInfoBeneficiarioAccionista_Juridico,
      scopeInfoPepAccionista_Juridico, InfoPepAccionista_JuridicoRef, validacionInfoPepAccionista_Juridico, mostrarSeccionPepAJ,onBeforeMount, parametricas
    };
  },
  methods: {
    ModificarPasosIfNatural() {
      this.SECCION = {
        CONTACTO: 1,
        PEP: 2,
        CLIENTE: 3,
        FINANCIERA: 4,
        ADICIONAL: 5,
        LEGAL:0,
        ACCIONISTA:0
      };
    },

    ModificarPasosIfJuridico() {
      this.SECCION = {
        CONTACTO: 1,
        CLIENTE: 2,
        LEGAL: 3,
        ACCIONISTA: 4,
        FINANCIERA: 5,
        ADICIONAL: 6,
        PEP: 0       
      };
    },

    AvanzarFormulario() {
        //Validación de pasos para stepper y variable global según tipo persona
      this.validaTipoPersona = this.contactoRef.objetoContacto.TipoPersona;

        //Steps Persona Natural/Extranjera

        //Step 1 - Persona Natural/Extranjera (CONTACTO)
      if (this.store.state.ddModule.step === this.SECCION.CONTACTO && this.contactoRef.objetoContacto.TipoPersona !== "Jurídica") {
        this.FlujoInfoContactoPNatural();
        return;
      }

      //Step 2 - Persona Natural/Extranjera (PEP)
      if (this.store.state.ddModule.step === this.SECCION.PEP) {
        this.FlujoInfoPEPPNatural();
        return;
      }

      //Step 3 Persona Natural/Extranjera (CLIENTE)
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && (this.contactoRef.objetoContacto.TipoPersona === "Natural" || this.contactoRef.objetoContacto.TipoPersona === "Extranjera")) {
      this.FlujoInfoClientePNatural();
      return;
      }

      //Step Info Financiera
      if (this.store.state.ddModule.step === this.SECCION.FINANCIERA) {
        // this.validacionFinanciera.$validate();
        // if (!this.validacionFinanciera.$error) {
          this.AdicionalRef.objetoArchivos.EstadosFinancieros.InformacionFinanciera =  this.FinancieraRef.objetoFinanciera;
          this.store.dispatch("CargarInformacionFinanciera",this.FinancieraRef.objetoFinanciera);     
          this.store.dispatch("Avanzar");
        // }
        // else{
        //     ElMessage.error(this.mensajeErrorDD);
        // }
        return;
      }

        //step final (Info Adicional)
      if (this.store.state.ddModule.step === this.SECCION.ADICIONAL) {
          this.validacionAdicional.$validate();
          if (!this.validacionAdicional.$error) {

            this.finalizar = true;
            this.mostrarSpinner = true;

            this.store.dispatch("CargarInformacionCompromisos", {
               BuenasPracticas: this.AdicionalRef.objetoArchivos.BuenasPracticas,
               ConflictoInteres: this.AdicionalRef.objetoArchivos.ConflictoInteres
            });

            this.AdicionalRef.objetoArchivos.Archivos
              .append('EntradaDebidaDiligencia', 
                JSON.stringify(this.store.state.ddModule.debidaDiligencia))

            let params = {
              Token: localStorage.getItem("Tokensin"),
              Archivos: this.AdicionalRef.objetoArchivos.Archivos
            };
            this.store.dispatch('RegistrarDebidaDiligencia', params)
            .then((data) => {
                this.mostrarSpinner = false;
                if(data.status == 204)
                {
                  router.push({ name: "Dashboard" });
                }               
            })
            .catch((error) => {
              this.mostrarSpinner = false;
              console.log(error);
            });
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
        }
          return;
        }
      
        //Steps Persona Jurídica

        //Step 1 Persona Jurídica (INFO CONTACTO)
      if (this.store.state.ddModule.step === this.SECCION.CONTACTO && this.contactoRef.objetoContacto.TipoPersona === "Jurídica") {
        this.FlujoInfoContactoPJuridica();
        return;
      }

      //Step 2 Persona Jurídica (INFO CLIENTE)
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && this.contactoRef.objetoContacto.TipoPersona === "Jurídica") {
        this.FlujoInfoClientePJuridica();
        return;
      }

      //Step 3 Persona Jurídica (INFO LEGAL)
      if (this.store.state.ddModule.step === this.SECCION.LEGAL) {
        this.FlujoInfoLegalPJuridica();
        return;
      }

      //Step 4 Persona Jurídica (ACCIONISTA NATURAL)

      if (this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasNaturales) {
        this.validacionInfoAccionista_Natural.$validate();
        if (this.mostrarSeccionPepAN) {
          this.FlujoAccNaturalIfPEP();
        } 
        else {
          this.FlujoAccNaturalIfNoPEP();
        }
        return;
      }

      //Step 4 Persona Jurídica (ACCIONISTA JURÍDICO)..

      if (this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasJuridicos) {     
          this.validacionInfoAccionista_Juridico.$validate();
          this.validacionInfoBeneficiarioAccionista_Juridico.$validate();
          if(this.mostrarSeccionPepAJ)
          {
              this.FlujoAccJuridicoIfPEP();
          }
          else
          {
              this.FlujoAccJuridicoIfNoPEP();
          }
          return;
      }  
    },
    AvanzarCliente() {
      this.mostrarPEP = false;
      this.mostrarCliente = true;
      this.pepDialogVisible = false;
      this.store.dispatch("Avanzar");
    },
    AvanzarClienteIfNoPep() {
      this.mostrarPEP = false;
      this.mostrarCliente = true;
      this.pepDialogVisible = false;
      this.store.dispatch("AvanzarDosPasos");
      this.store.dispatch("EliminarInformacionPEP");
      this.pepRef.reiniciarObjetoPEP();
      this.RegistraPep = false;
    },
    AvanzarPEP() {
      this.mostrarPEP = true;
      this.mostrarCliente = false;
      this.pepDialogVisible = false;
      this.store.dispatch("Avanzar");
    },
    RetrocederFormulario() {
        //Steps Retroceder Persona Natural

        //Retroceder a CONTACTO cuando se está en PEP
      if (this.store.state.ddModule.step === this.SECCION.PEP) {
        this.store.dispatch("Retroceder");
        return;
      }

        //Retroceder a CONTACTO o a PEP cuando está en CLIENTE
      if (this.store.state.ddModule.step === this.SECCION.CLIENTE && this.validaTipoPersona !== "Jurídica") {
        if (this.RegistraPep) {
          this.store.dispatch("Retroceder");
          this.mostrarPEP = true;
          this.mostrarCliente = false;
          this.pepDialogVisible = false;
        } else {
          this.store.dispatch("RetrocederDosPasos");
        }
        return;
      }

      //Retroceder a CLIENTE cuando se está en FINANCIERA
      if (this.store.state.ddModule.step === this.SECCION.FINANCIERA && this.validaTipoPersona !== "Jurídica") {
          this.store.dispatch("Retroceder");
          return;
      }

      //Retroceder a FINANCIERA o a CLIENTE cuando se está en ADICIONAL
      if (this.store.state.ddModule.step === this.SECCION.ADICIONAL && this.validaTipoPersona !== "Jurídica") {
        if (
          this.RegistraPep ||
          this.clienteRef.objetoCliente.ActivosVirtuales === "Si"
        ) {
          this.store.dispatch("Retroceder");
        } else {
          this.store.dispatch("RetrocederDosPasos");
        }
        return;
      }

      //Steps Retroceder Persona Jurídica
      
        //Retroceder a CONTACTO cuando se está en CLIENTE
      if(this.store.state.ddModule.step === this.SECCION.CLIENTE && this.validaTipoPersona === "Jurídica")
      {
          this.store.dispatch("Retroceder");
          this.mostrarCliente = false;
          return;
      }

      //Retroceder a CLIENTE cuando se está en LEGAL
      if(this.store.state.ddModule.step === this.SECCION.LEGAL)
      {
          this.store.dispatch("Retroceder");
          this.mostrarCliente = true;
          return;
      }

      //Retroceder a LEGAL cuando se está en ACCIONISTA NATURAL
      if(this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasNaturales)
      {
          this.store.dispatch("Retroceder");
          return;
      }

      //Retroceder a ACCIONISTA NATURAL o a LEGAL cuando se está en ACCIONISTA JURÍDICO
      if(this.store.state.ddModule.step === this.SECCION.ACCIONISTA && this.mostrarAccionistasJuridicos)
      {
          if(this.radioAccionistas === 3)
          {
              this.store.dispatch("Retroceder");
          }
          else
          {
              this.mostrarAccionistasNaturales = true;
              this.mostrarAccionistasJuridicos = false;
          }
          return;
      }

       //Retroceder a ACCIONISTA NATURAL o ACCIONISTA JURÍDICO o LEGAL cuando se está en FINANCIERA
      if(this.store.state.ddModule.step === this.SECCION.FINANCIERA && this.validaTipoPersona === "Jurídica"){
          if(this.radioAccionistas === 1)
          {
              this.store.dispatch("RetrocederDosPasos");
          }else{
              if(this.radioAccionistas === 2)
              {
                  this.store.dispatch("Retroceder");
                  this.mostrarAccionistasNaturales = true;
                  this.mostrarAccionistasJuridicos = false;
              }else{        
                    this.store.dispatch("Retroceder");
                    this.mostrarAccionistasNaturales = false;
                    this.mostrarAccionistasJuridicos = true;            
              }
          }
          return;
      }
          
        //Retroceder a FINANCIERA o ACCIONISTA JURIDICO o ACCIONISTA NATURAL o LEGAL cuando se está en ADICIONAL
      if(this.store.state.ddModule.step === this.SECCION.ADICIONAL && this.validaTipoPersona === "Jurídica")
      {
          if(this.mostrarSeccionPep || this.mostrarSeccionPepAN || this.mostrarSeccionPepAJ || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
          {
              this.store.dispatch("Retroceder");
          }
          else{
              if(this.radioAccionistas === 1)
              {
                  this.store.dispatch("RetrocederTresPasos")
              }
              else
              if(this.radioAccionistas === 2)
              {
                  this.store.dispatch("RetrocederDosPasos");
                  this.mostrarAccionistasNaturales = true;
                  this.mostrarAccionistasJuridicos = false
              }
              else
              {
                  this.store.dispatch("RetrocederDosPasos");
                  this.mostrarAccionistasNaturales = false;
                  this.mostrarAccionistasJuridicos = true;
              }
              
          }
          return;
      }      
    },
    FlujoInfoContactoPNatural(){
        this.validacionContacto.$validate();
        if (!this.validacionContacto.$error) {
          this.store.dispatch("CargarInformacionContacto",this.contactoRef.objetoContacto);
          this.ModificarPasosIfNatural();
          this.pepDialogVisible = true;
        }
        else{
          ElMessage.error(this.mensajeErrorDD);    
        }
    },
    FlujoInfoContactoPJuridica(){
        this.validacionContacto.$validate();
        if (!this.validacionContacto.$error) {
          this.store.dispatch("CargarInformacionContacto", this.contactoRef.objetoContacto);
          this.ModificarPasosIfJuridico();
          this.mostrarCliente = true;
          this.store.dispatch("Avanzar");
        }
        else{
            ElMessage.error(this.mensajeErrorDD)    
        }
    },
    FlujoInfoPEPPNatural(){
        if (this.mostrarPEP) {
          this.validacionPep.$validate();
          if (!this.validacionPep.$error) {
            this.store.dispatch("CargarInformacionPep", this.pepRef.objetoPep);
            this.RegistraPep = true;
            this.AvanzarCliente();
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
          }
        } else {
          this.AvanzarCliente();
        }
    },
    FlujoInfoClientePNatural(){
        this.validacionCliente.$validate();
        if (!this.validacionCliente.$error) {
          if (this.RegistraPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si") {
            this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
            this.store.dispatch("Avanzar");
          } else {
            this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
            this.AvanzarCompromisosIfNoFinanciera();
          }      
        }
        else{
            ElMessage.error(this.mensajeErrorDD)
        }
    },
    FlujoInfoClientePJuridica(){
        this.validacionCliente.$validate();
        if (!this.validacionCliente.$error) {
          this.store.dispatch("CargarInformacionCliente", this.clienteRef.objetoCliente);
          this.store.dispatch("Avanzar");
        }
        else{
           ElMessage.error(this.mensajeErrorDD);
        }
    },
    FlujoInfoLegalPJuridica(){
      this.validacionInfoRepresentanteTitular.$validate();
        this.validacionInfoRepresentanteSuplente.$validate();
        if (this.mostrarSeccionPep) {
          this.validacionInfoRepresentantePep.$validate();
          if (!this.validacionInfoRepresentanteTitular.$error && !this.validacionInfoRepresentanteSuplente.$error && !this.validacionInfoRepresentantePep.$error) {
            this.CargarInfoLegal();
            this.AccionistasDialogVisible = true;
          }
          else
          {
            ElMessage.error(this.mensajeErrorDD);
          }
        } else {
          if (!this.validacionInfoRepresentanteTitular.$error && !this.validacionInfoRepresentanteSuplente.$error) {
            this.CargarInfoLegal();
            this.AccionistasDialogVisible = true;
          }
          else
          {
              ElMessage.error(this.mensajeErrorDD);
          }
        }
    },
    FlujoAccNaturalIfPEP(){
        this.validacionInfoPepAccionista_Natural.$validate();
          if (!this.validacionInfoAccionista_Natural.$error && !this.validacionInfoPepAccionista_Natural.$error) {
            this.CargarInfoAccionistaNatural();
            if (this.radioAccionistas === 2) {
              this.store.dispatch("Avanzar");
            } 
            else {
              this.mostrarAccionistasNaturales = false;
              this.mostrarAccionistasJuridicos = true;
            }
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccNaturalIfNoPEP(){
        if (!this.validacionInfoAccionista_Natural.$error) {
            this.CargarInfoAccionistaNatural();
            if (this.radioAccionistas === 2) {
                if(this.mostrarSeccionPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
                {
                    this.store.dispatch("Avanzar");
                }
                else
                {
                    this.store.dispatch("AvanzarDosPasos");
                    this.LimpiaInfoFinanciera();
                }         
            } 
            else {
              this.mostrarAccionistasNaturales = false;
              this.mostrarAccionistasJuridicos = true;
            }
          }
          else{
            ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccJuridicoIfPEP(){
        this.validacionInfoPepAccionista_Juridico.$validate();
            if(!this.validacionInfoAccionista_Juridico.$error && !this.validacionInfoBeneficiarioAccionista_Juridico.$error && !this.validacionInfoPepAccionista_Juridico.$error)
            {             
                this.CargarInfoAccionistaJuridico();            
                this.store.dispatch("Avanzar");                                               
            }
            else{
                ElMessage.error(this.mensajeErrorDD)
            }
    },
    FlujoAccJuridicoIfNoPEP(){
        if (!this.validacionInfoAccionista_Juridico.$error && !this.validacionInfoBeneficiarioAccionista_Juridico.$error) {
            this.CargarInfoAccionistaJuridico();
            if(this.mostrarSeccionPep || this.mostrarSeccionPepAN || this.clienteRef.objetoCliente.ActivosVirtuales === "Si")
                {
                    this.store.dispatch("Avanzar");
                }
                else
                {
                    this.store.dispatch("AvanzarDosPasos");
                    this.LimpiaInfoFinanciera();
                }
            }
            else{
                ElMessage.error(this.mensajeErrorDD)
            }
    },
    AvanzarCompromisosIfNoFinanciera() {
      this.store.dispatch("AvanzarDosPasos");
      this.LimpiaInfoFinanciera();     
    },
    CargarInfoLegal() {
      this.store.dispatch(
        "CargarInformacionRepresentanteTitular",
        this.RepresentanteTitularRef.objetoInfoRepresentanteTitular
      );
      this.store.dispatch(
        "CargarInformacionRepresentanteSuplente",
        this.RepresentanteSuplenteRef.objetoInfoRepresentanteSuplente
      );
      this.store.dispatch(
        "CargarInformacionRepresentantePep",
        this.RepresentantePepRef.objetoInfoRepresentantePep
      );
    },
    CargarInfoAccionistaNatural() {
      this.store.dispatch(
        "CargarInformacionAccionistaNatural",
        this.InfoAccionista_NaturalRef.objetoInfoAccionista_Natural
      );
      this.store.dispatch(
        "CargarInformacionPepAccionistaNatural",
        this.InfoPepAccionista_NaturalRef.objetoInfoPepAccionista_Natural
      );
    },
    CargarInfoAccionistaJuridico(){
        this.store.dispatch(
        "CargarInformacionAccionistaJuridico",
        this.InfoAccionista_JuridicoRef.objetoInfoAccionista_Juridico
      );
      this.store.dispatch(
        "CargarInformacionBeneficiarioAccionistaJuridico",
        this.InfoBeneficiarioAccionista_JuridicoRef.objetoInfoBeneficiarioAccionista_Juridico
      );
      this.store.dispatch(
        "CargarInformacionPepAccionistaJuridico",
        this.InfoPepAccionista_JuridicoRef.objetoInfoPepAccionista_Juridico
      );
    },
    MostrarSeccionPepRT(Result: boolean) {
      this.EstadoPEPRT = Result;
      this.ComparaEstadoPep();
    },
    MostrarSeccionPepRS(Result: boolean) {
      this.EstadoPEPRS = Result;
      this.ComparaEstadoPep();
    },
    ComparaEstadoPep() {
      if (this.EstadoPEPRT || this.EstadoPEPRS) {
        this.mostrarSeccionPep = true;
      } else {
        this.mostrarSeccionPep = false;
        this.RepresentantePepRef.reiniciarObjetoInfoPepRepresentante()
      }
    },
    AvanzarAccionistas(seleccionAccionistas: number) {
      if (seleccionAccionistas === 1) {
        if (
          this.mostrarSeccionPep || this.clienteRef.objetoCliente.ActivosVirtuales === "Si"
        ) {
          this.AccionistasDialogVisible = false;
          this.store.dispatch("AvanzarDosPasos");
        } else {
          this.AccionistasDialogVisible = false;
          this.LimpiaInfoFinanciera();
          this.store.dispatch("AvanzarTresPasos");
        }
        this.LimpiarInfoAccionistas();
      } else {
        if (seleccionAccionistas === 2 || seleccionAccionistas === 4) {
          this.AccionistasDialogVisible = false;
          this.mostrarAccionistasNaturales = true;
          this.mostrarAccionistasJuridicos = false;
          this.store.dispatch("Avanzar");
          if(seleccionAccionistas === 2)
          {
              this.LimpiarInfoAccionistasJuridicos();
          }
        } else {
          if (seleccionAccionistas === 3) {
            this.AccionistasDialogVisible = false;
            this.store.dispatch("Avanzar");
            this.mostrarAccionistasNaturales = false;
            this.mostrarAccionistasJuridicos = true;
            this.LimpiarInfoAccionistasNaturales();
          }
        }
      }
    },
    MostrarSeccionPepAN(Result: boolean) {
      this.mostrarSeccionPepAN = Result;
      if(!this.mostrarSeccionPepAN){
          this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural()
      }
    },
    MostrarSeccionPepAJ(Result: boolean) {
      this.mostrarSeccionPepAJ = Result;
      if(!this.mostrarSeccionPepAJ){
          this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico()
      }
      
    },
    LimpiarInfoAccionistas(){
        this.InfoAccionista_NaturalRef.reiniciarObjetoInfoAccionista_Natural();
        this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural();
        this.InfoAccionista_JuridicoRef.reiniciarObjetoInfoAccionista_Juridico();
        this.InfoBeneficiarioAccionista_JuridicoRef.reiniciarObjetoInfoBeneficiarioAccionista_Juridico();
        this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico();
        this.mostrarSeccionPepAN = false;
        this.mostrarSeccionPepAJ = false;
    },
    LimpiarInfoAccionistasNaturales(){
        this.InfoAccionista_NaturalRef.reiniciarObjetoInfoAccionista_Natural();
        this.InfoPepAccionista_NaturalRef.reiniciarObjetoInfoPepAccionista_Natural();
        this.mostrarSeccionPepAN = false;
    },
    LimpiarInfoAccionistasJuridicos(){
        this.InfoAccionista_JuridicoRef.reiniciarObjetoInfoAccionista_Juridico();
        this.InfoBeneficiarioAccionista_JuridicoRef.reiniciarObjetoInfoBeneficiarioAccionista_Juridico();
        this.InfoPepAccionista_JuridicoRef.reiniciarObjetoInfoPepAccionista_Juridico();
        this.mostrarSeccionPepAJ = false;
    },
    LimpiaInfoFinanciera(){
        this.store.dispatch("EliminarInformacionFinanciera");
        this.AdicionalRef.objetoArchivos.EstadosFinancieros.InformacionFinanciera = undefined;
        this.FinancieraRef.reiniciarObjetoFinanciera();
    }
  }
});
