import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ValidacionTransmision = _resolveComponent("ValidacionTransmision")!

  return ($setup.store.state.ErrorDialog)
    ? (_openBlock(), _createBlock(_component_ValidacionTransmision, {
        key: 0,
        tipoError: $setup.tipoError
      }, null, 8, ["tipoError"]))
    : _createCommentVNode("", true)
}