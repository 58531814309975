
import { ref, onUnmounted, onMounted } from "vue";

export default {
  props: {
    horaInicio: {
      type: Number,
      required: true,
    },
    minutoInicio: {
      type: Number,
      required: true,
    },
    horaFin: {
      type: Number,
      required: true,
    },
    minutoFin: {
      type: Number,
      required: true,
    },
    intervalo: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    let interval;
    const dentroDeFranjaHoraria = ref(false);

    const VerificarFranjaHoraria = () => {
      const ahora = new Date();
      const horaActual = ahora.getHours();
      const minutoActual = ahora.getMinutes();

      const inicioEnMinutos = props.horaInicio * 60 + props.minutoInicio;
      const finEnMinutos = props.horaFin * 60 + props.minutoFin;
      const actualEnMinutos = horaActual * 60 + minutoActual;

      const dentro =
        actualEnMinutos >= inicioEnMinutos && actualEnMinutos <= finEnMinutos;
        
      return dentro;
    };

    const iniciarIntervalo = () => {
      interval = setInterval(() => {
        dentroDeFranjaHoraria.value = VerificarFranjaHoraria();
        emit("update:modelValue", dentroDeFranjaHoraria.value);
      }, props.intervalo);
    };

    onUnmounted(() => {
      clearInterval(interval);
    });

    onMounted(() => {
      iniciarIntervalo();
    });

    return {
      dentroDeFranjaHoraria,
    };
  },
};
