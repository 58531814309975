
import Initial from "@/components/Qualification/Initial.vue"
import Approved from "@/components/Qualification/Approved.vue"
import Process from "@/components/Qualification/Process.vue"
import Rejected from "@/components/Qualification/Rejected.vue"
import { onMounted } from 'vue';
import { useStore } from "vuex"
import router from '@/router'
import { ref } from 'vue'
import { useMutations } from '@/store/helper'
export default {
  name: "Qualification",
  components: {
    Initial,
    Process,
    Approved,
    Rejected
  },
  setup() {
    const store = useStore();
    const state = ref("EnProceso");
    var intervalStateTestset: number;
    const { setStateMenu } = useMutations(['setStateMenu']);
    setStateMenu(false);
    const urlvd = ref("");
    function goToDashboard() {
      router.push({ name: 'Dashboard' });
    }
    function urlVideo(nombreVideo: string) {
      var params = {
        Token: localStorage.getItem("Tokensin"),
        NombreVideo: nombreVideo,
      };
      store.dispatch('GetUrlVideo', params).then(data => {
        urlvd.value = data.data;
      }).catch(error => {
        console.log(error);
      });
    }

    const chaguestate = (changue: string) => {
      state.value = changue;
      if (changue == "Rechazado") {
        closeInterval()
      } else {
        initializeInterval()
      }
    }
    const Download = () => {
      const url = process.env.VUE_APP_Gateway_API + "Empleador/DownloadInstructive?nombre=instructivo01.pdf";
      window.open(url, '_blank');
    }
    function GetStateTestSet() {
      store.dispatch('GetStateTestSet', localStorage.getItem('Tokensin')).then(data => {
        state.value = data.data;
      }).catch(error => {
        console.log(error);
      });
      if (state.value == "Aprobado" || state.value == "Rechazado" || state.value == "Inicial") {
        closeInterval()
      } else {
        closeInterval()
        initializeInterval()
      }
    }
    function closeInterval() {
      window.clearInterval(intervalStateTestset);
    }
    function initializeInterval() {
      intervalStateTestset = window.setInterval(GetStateTestSet, 40000);
    }
    onMounted(() => {
      urlVideo("VideoHabilitacion.mp4");
      GetStateTestSet();

    });

    return {
      goToDashboard,
      state,
      chaguestate,
      Download,
      urlvd,
    };
  }

};
