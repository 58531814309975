import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class ODValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const OD = reactive(
      store.state.DetalleNomina.JsonNomina
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasOD = computed(() => {
      return {
        OtherDeduction: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE197`,
            requiredIf(OD.OtherDeductions[0] > maxInt)
          ),
        },
        VoluntaryPension: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE198`,
            requiredIf(OD.GeneralDeductions.WithHoldingAtSource > maxInt)
          ),
        },
        WithHoldingAtSource: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE177`,
            requiredIf(OD.GeneralDeductions.WithHoldingAtSource > maxInt)
          ),
        },
        SavingsToPromoteConstruction: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE179`,
            requiredIf(OD.GeneralDeductions.SavingsToPromoteConstruction > maxInt)
          ),
        },
        Cooperative: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE180`,
            requiredIf(OD.GeneralDeductions.Cooperative > maxInt)
          ),
        },
        TaxEmbargo: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE181`,
            requiredIf(OD.GeneralDeductions.TaxEmbargo > maxInt)
          ),
        },
        SupplementalPlan: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE182`,
            requiredIf(OD.GeneralDeductions.SupplementalPlan > maxInt)
          ),
        },
        Education: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE183`,
            requiredIf(OD.GeneralDeductions.Education > maxInt)
          ),
        },
        RefundDeduction: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE184`,
            requiredIf(OD.GeneralDeductions.RefundDeduction > maxInt)
          ),
        },
        Debt: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE185`,
            requiredIf(OD.GeneralDeductions.Debt > maxInt)
          ),
        },
      };
    });

    return [OD, reglasOD] as const;
  }
}

export default ODValidacion;