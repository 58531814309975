
import router from "@/router";
import Dialog from "primevue/dialog";
import { useMutations } from "@/store/helper";
import { useStore } from "vuex";

export default {
  props: {
    tipoError: String,
  },
  components: {
    Dialog,
  },
  setup(props) {
    const store = useStore();
    const { showErrorDialog } = useMutations(["showErrorDialog"]);
    const { showTranssmission } = useMutations(["showTranssmission"]);
    const { showConfirmDialog } = useMutations(["showConfirmDialog"]);

    const CerrarModalError = () => {
      showErrorDialog(false);
      showTranssmission(false);
      showConfirmDialog(false);
    };

    const ConfirmacionModal = () => {
      if (props.tipoError === "SaldoInsuficiente") {
        router.push({
          name: "Plans",
        });
      } else {
        showErrorDialog(false);
        showTranssmission(false);
      }
    };

    return {
      props,
      store,
      ConfirmacionModal,
      showErrorDialog,
      showTranssmission,
      CerrarModalError,
    };
  },
};
