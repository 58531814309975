
import InputNumber from "primevue/inputnumber";
import VacacionesValidacion from "@/validation/PayrollTransmission/Vacaciones";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { Vacaciones } from "@/helpers/Parametricas";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { onMounted, ref } from "vue";
import { DatetimeToDate, Split } from "@/helpers/helpers";

export default {
  components: {
    InputNumber,
    Calendar,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const listaVacaciones = ref();

    let [holidays, vacaciones, validaciones] =
      new VacacionesValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, holidays, {
      $scope: props.nomina,
    });

    const AgregarFila = () => {
      const holiday = {
        HolidayTypeCode: null,
        StartDate: null,
        EndDate: null,
        Quantity: null,
        Total: null,
      };

      holidays.items.push(holiday);
      vacaciones.push(holiday);
    };

    const EliminarVacaciones = (index: number) => {
      vacaciones.splice(index, 1);
      holidays.items.splice(index, 1);
    };

    const FormatearFechas = () => {
      holidays.items = holidays.items.map((holiday: any) => {
        holiday.StartDate = DatetimeToDate(holiday.StartDate);
        holiday.EndDate = DatetimeToDate(holiday.EndDate);
        return holiday;
      });
    };

    const NombreVacaciones = () => {
      holidays.items = holidays.items.map((h: any) => {
        h.TipoVacaciones = listaVacaciones.value
          .filter((vacaciones: any) => {
            return vacaciones.codigo == h.HolidayTypeCode;
          })
          .shift()?.abreviatura;
        return h;
      });
    };

    const CodigoVacaciones = (vacaciones: string, index: number) => {
      if (vacaciones) {
        holidays.items[index].HolidayTypeCode = Split(vacaciones, 0, "-");
      } else {
        holidays.items[index].HolidayTypeCode = "";
      }
    };

    onMounted(() => {
      listaVacaciones.value = Vacaciones();
      NombreVacaciones();
      FormatearFechas();
    });

    return {
      holidays,
      listaVacaciones,
      v$,
      EliminarVacaciones,
      FormatearFechas,
      AgregarFila,
      CodigoVacaciones,
    };
  },
};
