<template>
  <h2 class="Transmision_title">Nuestros planes por paquete</h2>
  <div class="plan-container">
    <div v-for="item in json" :key="item.id" class="plan-box">
      <div class="title-plan">
        <span>{{ normalizeNumberValidator(item.documents) }}</span> documentos
      </div>
      <div class="value-plan">
        <span>{{ colombianCurrencyValidator(item.value) }}</span>
      </div>
      <button v-on:click="selectPlan(item.value, item.documents)">
        Comprar
      </button>
    </div>
  </div>
  <PopUpRenovacionDebidaDiligencia v-if="mostrarPopUpDD" :estadoDD="estadoDD"></PopUpRenovacionDebidaDiligencia>
</template>

<script>
import {
  normalizeNumberValidator,
  colombianCurrencyValidator
} from "@/store/Payment/plans";
import { ref } from "@vue/reactivity";
import router from "@/router";
import { useMutations } from "@/store/helper";
import { onBeforeMount, onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
import PopUpRenovacionDebidaDiligencia from '@/components/DueDiligence/PopUpRenovacionDebidaDiligencia.vue';
import EstadoDebidaDiligencia from "../models/DueDiligence/EstadoDebidaDiligencia";
import { TipoMarca } from '../models/Payroll/Enums';

export default {
  name: "Plans",
  components: {PopUpRenovacionDebidaDiligencia},
  setup() {
    const store = useStore();
    const { setStateMenu } = useMutations(["setStateMenu"]);
    const mostrarPopUpDD = ref(false);
    const estadoDD = ref(0);
    setStateMenu(true);
    const json = ref([ ]);
    let featureFlag = ref(false);

    function getListPlans() {
      store.dispatch('GetListPlans', localStorage.getItem("Tokensin")).then(data => {
        json.value = [];
         data.data.forEach(element => {
           var plan =
           {
             id: element.planId,
             documents: element.cantidadDocumentos,
             value: element.valor
           };
           json.value.push(plan);
         });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    function selectPlan(value, documents) {
      if(mostrarPopUpDD.value)
      {
        router.push({ name: "Plans" })
      }
      else
      {
        if(featureFlag.value)
        {
          router.push({
            name: "PaylinePaymentForm",
            params: {
              valuePlan: Number(value),
              documentsCount: Number(documents),
            },
          });
        }
        else
        {
          router.push({
            name: "KushkiPaymentForm",
            params: {
              valuePlan: Number(value),
              documentsCount: Number(documents),
              featureFlag: Boolean(featureFlag.value)
            },
          });
        }     
      }
    }
    function getKhuskiSettings() {
      store.dispatch("GetPublicMerchantId", localStorage.getItem("Tokensin"))
        .then((response) => {
          localStorage.setItem("publicMerchantId", response.data);
        })
        .catch((err) => {
          console.error(err);
        });
    }
    function GetFeatureFlag(){
      const data = 
      {
        token: localStorage.getItem('Tokensin'),
        featureFlagName: 'PBI-257151'
      }
      store.dispatch('GetStatusFeatureFlag', data).then(response => {
        featureFlag.value = response.data;
          })
          .catch((err) => {
            console.error(err);
          });
    }
    function MostrarOcultarPopUpDebidaDiligencia() {   
      store.dispatch('validarEstadoParaMostrarComponenteDD').then((response) => 
      {
          estadoDD.value = response;         
          if(response == EstadoDebidaDiligencia.ParaCrearNuevaDD)
          {          
            var params = {
              Token: localStorage.getItem("Tokensin"),
              TipoMarca: TipoMarca.CompraPlanes
            };
            store.dispatch('ObtenerInfoMarca', params)
              .then((resp) => {          
                if(!resp.data.activo){
                  mostrarPopUpDD.value = true;
                }      
              })
              .catch((err) => {
                console.error(err);
              });
          }
          else
          {
            if(response == EstadoDebidaDiligencia.CreadaDD || response == EstadoDebidaDiligencia.RechazadaDD)
            {
              mostrarPopUpDD.value = true;
            }
          }                 
      });
    }
      

    onMounted(() => {
      if (localStorage.getItem("TieneDatosContacto") == 'false'){
        router.push({ name: "EmployerContact" });
      } else {
        getListPlans();
        getKhuskiSettings();
      }
    });

    onBeforeMount(() => {
      GetFeatureFlag();
      MostrarOcultarPopUpDebidaDiligencia();
    })
    return {
      json,
      selectPlan,
      normalizeNumberValidator,
      colombianCurrencyValidator,
      getListPlans,
      mostrarPopUpDD,
      estadoDD,
      GetFeatureFlag
    };
  },
};
</script>
