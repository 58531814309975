<template>
    <div class="plan-selected-container">
      <div v-if="respuestaBanco" class="data-plans">
        <div class="header-respuesta">
          <font-awesome-icon
            :icon="['fas', responseCodes[respuestaBanco.estado].icon]"
            size="3x"
          />
          <div>
            <h2 class="transact-title">
              {{ responseCodes[respuestaBanco.estado].title }}
            </h2>
            <label>{{
              responseCodes[respuestaBanco.estado].message
            }}</label>
          </div>
        </div>
        <h3>Datos de la transacción</h3>
        <div class="bank-response">
          <div class="bank-response-row">
            <span>Tipo de identificación</span>
            <span>{{ tipoIdentificacionTable }}</span>
          </div>
          <div class="bank-response-row">
            <span>Número de identificación</span>
            <span>{{ identificacionTable }}</span>
          </div>
          <div class="bank-response-row">
            <span>Número de referencia</span>
            <span>{{ respuestaBanco.referenciapago1 }}</span>
          </div>
          <div class="bank-response-row">
            <span>Banco</span>
            <span>{{ respuestaBanco.nombrebanco }}</span>
          </div>
          <div class="bank-response-row">
            <span>Fecha y hora</span>
            <span>{{ normalizeDateValidator12Hours(tiempoActual) }}</span>
          </div>
          <div class="bank-response-row">
            <span>Monto pagado</span>
            <span
              ><strong>{{
                colombianCurrencyValidator(respuestaBanco.valor)
              }}</strong></span
            >
          </div>
          <div class="bank-response-row">
            <span>CUS</span>
            <span>{{ respuestaBanco.idtxproveedor }}</span>
          </div>
          <div class="bank-response-row">
            <span>Descripción</span>
            <span>{{ respuestaBanco.descripcion }}</span>
          </div>
          <div class="footer-form">
            <button class="back-form" type="button" v-on:click="print">
              Imprimir
            </button>
            <button
              type="button"
              v-on:click="redirectDuePaymentState(respuestaBanco.estado)"
            >
              Finalizar
            </button>
          </div>
        </div>
        <MessageToClient />
      </div>
      <Spinner v-if="!respuestaBanco" type="modern"></Spinner>
    </div>
  </template>
  
  <script>
  import { onMounted } from "@vue/runtime-core";
  import { useRoute } from "vue-router";
  import { ref, reactive } from "vue";
  import { useStore } from "vuex";
  import { normalizeDateValidator, normalizeDateValidator12Hours, colombianCurrencyValidator } from "@/store/Payment/plans";
  import Spinner from "@/components/layout/Spinner.vue";
  import router from "@/router";
  import MessageToClient from "@/components/Payment/MessageToClient.vue";
  import ProveedorPago from "../models/payment/ProveedorPago";
  
  const responseCodes = {
    Rechazada: {
      title: "Transacción rechazada",
      message: "Al finalizar podrás reintentar tu pago",
      icon: "times-circle",
      redirect: "Plans",
    },
    Pendiente: {
      title: "Transacción pendiente",
      message: "Tu pago está en proceso de validación",
      icon: "redo-alt",
      redirect: "WaitingPayment",
    },
    SinProcesar: {
      title: "Transacción pendiente",
      message: "Tu pago está en proceso de validación",
      icon: "redo-alt",
      redirect: "WaitingPayment",
    },
    Aprobada: {
      title: "Transacción aprobada",
      message: "* Sujeto a verificación",
      icon: "check-circle",
      redirect: "Transmission",
    },
  };
  
  export default {
    name: "PaylineBankResponse",
    props: {},
    components: {
      Spinner,
      MessageToClient,
    },
    setup() {
      let respuestaBanco = ref();
      const tiempoActual = new Date(Date.now());

      const tipoIdentificacionTable = localStorage.getItem("TipoIdentificacion");
      const identificacionTable = localStorage.getItem("Identificacion")

      function getPaymentInfoByToken() {
        const store = useStore();
  
        let data = {
          tokenAEL: localStorage.getItem("Tokensin"),
          token: '',
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          proveedorPago: ProveedorPago.Pasarela
        };
  
        store
          .dispatch("GetPaymentInfo", data)
          .then((res) => {
            respuestaBanco.value = res.data;
          })
          .catch((err) => console.error(err));
      }
      function print() {
        window.print();
      }
      function redirectDuePaymentState(estado) {
        router.push({ name: responseCodes[estado].redirect });
      }
      onMounted(() => {
        getPaymentInfoByToken();
      });
      return {
        respuestaBanco,
        print,
        normalizeDateValidator,
        normalizeDateValidator12Hours,
        colombianCurrencyValidator,
        redirectDuePaymentState,
        tiempoActual,
        tipoIdentificacionTable,
        identificacionTable
      };
    },
    computed: {
      responseCodes: () => responseCodes,
    },
  };
  </script>
