<template>

    <download-excel class="Button_BColorDegradedGreen Button_DimensionGreen" :data="data" :fields="json_fields"
        type="xlsx" worksheet="My Worksheet" :name="name">
        <font-awesome-icon :icon="['fas', 'download']" size="sm" /> Descargar
    </download-excel>

</template>

<script>

import { onMounted, ref } from "vue";
import JsonExcel from "vue-json-excel3";

export default {
    name: "DescargarExcel",
    components: {
        downloadExcel: JsonExcel
    },
    props: {
        data: {},
        json_fields: {},
        name: String
    },
    setup(props) {

        const data = ref({});
        const json_fields = ref({});
        const name = ref('');
      
        onMounted(() => {
            data.value = props.data;
            json_fields.value = props.json_fields;
            name.value = props.name;

        });

        return {
            data,
            name,
            json_fields      
        };
    },
};

</script>