import { computed, reactive } from 'vue'
import { required, helpers, maxLength } from '@vuelidate/validators'

const numAndLetters = helpers.regex(/^[0-9a-zA-Z]+$/);
const caracteresEspeciales = "No se admiten espacios o caracteres especiales";

class DireccionValidacion {

    public ObtenerReglasValidacion() {

        const direccion = reactive(
            {
                TipoVia: "",
                NumeroVia: "",
                OrientacionEsteOeste: "",
                NumeroComplementario: "",
                OrientacionNorteSur: "",
                NumeroAdicional: "",
                TipoUbicacion: "",
                Ubicacion: ""
            }
        );

        const reglasDireccion = computed(() => {
            return {
                TipoVia: {
                    required: helpers.withMessage(
                        `Selecciona un valor`,
                        required
                    ),
                },
                NumeroVia: {
                    required: helpers.withMessage(
                        `Ingresa un valor`,
                        required
                    ),
                    maxLength: helpers.withMessage(
                        "Ingresa máximo 5 caracteres",
                        maxLength(5)
                    ),
                    pattern: helpers.withMessage(
                        `${caracteresEspeciales}`,
                        numAndLetters
                    )
                },
                NumeroComplementario: {
                    required: helpers.withMessage(
                        `Ingresa un valor`,
                        required
                    ),
                    maxLength: helpers.withMessage(
                        "Ingresa máximo 5 caracteres",
                        maxLength(5)
                    ),
                    pattern: helpers.withMessage(
                        `${caracteresEspeciales}`,
                        numAndLetters
                    )
                },
                NumeroAdicional: {
                    required: helpers.withMessage(
                        `Ingresa un valor`,
                        required
                    ),
                    maxLength: helpers.withMessage(
                        "Ingresa máximo 3 caracteres",
                        maxLength(3)
                    ),
                    numeric: helpers.withMessage(
                        "Sólo puedes ingresar números",
                        helpers.regex(/^\d+$/)
                    )
                },
                TipoUbicacion: {
                    required: helpers.withMessage(
                        `Selecciona un valor`, () => {
                            if (direccion.Ubicacion && !direccion.TipoUbicacion) {
                                return false;
                            }
                            return true;
                        }
                    ),
                },
                Ubicacion: {
                    required: helpers.withMessage(
                        `Ingresa un valor`, () => {
                            if (direccion.TipoUbicacion && !direccion.Ubicacion) {
                                return false;
                            }
                            return true;
                        }
                    ),
                    maxLength: helpers.withMessage(
                        "Ingresa máximo 20 caracteres",
                        maxLength(20)
                    ),
                },
            };
        });

        return [direccion, reglasDireccion] as const;
    }
}

export default DireccionValidacion;