

import useVuelidate from '@vuelidate/core';
import { defineComponent } from 'vue';
import InfoBeneficiarioAccionista_JuridicoValidacion from '../../validation/InfoBeneficiarioAccionista_Juridico';
import '../../assets/sass/components/DueDiligence/_legal.scss';

export default defineComponent({
    props: {
        scopeInfoBeneficiarioAccionista_Juridico: String,
        parametricas: {}
    },
    setup(props) {
        
        let [objetoInfoBeneficiarioAccionista_Juridico, reglasValidacion] = new InfoBeneficiarioAccionista_JuridicoValidacion().ObtenerReglasValidacion();

        const AgregarFilaBeneficiario = () => {

            objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.push({
                NombreAJ_Beneficiario: '',
                NombreBeneficiario: '',
                TipoIdBeneficiario: '',
                NumeroIdentificacionBeneficiario: '',
                EsPEPBeneficiario: '',
                VinculosFamPEP_Beneficiario: '',
            }); 
        }

        const EliminarFilaBeneficiario = (index: number) => {
            objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.splice(index, 1);
        }

        const reiniciarObjetoInfoBeneficiarioAccionista_Juridico = () => {
          
          objetoInfoBeneficiarioAccionista_Juridico.Beneficiario = [];
          AgregarFilaBeneficiario();
        }

        const validacionInfoBeneficiarioAccionista_Juridico = useVuelidate(reglasValidacion, objetoInfoBeneficiarioAccionista_Juridico, { $scope: props.scopeInfoBeneficiarioAccionista_Juridico })

        const tiposIdentificacion = [{
            value: "2",
            label: "CC",
          },
          {
            value: "3",
            label: "CE",
          },
          {
            value: "5",
            label: "PAS",
          },
          {
            value: "6",
            label: "PE",
          }
        ]


        return { 
          AgregarFilaBeneficiario, 
          EliminarFilaBeneficiario,
          validacionInfoBeneficiarioAccionista_Juridico, objetoInfoBeneficiarioAccionista_Juridico, reiniciarObjetoInfoBeneficiarioAccionista_Juridico,
          tiposIdentificacion
        }
    },
    methods:{
        ValidaSiPEPAJ()
        {
            let i;
            let Result = false;
            for(i = 0; i < this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario.length; i++)
            {
                if(this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario[i].EsPEPBeneficiario.includes("Si") || this.objetoInfoBeneficiarioAccionista_Juridico.Beneficiario[i].VinculosFamPEP_Beneficiario.includes("Si"))
                {
                    Result = true;
                    break;
                }
                
            }
            this.$emit('MostrarSeccionPepAJ',Result)
        }
    },
})

