import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class CompensacionesValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const compensaciones = reactive(
      store.state.DetalleNomina.JsonNomina.Compensations
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasCompensaciones = computed(() => {
      return {
        Total: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE149`,
            requiredIf(
              compensaciones.length > 0
                ? compensaciones[0].Total > maxInt
                : false
            )
          ),
        },
        ExtraordinaryTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE150`,
            requiredIf(
              compensaciones.length > 0
                ? compensaciones[0].ExtraordinaryTotal > maxInt
                : false
            )
          ),
        },
      };
    });

    return [compensaciones, reglasCompensaciones] as const;
  }
}

export default CompensacionesValidacion;