
import DatosEmpresaValidacion from "@/validation/PayrollTransmission/DatosEmpresa";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import { Paises, Departamentos } from "@/helpers/Parametricas";
import { useStore } from "vuex";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import { computed, onMounted, ref } from "vue";
import { Split, CampoNuloOVacio, CompletarDigitos } from "@/helpers/helpers";

export default {
  components: {
    InputText,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },

  setup(props) {
    const store = useStore();
    const pais = ref("");
    const dpto = ref("");
    const digitosCiudad = 3;
    const municipio = ref("");
    const divipolas = store.state.ParametricasTransmision.Divipolas;

    let [empresa, validaciones] =
      new DatosEmpresaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, empresa, {
      $scope: props.nomina,
    });

    const PaisSeleccionado = () => {
      if (pais.value) {
        empresa.CountryCode = Split(pais.value, 0, "-");
      } else {
        dpto.value = "";
        municipio.value = "";
        empresa.CountryCode = "";
        empresa.DepartmentCode = "";
        empresa.CityCode = "";
        v$.value.CountryCode.$touch();
      }
    };

    const DptoSeleccionado = () => {
      municipio.value = "";
      empresa.CityCode = "";

      if (dpto.value) {
        empresa.DepartmentCode = Split(dpto.value, 1, "_");
      } else {
        empresa.DepartmentCode = "";
        v$.value.DepartmentCode.$touch();
      }
    };

    const MunicipioSeleccionado = () => {
      if (municipio.value) {
        empresa.CityCode = CompletarDigitos(Split(municipio.value, 0, "-"), 3);
      } else {
        empresa.CityCode = "";
        v$.value.CityCode.$touch();
      }
    };

    const Municipios = computed(() => {
      if (dpto.value) {
        return divipolas.filter((municipio: any) => {
          return municipio.codigoDepartamento == Split(dpto.value, 1, "_");
        });
      }
    });

    const Divipolas = () => {
      pais.value = Paises()
        .filter((p: any) => {
          return p.codigo === empresa.CountryCode;
        })
        .shift()?.abreviatura;

      dpto.value = Departamentos()
        .filter((d: any) => {
          return d.codigo === empresa.DepartmentCode;
        })
        .shift()?.abreviatura;

      divipolas.value = divipolas.map((divipola: any) => {
        divipola.codigoCiudad = CompletarDigitos(
          divipola.codigoCiudad.toString(),
          digitosCiudad
        );
        return divipola;
      });

      municipio.value = divipolas
        .filter((d: any) => {
          return (
            d.codigoDepartamento == empresa.DepartmentCode &&
            Number(d.codigoCiudad) == Number(empresa.CityCode)
          );
        })
        .shift()?.abreviatura;
    };

    onMounted(() => {
      Divipolas();
    });

    return {
      empresa,
      v$,
      Paises,
      Departamentos,
      Municipios,
      DptoSeleccionado,
      PaisSeleccionado,
      MunicipioSeleccionado,
      pais,
      dpto,
      municipio,
      CampoNuloOVacio,
    };
  },
};
