import { reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'
import InfoPEPRepresentante from '../components/DueDiligence/InfoPEPRepresentante.vue'

class InfoRepresentantePepValidacion{

     public ObtenerReglasValidacion() {

         const objetoInfoRepresentantePep = reactive({
             RLPep:[{
                NombreRLPEP: '',
                NombrePEP: '',
                TipoIdPEPRL: '',
                NumeroIdentificacionPEPRL: '',
                TipoVinculoPEPRL: '',
                TipoPEPRL: ''
             }],
         });

         const requiredMessage = 'Campo obligatorio';
         
         
         const reglasValidacion = {
            RLPep: {
                 $each: helpers.forEach({
                    NombreRLPEP: {
                         required: helpers.withMessage(requiredMessage, required)
                     },
                     NombrePEP: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoIdPEPRL: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     NumeroIdentificacionPEPRL: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoVinculoPEPRL: {
                         required: helpers.withMessage(requiredMessage, required),
                     },
                     TipoPEPRL: {
                        required: helpers.withMessage(requiredMessage, required),
                    }
                 })
             }     
         };

        return [objetoInfoRepresentantePep, reglasValidacion] as const;
    }
}

export default InfoRepresentantePepValidacion;