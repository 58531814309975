
import InputNumber from "primevue/inputnumber";
import SsptaValidacion from "@/validation/PayrollTransmission/Sspta";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { onBeforeMount } from "vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [sspta, validaciones] = new SsptaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, sspta, {
      $scope: props.nomina,
    });

    const ValidarSindicatos = () => {
      if (sspta.LaborUnions.length == 0) {
        sspta.LaborUnions.push({
          Percentage: null,
          Total: null,
        });
      }
    };

    const ValidarSanciones = () => {
      if (sspta.Sanctions.length == 0) {
        sspta.Sanctions.push({
          PublicTotal: null,
          PrivateTotal: null,
        });
      }
    };

    onBeforeMount(() => {
      ValidarSindicatos();
      ValidarSanciones();
    });

    return {
      sspta,
      v$,
    };
  },
};
