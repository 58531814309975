
import InputNumber from "primevue/inputnumber";
import TransporteValidacion from "@/validation/PayrollTransmission/Transporte";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { onBeforeMount } from "vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [transporte, validaciones] =
      new TransporteValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, transporte, {
      $scope: props.nomina,
    });

    const ValidarTransporte = () => {
      if (transporte.length == 0) {
        transporte.push({
          TransportationAssistance: null,
          SalarialViatic: null,
          NonSalarialViatic: null,
        });
      }
    };

    onBeforeMount(() => {
      ValidarTransporte();
    });

    return {
      transporte,
      v$,
    };
  },
};
