import { helpers, maxValue, required } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class TotalNominaValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const totalNomina = reactive(
      store.state.DetalleNomina.JsonNomina
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";

    const reglasTotalNomina = computed(() => {
      return {
        Rounding: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE186`,
            maxValue(maxInt)
          ),
        },
        AcrrualsTotal: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE187`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(`${campoObligatorio} NIE187`, required),
        },
        DeductionsTotal: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE188`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(`${campoObligatorio} NIE188`, required),
        },
        Total: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE189`,
            maxValue(maxInt)
          ),
          required: helpers.withMessage(`${campoObligatorio} NIE189`, required),
        },
      };
    });

    return [totalNomina, reglasTotalNomina] as const;
  }
}

export default TotalNominaValidacion;