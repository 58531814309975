
import LibranzasValidacion from "@/validation/PayrollTransmission/Libranzas";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";

export default {
  components: {
    InputText,
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },

  setup(props) {
    let [drafts, validaciones] =
      new LibranzasValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, drafts, {
      $scope: props.nomina,
    });

    const AgregarLibranza = () => {
      const draft = {
        Description: null,
        Total: null,
      };

      drafts.items.push(draft);
    };

    const EliminarLibranza = (index: number) => {
      drafts.items.splice(index, 1);
    };

    return {
      drafts,
      v$,
      EliminarLibranza,
      AgregarLibranza,
    };
  },
};
