import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_3 = {
  key: 0,
  class: "el-form-item__error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_form_item = _resolveComponent("el-form-item")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_form_item, {
      label: "Dirección",
      class: "field"
    }, _createSlots({
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 2 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, { span: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  filter: "",
                  showClear: "",
                  modelValue: $setup.direccion.TipoVia,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.direccion.TipoVia) = $event)),
                  optionLabel: "abreviatura",
                  optionValue: "abreviatura",
                  class: _normalizeClass(["pv-input-size", { 'p-invalid': $setup.v$.TipoVia.$error }]),
                  options: $setup.tiposVias,
                  onChange: _cache[1] || (_cache[1] = ($event: any) => ($setup.v$.TipoVia.$touch()))
                }, null, 8, ["modelValue", "options", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  class: _normalizeClass(["d-text", { 'p-invalid': $setup.v$.NumeroVia.$error }]),
                  onInput: _cache[2] || (_cache[2] = ($event: any) => ($setup.v$.NumeroVia.$touch())),
                  modelValue: $setup.direccion.NumeroVia,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (($setup.direccion.NumeroVia) = $event))
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  filter: "",
                  showClear: "",
                  modelValue: $setup.direccion.OrientacionEsteOeste,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => (($setup.direccion.OrientacionEsteOeste) = $event)),
                  optionLabel: "abreviatura",
                  optionValue: "abreviatura",
                  class: "pv-input-size",
                  options: $setup.cardinalidad
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 1,
              id: "hashtag"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" # ")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 3 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  class: _normalizeClass(["d-text", { 'p-invalid': $setup.v$.NumeroComplementario.$error }]),
                  modelValue: $setup.direccion.NumeroComplementario,
                  "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (($setup.direccion.NumeroComplementario) = $event)),
                  onInput: _cache[6] || (_cache[6] = ($event: any) => ($setup.v$.NumeroComplementario.$touch()))
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 5 }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  filter: "",
                  showClear: "",
                  modelValue: $setup.direccion.OrientacionNorteSur,
                  "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => (($setup.direccion.OrientacionNorteSur) = $event)),
                  optionLabel: "abreviatura",
                  optionValue: "abreviatura",
                  class: "pv-input-size",
                  options: $setup.cardinalidad
                }, null, 8, ["modelValue", "options"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 2 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  class: _normalizeClass(["d-text", { 'p-invalid': $setup.v$.NumeroAdicional.$error }]),
                  modelValue: $setup.direccion.NumeroAdicional,
                  "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => (($setup.direccion.NumeroAdicional) = $event)),
                  onInput: _cache[9] || (_cache[9] = ($event: any) => ($setup.v$.NumeroAdicional.$touch()))
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            ($setup.hayErroresDireccion)
              ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($setup.errorDireccion), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 2
    }, [
      ($setup.props.requiredAsterisk)
        ? {
            name: "label",
            fn: _withCtx(() => [
              _createElementVNode("span", {
                innerHTML: $setup.asterisk('Dirección')
              }, null, 8, _hoisted_1)
            ]),
            key: "0"
          }
        : undefined
    ]), 1024),
    _createVNode(_component_el_form_item, { class: "field" }, {
      default: _withCtx(() => [
        _createVNode(_component_el_row, { gutter: 6 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 12,
              class: "adicional"
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Información adicional de la dirección (opcional) ")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  filter: "",
                  showClear: "",
                  modelValue: $setup.direccion.TipoUbicacion,
                  "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => (($setup.direccion.TipoUbicacion) = $event)),
                  optionLabel: "abreviatura",
                  optionValue: "abreviatura",
                  class: _normalizeClass(["pv-input-size", { 'p-invalid': $setup.v$.TipoUbicacion.$error }]),
                  options: $setup.tiposUbicacion,
                  onChange: _cache[11] || (_cache[11] = ($event: any) => ($setup.v$.TipoUbicacion.$touch()))
                }, null, 8, ["modelValue", "options", "class"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 6 }, {
              default: _withCtx(() => [
                _createVNode(_component_InputText, {
                  type: "text",
                  class: _normalizeClass(["d-text", { 'p-invalid': $setup.v$.Ubicacion.$error }]),
                  modelValue: $setup.direccion.Ubicacion,
                  "onUpdate:modelValue": _cache[12] || (_cache[12] = ($event: any) => (($setup.direccion.Ubicacion) = $event)),
                  onInput: _cache[13] || (_cache[13] = ($event: any) => ($setup.v$.Ubicacion.$touch()))
                }, null, 8, ["modelValue", "class"])
              ]),
              _: 1
            }),
            ($setup.hayErroresOpcional)
              ? (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString($setup.errorOpcional), 1))
              : _createCommentVNode("", true)
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ], 64))
}