
import InputNumber from "primevue/inputnumber";
import CompensacionesValidacion from "@/validation/PayrollTransmission/Compensaciones";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { onBeforeMount } from "vue";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [compensaciones, validaciones] =
      new CompensacionesValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, compensaciones, {
      $scope: props.nomina,
    });

    const ValidarCompensaciones = () => {
      if (compensaciones.length == 0) {
        compensaciones.push({
          Total: null,
          ExtraordinaryTotal: null,
        });
      }
    };

    onBeforeMount(() => {
      ValidarCompensaciones();
    });

    return {
      compensaciones,
      v$,
    };
  },
};
