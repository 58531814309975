
import router from '@/router';
import {useStore} from "vuex";
import { ref } from '@vue/reactivity';
import{useMutations} from '@/store/helper';
import { watch} from '@vue/runtime-core';
import Spinner from "@/components/layout/Spinner.vue"
export default {
  name: "Contract",
  components: {
      Spinner
  },
  setup() {
    const loading = ref(false);  
    const store= useStore();
    const dialogVisible=ref(false);
    const checkTerminos= ref(false);
    const Error= ref(false);
    const styleCheckError= ref({'border': '3px solid red', 'background-color':'red'});
    const{setTyC} = useMutations(['setTyC']);
    function Validarcheck(){
        if(checkTerminos.value){
            goToDashboard();
            Error.value=false;
        }else{
            Error.value=true;
        }
      }
      watch(()=>checkTerminos.value,()=>{
        if(checkTerminos.value){
            Error.value=false;
          }else{
            Error.value=true;
          }
      }); 
    function goToDashboard(){
      loading.value = true;
      var data={
          Identificacion:localStorage.getItem('Identificacion'),
          Tipoidentificacion:localStorage.getItem('TipoIdentificacion'),
          EstadoAporbacion:checkTerminos
          
        }  
      setTyC(data);
      store
      .dispatch("SendStateEmpleadorTyC", localStorage.getItem("Tokensin"))
      .then(() => {
        store.dispatch('ValidateContactData', localStorage.getItem('Tokensin')).then(
          (res) => {
            loading.value = false;
            localStorage.setItem('TieneDatosContacto', res.data);
            localStorage.setItem('DatosContactoRedireccion', 'Plans');
            if (res.data){
              router.push({name:'Plans'});
            } else {
              router.push({name:'EmployerContact'});
            }
          }
        ).catch((err) => {
          loading.value = false;
          console.log(err);
          router.push({name:'EmployerContact'});
        })
      })
      .catch((error) => {
        loading.value = false;
        console.log(error);
      });
    }
    return {
        dialogVisible,
        checkTerminos,
        styleCheckError,
        Error,
        Validarcheck,
        goToDashboard,
        loading,
    };
  },
};
