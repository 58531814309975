import { helpers, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class SsptaValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const sspta = reactive(
      store.state.DetalleNomina.JsonNomina
    );

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido."

    const reglasSspta = computed(() => {
      return {
        LaborUnionPercentage: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE171`,
            requiredIf(
              sspta.LaborUnions.length > 0
                ? sspta.LaborUnions[0].Percentage > maxInt
                : false
            )
          ),
        },
        LaborUnionTotal: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE172`,
            requiredIf(
              sspta.LaborUnions.length > 0
                ? sspta.LaborUnions[0].Total > maxInt
                : false
            )
          ),
        },
        SanctionPublicTotal: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE173`,
            requiredIf(
              sspta.Sanctions.length > 0
                ? sspta.Sanctions[0].PublicTotal > maxInt
                : false
            )
          ),
        },
        SanctionPrivateTotal: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE174`,
            requiredIf(
              sspta.Sanctions.length > 0
                ? sspta.Sanctions[0].PrivateTotal > maxInt
                : false
            )
          ),
        },
        ThirdPartyPaymentDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE195`,
            requiredIf(sspta.ThirdPartyPaymentDeductions[0] > maxInt)
          ),
        },
        PrepaidDeduction: {
          maxValue: helpers.withMessage(
            `${valorInvalido} NIE196`,
            requiredIf(sspta.PrepaidDeductions[0] > maxInt)
          ),
        },
      };
    });

    return [sspta, reglasSspta] as const;
  }
}

export default SsptaValidacion;