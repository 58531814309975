import { computed, reactive } from 'vue'
import { required, helpers } from '@vuelidate/validators'

class DivipolaValidacion {

  public ObtenerReglasValidacion() {

    const divipolas = reactive({ Pais: "", Departamento: "", Municipio: "" });

    const reglasDivipolas = computed(() => {
      return {
        Pais: {
          required: helpers.withMessage(
            `Selecciona un país`,
            required
          ),
        },
        Departamento: {
          required: helpers.withMessage(
            `Selecciona un departamento`,
            required
          ),
        },
        Municipio: {
          required: helpers.withMessage(
            `Selecciona un municipio`,
            required
          ),
        },
      };
    });

    return [divipolas, reglasDivipolas] as const;
  }
}

export default DivipolaValidacion;