import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "Initialize_Error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (Error != '')
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString($setup.Error), 1))
        : _createCommentVNode("", true)
    ]),
    ($setup.loading)
      ? (_openBlock(), _createBlock(_component_Spinner, {
          key: 0,
          type: "modern"
        }))
      : _createCommentVNode("", true)
  ], 64))
}