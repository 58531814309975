
import { ref } from "vue";
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import HorasExtras from "@/components/TransmisionNomina/HorasExtras.vue";
import { TabDeducciones } from "@/models/Payroll/Enums";
import Incapacidades from "@/components/TransmisionNomina/Incapacidades.vue";
import Licencias from "@/components/TransmisionNomina/Licencias.vue";
import Vacaciones from "@/components/TransmisionNomina/Vacaciones.vue";
import HuelgasLegales from "@/components/TransmisionNomina/HuelgasLegales.vue";
import OtrosConceptos from "@/components/TransmisionNomina/OtrosConceptos.vue";
import Libranzas from "@/components/TransmisionNomina/Libranzas.vue";

export default {
  components: {
    TabView,
    TabPanel,
    HorasExtras,
    Incapacidades,
    Licencias,
    Vacaciones,
    HuelgasLegales,
    OtrosConceptos,
    Libranzas,
  },
  props: {
    nomina: String
  },
  setup(props) {
    const tabs = TabDeducciones;
    const activeTab = ref(tabs.HorasExtras);
    const scopeNomina = props.nomina;

    return {
      activeTab,
      scopeNomina
    };
  },
};
