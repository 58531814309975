<template>
    <div class="chatButton" v-if="showChatButton">
        <a :href="urlChatSoporte" target="_blank" >
            <img src="../../assets/img/chatButton.png" alt="logo" width="149" height="150" >
        </a>
    </div>
</template>

<script>
    import { useRoute } from 'vue-router';
    import { ref, watch } from 'vue';
    export default {
        setup() {
            const urlChatSoporte = process.env.VUE_APP_ChatSoporte ? process.env.VUE_APP_ChatSoporte : '#';
            const unauthorizedViews = ['Contract','Dashboard','Information','Initialize','Qualification','DueDiligenceR','validationNeL'];
            const route = useRoute();
            const showChatButton = ref(true);

            watch(
                () => route.name,
                (currentView) => {
                    if (unauthorizedViews.includes(currentView)) {
                        showChatButton.value = false;
                    } else {
                        showChatButton.value = true;
                    }
                }
            );

            return {
                urlChatSoporte,
                showChatButton
            };
        },
    };
</script>
 