import { helpers, maxValue, requiredIf } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class CABValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const CAB = reactive(
      store.state.DetalleNomina.JsonNomina
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasCAB = computed(() => {
      return {
        Commission: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE155`,
            requiredIf(CAB.Commissions[0] > maxInt)
          ),
        },
        AssistanceTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE141`,
            requiredIf(
              CAB.Assistances.length > 0
                ? CAB.Assistances[0].Total > maxInt
                : false
            )
          ),
        },
        AssistanceNonSalaryTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE142`,
            requiredIf(
              CAB.Assistances.length > 0
                ? CAB.Assistances[0].NonSalaryTotal > maxInt
                : false
            )
          ),
        },
        BonusTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE139`,
            requiredIf(
              CAB.EpctvBonuses.length > 0
                ? CAB.Bonuses[0].Total > maxInt
                : false
            )
          ),
        },
        BonusNonSalaryTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE140`,
            requiredIf(
              CAB.EpctvBonuses.length > 0
                ? CAB.Bonuses[0].NonSalaryTotal > maxInt
                : false
            )
          ),
        },
      };
    });

    return [CAB, reglasCAB] as const;
  }
}

export default CABValidacion;