
import InputNumber from "primevue/inputnumber";
import ODValidacion from "@/validation/PayrollTransmission/OtrasDeducciones";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";

export default {
  components: {
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    let [OD, validaciones] = new ODValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, OD, {
      $scope: props.nomina,
    });

    return {
      OD,
      v$,
    };
  },
};
