
import EmpleadoValidacion from "@/validation/PayrollTransmission/Empleado";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import { DatetimeToDate, ObtenerEstado, Split } from "@/helpers/helpers";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import { onMounted, ref } from "vue";
import {
  Decision,
  TiposTrabajador,
  SubtiposTrabajador,
} from "@/helpers/Parametricas";
import { useStore } from "vuex";

export default {
  components: {
    InputText,
    Dropdown,
    Calendar,
    InputNumber,
    Tooltip,
  },
  props: {
    nomina: String,
  },

  setup(props) {
    const workerTypeCode = ref("");
    const subWorkerTypeCode = ref("");
    const isIntegralSalary = ref("");
    const isHighRiskPension = ref("");
    const fechaInicioNovedad = ref("");
    const hasNovelty = ref("");
    const store = useStore();

    let [empleado, validaciones] =
      new EmpleadoValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, empleado, {
      $scope: props.nomina,
    });

    const CodigoTipoTrabajador = () => {
      empleado.WorkerTypeCode = Split(workerTypeCode.value, 0, "-");
    };

    const CodigoSubTipoTrabajador = () => {
      empleado.SubworkerTypeCode = Split(subWorkerTypeCode.value, 0, "-");
    };

    const TipoTrabajador = () => {
      workerTypeCode.value = TiposTrabajador()
        .filter((w: any) => {
          return w.codigo === empleado.WorkerTypeCode;
        })
        .shift()?.abreviatura;
    };

    const SubtipoTrabajador = () => {
      subWorkerTypeCode.value = SubtiposTrabajador()
        .filter((s: any) => {
          return s.codigo === empleado.SubworkerTypeCode;
        })
        .shift()?.abreviatura;
    };

    const Novedad = () => {
      empleado.hasNovelty = hasNovelty.value == "SI" ? true : false;
    };

    const SalarioIntegral = () => {
      empleado.IsIntegralsalary = isIntegralSalary.value == "SI" ? true : false;
    };

    const AltoRiesgoPension = () => {
      empleado.IsHighRiskPension =
        isHighRiskPension.value == "SI" ? true : false;
    };

    const ValidarFechaIngreso = () => {
      FormatearFechas();
      v$.value.AdmissionDate.$touch();
    };

    const FormatearFechas = () => {
      empleado.AdmissionDate = DatetimeToDate(empleado.AdmissionDate);
      empleado.RetirementDate = DatetimeToDate(empleado.RetirementDate);
      fechaInicioNovedad.value = DatetimeToDate(
        store.state.DetalleNomina.Detalle.fechaInicioNovedad
      );
    };

    onMounted(() => {
      TipoTrabajador();
      SubtipoTrabajador();
      FormatearFechas();
      hasNovelty.value = ObtenerEstado(empleado.HasNovelty);
      isIntegralSalary.value = ObtenerEstado(empleado.IsIntegralsalary);
      isHighRiskPension.value = ObtenerEstado(empleado.IsHighRiskPension);
    });

    return {
      v$,
      empleado,
      Decision,
      Novedad,
      hasNovelty,
      TiposTrabajador,
      SubtiposTrabajador,
      FormatearFechas,
      workerTypeCode,
      subWorkerTypeCode,
      fechaInicioNovedad,
      CodigoTipoTrabajador,
      isIntegralSalary,
      isHighRiskPension,
      CodigoSubTipoTrabajador,
      SalarioIntegral,
      AltoRiesgoPension,
      ValidarFechaIngreso,
    };
  },
};
