enum EstadoEmpleador{
    
    NoDefinido = 0,
    Habilitado = 1,
    Registrado = 2,
    RegistradoPT = 3,
    AprobadoDebidaDiligencia = 4,
    RechazadoDebidaDiligencia = 5,
    AceptoTratamientoDatos = 6,
    AceptoTyC = 7,
    FormularioDebidaDiligencia = 8
}

export default EstadoEmpleador