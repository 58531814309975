const requiredRule = (message: string) => ({
    required: true,
    message,
    trigger: ["blur", "change"],
});
 
const minLengthRule = (min: number, message: string) => ({
    min,
    message,
    trigger: ["blur", "change"],
});
 
const maxLengthRule = (max: number, message: string) => ({
    max,
    message,
    trigger: ["blur", "change"],
});
 
const patternRule = (pattern: RegExp, message: string) => ({
    pattern,
    message,
    trigger: ["blur", "change"],
});

const documentTypePatternRule = (patternGetter: () => RegExp | null, message: string) => ({
    validator:(rule:any,value:string,callback:Function) => {
        const pattern = patternGetter();
        if(pattern && !pattern.test(value)){
            callback(new Error(message));
        }else{
            callback();
        }
    },
    trigger:["blur", "change"]
})
 
export const validationRules = (patternGetter: () => RegExp | null) => ({
    correo: [
        requiredRule("Escribe tu e-mail"),
        {
            type: "email",
            message: "Ingresa un e-mail válido",
            trigger: ["blur", "change"],
        },
        maxLengthRule(120, "Ingresa máximo 120 caracteres"),
    ],
    tipoIdentificacion: [
        requiredRule("Selecciona tipo de documento"),
    ],
    tipoIdentificacionTitular: [
        requiredRule("Selecciona tipo de documento"),
        
    ],
    numeroIdentificacion: [
        requiredRule("Ingresa tu número de documento"),
        minLengthRule(6, "Ingresa al menos 6 caracteres"),
        maxLengthRule(15, "Ingresa máximo 15 caracteres"),
        patternRule(/^[a-zA-Z0-9_.-]*$/, "No se admiten espacios o caracteres especiales"),
    ],
    numeroIdentificacionTitular: [
        requiredRule("Ingresa tu número de documento"),
        maxLengthRule(15, "Ingresa máximo 15 caracteres"),
        documentTypePatternRule(patternGetter, "El formato no corresponde al tipo de documento seleccionado")
    ],
    celular: [
        requiredRule("Ingresa tu número de celular"),
        minLengthRule(10, "Ingresa al menos 10 caracteres"),
        maxLengthRule(10, "Ingresa máximo 10 caracteres"),
        patternRule(/^3\d*$/, "El campo recibe únicamente números celulares"),
    ],
    banco: [
        requiredRule("Selecciona un banco"),
    ],
    nombreORazonSocial: [
        requiredRule("Ingresa la razón social"),
        maxLengthRule(64, "Ingresa máximo 64 caracteres"),
    ],
    razonSocialTitular: [
        requiredRule("Ingresa la razón social"),
        maxLengthRule(64, "Ingresa máximo 64 caracteres"),
    ],
    tipoPersona: [],
});