import moment from "moment"

export enum PaymentState {
    SinProceso = 1,
    EnProceso,
    AprobadoPago,
    RechazadoPago
}

export function colombianCurrencyValidator(n: number) {
    return Number(n).toLocaleString('es-CO', {
        style: 'currency',
        currency: 'COP',
        minimumFractionDigits: 0
    });
}

export function normalizeNumberValidator(n: number) {
    return Number(n).toLocaleString('es-CO');
}

export function normalizeDateValidator(n: Date) {
    return moment(n)
    .format("YYYY-MM-DD HH:mm");
}

export function normalizeDateValidator12Hours(n: Date) {
    return moment(n)
    .format("DD-MM-YYYY HH:mm:ss");
}

export function thousandsSeparatorFormat(n: number):string{
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g,".");
}