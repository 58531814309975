

import { defineComponent,ref, onBeforeMount } from 'vue'
import useVuelidate from '@vuelidate/core';
import ContactoValidacion from '@/validation/InfoContacto';
import func from '@/validation/GeneralRules';
import { useStore } from "vuex";

export default defineComponent({
  props: {
        scopeContacto: String,
        contactoRef: {},
        parametricas: {}
    },
    setup(props){      
        const store = useStore();
        let [objetoContacto, reglasValidacion] = new ContactoValidacion().ObtenerReglasValidacion();      
        const validacionContacto = useVuelidate(reglasValidacion, objetoContacto, { $scope: props.scopeContacto }) 

        return { 
          objetoContacto, validacionContacto, func,store
        };
    },
})
