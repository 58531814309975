
import InputNumber from "primevue/inputnumber";
import LicenciasValidacion from "@/validation/PayrollTransmission/Licencias";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import useVuelidate from "@vuelidate/core";
import { Licencias } from "@/helpers/Parametricas";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import { onMounted, ref } from "vue";
import { DatetimeToDate, Split } from "@/helpers/helpers";

export default {
  components: {
    InputNumber,
    Calendar,
    Dropdown,
    Tooltip,
  },
  props: {
    nomina: String,
  },
  setup(props) {
    const listaLicencias = ref();

    let [licenses, licencias, validaciones] =
      new LicenciasValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, licenses, {
      $scope: props.nomina,
    });

    const AgregarFila = () => {
      const licencia = {
        LicenseTypeCode: null,
        StartDate: null,
        EndDate: null,
        Quantity: null,
        Total: null,
      };

      licenses.items.push(licencia);
      licencias.push(licencia);
    };

    const EliminarLicencia = (index: number) => {
      licencias.splice(index, 1);
      licenses.items.splice(index, 1);
    };

    const FormatearFechas = () => {
      licenses.items = licenses.items.map((licencia: any) => {
        licencia.StartDate = DatetimeToDate(licencia.StartDate);
        licencia.EndDate = DatetimeToDate(licencia.EndDate);
        return licencia;
      });
    };

    const NombreLicencia = () => {
      licenses.items = licenses.items.map((l: any) => {
        l.TipoLicencia = listaLicencias.value
          .filter((licencia: any) => {
            return licencia.codigo == l.LicenseTypeCode;
          })
          .shift()?.abreviatura;
        return l;
      });
    };

    const CodigoLicencia = (licencia: string, index: number) => {
      if (licencia) {
        licenses.items[index].LicenseTypeCode = Split(licencia, 0, "-");
      } else {
        licenses.items[index].LicenseTypeCode = "";
      }
    };

    onMounted(() => {
      listaLicencias.value = Licencias();
      NombreLicencia();
      FormatearFechas();
    });

    return {
      licenses,
      listaLicencias,
      v$,
      EliminarLicencia,
      FormatearFechas,
      AgregarFila,
      CodigoLicencia,
    };
  },
};
