import { reactive } from 'vue'
import { required, helpers, maxValue, minValue } from '@vuelidate/validators'
import { useStore } from "vuex";

class HorasExtrasValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();
    const extrahours = store.state.DetalleNomina.JsonNomina.ExtraHours;
    const HE = reactive({ items: extrahours });
    const horasExtras = reactive(extrahours);

    const maxInt = 2147483647;
    const valorInvalido = "Valor inválido.";
    const campoObligatorio = "Campo obligatorio.";
    const mayorCero = "El valor debe ser mayor a cero.";

    const reglas = {
      items: {
        $each: helpers.forEach({
          SurchargeTypeCode: {
            required: helpers.withMessage("Campo obligatorio.", required),
          },
          Quantity: {
            required: helpers.withMessage(`${campoObligatorio} NIE076`, required),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE076-NIE081-NIE086-NIE091-NIE096-NIE101-NIE106`,
              maxValue(maxInt)
            ),
            minValue: helpers.withMessage(`${mayorCero} NIE076`, minValue(1)),
          },
          Total: {
            required: helpers.withMessage(`${campoObligatorio} NIE078`, required),
            maxValue: helpers.withMessage(
              `${valorInvalido} NIE078-NIE083-NIE088-NIE093-NIE098-NIE103-NIE108`,
              maxValue(maxInt)
            ),
            minValue: helpers.withMessage(`${mayorCero} NIE078`, minValue(1)),
          },
        }),
      },
    };

    return [HE, horasExtras, reglas] as const;
  }
}

export default HorasExtrasValidacion;
