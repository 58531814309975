
import { onMounted, ref } from "vue";
import router from "@/router";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import { useMutations } from "@/store/helper";
import { TipoNomina, TipoNota } from "@/models/Payroll/Enums";
import PayrollAdjustDialog from "primevue/confirmdialog";
import PayrollDeleteDialog from "primevue/confirmdialog";
import TransmitirNomina from "@/components/TransmisionNomina/TransmitirNomina.vue";
import Dialog from "primevue/dialog";
import { PayrollBatchRequest } from "@/models/Payroll/PayrollBatchRequest";

export default {
  name: "TransmisionNomina",
  components: {
    Dialog,
    TransmitirNomina,
    PayrollAdjustDialog,
    PayrollDeleteDialog,
  },
  props: {
    empleadoId: String,
  },
  setup(props) {
    const store = useStore();
    const confirm = useConfirm();
    const token = localStorage.getItem("Tokensin");

    const { setStateLoading } = useMutations(["setStateLoading"]);
    const { showConfirmDialog } = useMutations(["showConfirmDialog"]);
    const { setDetalleNomina } = useMutations(["setDetalleNomina"]);
    const { showTranssmission } = useMutations(["showTranssmission"]);
    const { setParametricasTransmision } = useMutations([
      "setParametricasTransmision",
    ]);

    const empleado = ref("");
    const empleadoId = ref("");
    const tipoAjuste = ref(TipoNota.Reemplazar);
    const periodo = store.state.DetalleEntradaNomina.Periodo;
    const nominaEmpleado = ref("");

    const urlvd = ref("");
    const visiblePopUpIndividual = ref(false);
    const VideoNominaAjustePortal = ref();
    let featureFlag = ref(false);

    const ConfirmarTipoAjuste = () => {
      confirm.require({
        message: "",
        header: "Importante",
        acceptClass: "p-button-danger",
        acceptLabel: "Aceptar",
        rejectLabel: "← Volver",
        accept: () => {
          ValidarTipoAjuste();
        },
        reject: () => {
          confirm.close();
          showConfirmDialog(false);
        },
        onHide: () => {
          confirm.close();
          showConfirmDialog(false);
        },
      });
    };

    const ConfirmarEliminacionNomina = () => {
      confirm.require({
        message: "",
        header: "Importante",
        acceptClass: "p-button-danger",
        acceptLabel: "Aceptar",
        rejectLabel: "← Volver",
        accept: () => {
          showTranssmission(true);
        },
        reject: () => {
          confirm.close();
          ConfirmarTipoAjuste();
        },
        onHide: () => {
          confirm.close();
          ConfirmarTipoAjuste();
        },
      });
    };

    const ValidarTipoAjuste = () => {
      store.state.DetalleNomina.JsonNomina.HasNovelty = false;

      if (tipoAjuste.value == TipoNota.Eliminar) {
        store.state.DetalleNomina.JsonNomina.PayrollAdjust.PayrollAdjustTypeCode =
          TipoNota.Eliminar;
        nominaEmpleado.value = JSON.stringify(
          store.state.DetalleNomina.JsonNomina
        );
        ConfirmarEliminacionNomina();
      } else {
        store.state.DetalleNomina.JsonNomina.PayrollAdjust.PayrollAdjustTypeCode =
          TipoNota.Reemplazar;
        router.push({
          name: "PayrollTransmission",
        });
      }
    };

    function ObtenerInformacionNomina() {
      let entradaConsultaNomina = {
        token: token,
        detalleNominaId: store.state.DetalleNomina.DetalleNominaId,
        empleadoId: props.empleadoId,
      };

      store
        .dispatch("ObtenerDetalleNominaEmpleado", entradaConsultaNomina)
        .then((response) => {
          const detalleNomina = response.data.detalleNomina;
          const payroll: PayrollBatchRequest = JSON.parse(
            detalleNomina.jsonNomina
          );

          empleado.value = response.data.empleado;

          payroll.CuneNovelty = null;
          payroll.XmlTypeCode = TipoNomina.NominaAjuste;

          payroll.PayrollAdjust = {
            PayrollAdjustTypeCode: "",
            PrefixCode: detalleNomina.prefijo,
            DocumentNumber: detalleNomina.consecutivo.toString(),
            Cune: detalleNomina.cune,
          };

          empleado.value = response.data.empleado;
          empleadoId.value = detalleNomina.empleadoId;

          setDetalleNomina({
            Empleado: empleado.value,
            JsonNomina: payroll,
            Detalle: detalleNomina,
          });

          setStateLoading(false);
          ConfirmarTipoAjuste();
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error);
        });
    }

    function ObtenerParametricas() {
      setStateLoading(true);
      let consultaParametricas = {
        token: token,
      };
      store
        .dispatch("ObtenerParametricasTransmision", consultaParametricas)
        .then((response) => {
          if (response.request.status == 200) {
            setParametricasTransmision({
              Divipolas: response.data.divipolas,
              Parametricas: response.data.listasParametricas,
              HorasExtras: response.data.horasExtras,
            });
          }
        })
        .catch((error) => {
          setStateLoading(false);
          console.log(error.request.response);
        });
    }

    function urlVideo(nombreVideo: string) {
      var params = {
        Token: localStorage.getItem("Tokensin"),
        NombreVideo: nombreVideo,
      };
      store
        .dispatch("GetUrlVideo", params)
        .then((data) => {
          urlvd.value = data.data;
        })
        .catch((error) => {
          console.log("Error al obtener el video en azure.");
        });
    }
    const detenerVideo = () => {
      if (VideoNominaAjustePortal.value) {
        VideoNominaAjustePortal.value.pause();
        visiblePopUpIndividual.value = false;
      }
    };

    function GetFeatureFlag() {
      const data = {
        token: localStorage.getItem("Tokensin"),
        featureFlagName: "PBI-232826",
      };
      store
        .dispatch("GetStatusFeatureFlag", data)
        .then((response) => {
          featureFlag.value = response.data;
        })
        .catch((err) => {
          console.error(err);
        });
    }

    const cerrarPopUp = () => {
      visiblePopUpIndividual.value = true;
    };
    onMounted(() => {
      ObtenerParametricas();
      ObtenerInformacionNomina();
      urlVideo("VideoNominaAjustePortal.mp4");
      GetFeatureFlag();
    });

    return {
      tipoAjuste,
      empleado,
      periodo,
      TipoNota,
      nominaEmpleado,
      empleadoId,
      store,
      urlvd,
      urlVideo,
      VideoNominaAjustePortal,
      visiblePopUpIndividual,
      detenerVideo,
      cerrarPopUp,
      featureFlag,
    };
  },
};
