<template>
  <div >
    <Header></Header>
    <div class="container">
      <Menu v-if="StateMenu"></Menu>
       <router-view/>
      <Spinner v-if="StateLoading" type="modern"></Spinner>
    </div>
    <ChatButton></ChatButton>
    <Footer></Footer>
    <LogOut></LogOut>
  </div>

</template>

<script>
import Footer from "@/components/layout/Footer";
import Header from "@/components/layout/Header";
import Menu from "@/components/layout/Menu";
import Spinner from "@/components/layout/Spinner.vue";
import ChatButton from "@/components/layout/ChatButton.vue";
import LogOut from "@/components/layout/LogOut.vue";
import{useState} from '@/store/helper'

export default {
    components:{
      Footer,
      Header,
      Menu,
      Spinner,
      ChatButton,
      LogOut
    },
    setup() {
         const{StateMenu} = useState(['StateMenu']);
         const{StateLoading} = useState(['StateLoading']);
    return{
      StateMenu,
      StateLoading
    };
  }
};
</script>
