
  
  import router from "@/router";
  import { useStore } from "vuex";
  import useVuelidate from "@vuelidate/core";
  import { useMutations } from "@/store/helper";
  import Spinner from "@/components/layout/Spinner.vue";
  import Checkbox from "@/components/layout/Checkbox.vue";
  import Direccion from "@/components/layout/Direccion.vue";
  import DivipolaDian from "@/components/layout/DivipolaDian.vue";
  import { ref, reactive, defineComponent, onMounted } from "vue";
  import { validationRules } from "@/validation/PaylinePaymentForm";
  import Tooltip from "@/components/layout/TooltipTransmission.vue";
  import { DocumentTypes, PersonType, DatosPago } from "@/models/payment";
  import MessageToClient from "@/components/Payment/MessageToClient.vue";
  import ValidadorFranjaHoraria from "@/components/layout/ValidadorFranjaHoraria.vue";
  import { colombianCurrencyValidator, normalizeNumberValidator, thousandsSeparatorFormat } from "@/store/Payment/plans";
  import ProveedorPago from "../models/payment/ProveedorPago";
  import RedirectToKhuski from '@/components/Payment/RedirectToKhuski.vue';
  
  export default defineComponent({
    name: "PaylinePaymentForm",
    props: {
      valuePlan: {
        type: Number,
        default: 0
      },
      documentsCount: Number,
    },
    components: {
      Spinner,
      MessageToClient,
      DivipolaDian,
      Direccion,
      Tooltip,
      Checkbox,
      ValidadorFranjaHoraria,
      RedirectToKhuski
    },
    setup(props) {
      const store = useStore();
      const tiposIdentificacion = ref([{ id: "", descripcion: "", nombre: "", expresion_Regular: "", Longitud_Max: "", Mensaje: "" }]);
      const bancos = ref([{ codigo: "", nombre: ""}]);
      const formRef = ref<HTMLFormElement>();
      const datosPago = reactive({ ...DatosPago });
      const { setStateMenu } = useMutations(["setStateMenu"]);
  
      const divipolaRef = ref();
      const direccionRef = ref();
  
      const expedirFactura = ref(false);
      const codigoPais = ref("");
      const codigoDpto = ref("");
      const codigoMunicipio = ref("");
      const direccion = ref("");
      const metadataDireccion = ref("");
      const objetoDireccion = ref();
      const objetoDivipola = ref();
      const franjaPagoNoDisponible = ref("");
      const mensajePagoNoDisponible = ref("");
      const pagoRestringido = ref(false);
  
      setStateMenu(false);
      const rules = reactive(validationRules(obtenerRegexTipoIdentificacion));
      const processPayment = ref(true);
      const errorPayment = ref(false);

      function getIdentificationTypes() {
        let parametros = {
        token: localStorage.getItem("Tokensin"),
        tipoIdentificacion: localStorage.getItem('TipoIdentificacion'),
        identificacion: localStorage.getItem('Identificacion')
      }
        store.dispatch("GetPasarelaIdentificationTypes",parametros).then(resp=> {        
            tiposIdentificacion.value = resp.data.tipoIdentificacions;
        processPayment.value = false;
        }).catch((error) => {              
                 console.log(error);                            
                 });
      }
  
      function getBanks() {
        let parametros = {
        token: localStorage.getItem("Tokensin"),
        tipoIdentificacion: localStorage.getItem('TipoIdentificacion'),
        identificacion: localStorage.getItem('Identificacion')
      }
        store.dispatch("GetPasarelaBanks",parametros).then(resp=> {        
        bancos.value = resp.data.bancos;   
        processPayment.value = false;
        }).catch((error) => {              
                 console.log(error);                            
                 });
      }
  
      const validarTitularidad = () => {
        if (!expedirFactura.value) {
          datosPago.razonSocialTitular = "";
          datosPago.tipoIdentificacionTitular = "";
          datosPago.numeroIdentificacionTitular = "";
        }
      };
  
      const titularidadFactura = () => {
        let titularidadFactura;
        if (
          !datosPago.razonSocialTitular &&
          !datosPago.tipoIdentificacionTitular &&
          !datosPago.numeroIdentificacionTitular
        ) {
          titularidadFactura = "";
        } else {
          titularidadFactura = {
            identificacionSinonimo: datosPago.tipoIdentificacionTitular,
            numeroIdentificacion: datosPago.numeroIdentificacionTitular,
            nombreORazonSocial: datosPago.razonSocialTitular,
            PasarelaTipoIdentificacionId: tiposIdentificacion.value.find((x) => x.descripcion === datosPago.tipoIdentificacionTitular)?.id
          };
        }
        return titularidadFactura;
      };

      function obtenerRegexTipoIdentificacion(){
        const itemSeleccionado = tiposIdentificacion.value.find(item => item.descripcion === datosPago.tipoIdentificacionTitular);
        return itemSeleccionado ? new RegExp(itemSeleccionado.expresion_Regular) : null;
      }
  
      const metadata = () => {
        return {
          nombreORazonSocial: datosPago.nombreORazonSocial,
          tipoIdentificacion: datosPago.tipoIdentificacion,
          numeroIdentificacion: datosPago.numeroIdentificacion,
          correo: datosPago.correo,
          tipoPersona: datosPago.tipoPersona,
          banco: datosPago.banco,
        };
      };
  
      const datosGeneralesFactura = () => {
        return {
          pais: codigoPais.value,
          departamento: codigoDpto.value,
          municipio: codigoMunicipio.value,
          celular: datosPago.celular,
          direccion: direccion.value,
          metadataDireccion: metadataDireccion.value,
          ProveedorPagoId: ProveedorPago.Pasarela,  
          descripcion: `Compra del plan de ${props.documentsCount} documentos por valor de ${thousandsSeparatorFormat(props.valuePlan)}` ,
          codigoBanco: bancos.value.find((x) => x.nombre === datosPago.banco)?.codigo,      
        };
      };
  
      const amount = () => {
        return {
          subtotalIva: 0,
          subtotalIva0: props.valuePlan,
          iva: 0,
        };
      };
  
      const informacionAportante = () => {
        return {
          razonSocial: localStorage.getItem("RazonSocialPila"),
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          dv: localStorage.getItem("DV"), 
        };
      };
  
      const informacionTransaccion = () => {
        return {
          tokenHeader: localStorage.getItem("Tokensin"),
          token: '',       
          amount: JSON.stringify(amount()),
          metadata: JSON.stringify(metadata()),
          datosGenerales: JSON.stringify(datosGeneralesFactura()),
          titularidadFactura: JSON.stringify(titularidadFactura()),
          informacionAportante: JSON.stringify(informacionAportante()),
          tipoIdentificacion: localStorage.getItem("TipoIdentificacion"),
          numeroIdentificacion: localStorage.getItem("Identificacion"),
          CantidadDocumentos: props.documentsCount,
        };
      };

      function iniciarTransaccion() {
        datosPago.pais = divipolaRef.value.divipolas.Pais
        datosPago.departamento = divipolaRef.value.divipolas.Departamento;
        datosPago.municipio = divipolaRef.value.divipolas.Municipio;
        datosPago.metadataDireccion = JSON.stringify(metadataDireccion.value);
        store.dispatch('cargarDatosPagoTemp',datosPago);       
        store
          .dispatch("GetUrlPayment", informacionTransaccion())
          .then((response: any) => {       
            let test = response.data;
            if (test != null) {         
              window.open(test,"_self");
            } else {
              errorPayment.value = true;
            }
          })
          .catch((err) => {
            processPayment.value = false;
            errorPayment.value = true;
            console.log(err.request.response);
          });
      }
  
      const scopeDivipolas = "Divipolas";
      const validacionDivipolas = useVuelidate({}, {}, {
          $scope: scopeDivipolas,
        }
      );
  
      const scopeDireccion = "Direccion";
      const validacionDireccion = useVuelidate({}, {}, {
          $scope: scopeDireccion,
        }
      );
  
      function GetKeyVaultSecrets() {
        let parametrosConsulta = {
          token: localStorage.getItem("Tokensin"),
          secret1: "FranjaPagoNoDisponible",
          secret2: "MensajePagoNoDisponible",
        };
        store
          .dispatch("GetKeyVaultSecrets", parametrosConsulta)
          .then((response) => {
            if (response.request.status == 200) {
              franjaPagoNoDisponible.value = JSON.parse(response.data[0].value);
              mensajePagoNoDisponible.value = response.data[1].value;
            }
          })
          .catch((error) => {
            console.log(error.request.response);
          });
      }
  
      const obtenerUbicaciones = () => {
        codigoPais.value = divipolaRef.value.codigoPais;
        codigoDpto.value = divipolaRef.value.codigoDpto;
        codigoMunicipio.value = divipolaRef.value.codigoMunicipio;
        direccion.value = direccionRef.value.ObtenerDireccion();
        metadataDireccion.value = direccionRef.value.direccion;
      };
  
      function validarDatosParaPago() {
        if (!pagoRestringido.value) {
          obtenerUbicaciones();
          validacionDivipolas.value.$validate();
          validacionDireccion.value.$validate();
          formRef.value?.validate((valid: boolean) => {        
            if (
               valid &&
              !validacionDivipolas.value.$error &&
              !validacionDireccion.value.$error
            ) { 
              processPayment.value = true;
              errorPayment.value = false;
                  iniciarTransaccion();
                }
          });
        }
      }
  
      function ObtenerTransaccionMasReciente() {
        let parametrosConsulta = {
          token: localStorage.getItem("Tokensin"),
          proveedorPago: ProveedorPago.Pasarela
        };
        store
          .dispatch("ObtenerTransaccionMasReciente", parametrosConsulta)
          .then((response) => {
            if (response.request.status == 200) {
              Object.assign(datosPago, response.data);
              expedirFactura.value = !!(
                datosPago.razonSocialTitular &&
                datosPago.tipoIdentificacionTitular &&
                datosPago.numeroIdentificacionTitular &&
                datosPago.tipoRegistro == 'Usuario'
              );
              validarTitularidad();
              objetoDireccion.value = JSON.parse(datosPago.metadataDireccion);
              objetoDivipola.value = {
                Pais: datosPago.pais,
                Departamento: datosPago.departamento,
                Municipio: datosPago.municipio,
              };
            }
          })
          .catch((error) => {
            console.log(error.request.response);
          });
      }
  
      function comebackToPlans() {
        router.push({ name: "Plans" });
      }
  
      onMounted(() => {
        getIdentificationTypes();
        getBanks();
        GetKeyVaultSecrets();
        ObtenerTransaccionMasReciente();
      });
      return {
        tiposIdentificacion,
        bancos,
        validarDatosParaPago,
        comebackToPlans,
        datosPago,
        divipolaRef,
        direccionRef,
        objetoDireccion,
        objetoDivipola,
        obtenerRegexTipoIdentificacion,
        processPayment,
        errorPayment,
        rules,
        formRef,
        scopeDivipolas,
        scopeDireccion,
        colombianCurrencyValidator,
        normalizeNumberValidator,
        thousandsSeparatorFormat,
        expedirFactura,
        franjaPagoNoDisponible,
        pagoRestringido,
        validarTitularidad,
        mensajePagoNoDisponible,
        store,
        informacionTransaccion
      };
    },
    computed: {
      DocumentTypes: () => DocumentTypes,
      PersonType: () => PersonType,
    }
  });
  