import { helpers, maxValue, minValue } from '@vuelidate/validators';
import { computed, reactive } from 'vue'
import { useStore } from "vuex";

class PrimaValidacion {

  public ObtenerReglasValidacion() {

    const store = useStore();

    const prima = reactive(
      store.state.DetalleNomina.JsonNomina.GeneralAccruals
    );

    const maxInt = 2147483647;
    const mensajeError = "Valor inválido."

    const reglasPrima = computed(() => {
      return {
        PremiumQuantity: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE117`,
            maxValue(maxInt)
          ),
        },
        PremiumTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE118`,
            maxValue(maxInt)
          ),
        },
        PremiumNonSalaryTotal: {
          maxValue: helpers.withMessage(
            `${mensajeError} NIE119`,
            maxValue(maxInt)
          ),
        },
      };
    });

    return [prima, reglasPrima] as const;
  }
}

export default PrimaValidacion;