
import NominaElectronicaValidacion from "@/validation/PayrollTransmission/NominaElectronica";
import useVuelidate from "@vuelidate/core";
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Tooltip from "@/components/layout/TooltipTransmission.vue";
import { onMounted, ref } from "vue";
import {
  FormaPago,
  MetodoPago,
  PeriodoNomina,
  TipoCuenta,
  TipoMoneda,
} from "@/helpers/Parametricas";
import Calendar from "primevue/calendar";
import { CompletarDigitos, DatetimeToDate, Split } from "@/helpers/helpers";

export default {
  components: {
    InputText,
    Dropdown,
    Calendar,
    Tooltip,
    InputNumber,
  },
  props: {
    nomina: String,
  },

  setup(props) {
    const forma = ref("");
    const metodo = ref("");
    const moneda = ref("");
    const cuenta = ref("");
    const periodo = ref("");

    let [nominaElectronica, validaciones] =
      new NominaElectronicaValidacion().ObtenerReglasValidacion();

    const v$ = useVuelidate(validaciones, nominaElectronica, {
      $scope: props.nomina,
    });

    const CodigoPeriodoNomina = () => {
      if (periodo.value) {
        nominaElectronica.PayrollPeriodCode = Split(periodo.value, 0, "-");
      } else {
        nominaElectronica.PayrollPeriodCode = "";
        v$.value.PayrollPeriodCode.$touch();
      }
    };

    const CodigoFormaPago = () => {
      if (forma.value) {
        nominaElectronica.PaymentMeanCode = Split(forma.value, 0, "-");
      } else {
        nominaElectronica.PaymentMeanCode = "";
        v$.value.PaymentMeanCode.$touch();
      }
    };

    const CodigoMetodoPago = () => {
      if (metodo.value) {
        nominaElectronica.PaymentMethodCode = Split(metodo.value, 0, "-");
      } else {
        nominaElectronica.PaymentMethodCode = "";
        v$.value.PaymentMethodCode.$touch();
      }
    };

    const CodigoTipoCuenta = () => {
      if (cuenta.value) {
        nominaElectronica.AccountTypeCode = Split(cuenta.value, 0, "-");
      } else {
        nominaElectronica.AccountTypeCode = "";
      }
    };

    const CodigoMoneda = () => {
      if (moneda.value) {
        nominaElectronica.CurrencyCode = Split(moneda.value, 0, "-");
      } else {
        nominaElectronica.CurrencyCode = "";
        v$.value.CurrencyCode.$touch();
      }
    };

    const ValidarFechasPago = () => {
      FormatearFechas();
      v$.value.PayrollPaymentDates.$touch();
    };

    const ListasDesplegables = () => {
      periodo.value = PeriodoNomina()
        .filter((p: any) => {
          return p.codigo === nominaElectronica.PayrollPeriodCode;
        })
        .shift()?.abreviatura;

      forma.value = FormaPago()
        .filter((f: any) => {
          return (
            f.codigo === CompletarDigitos(nominaElectronica.PaymentMeanCode, 2)
          );
        })
        .shift()?.abreviatura;

      metodo.value = MetodoPago()
        .filter((m: any) => {
          return m.codigo === nominaElectronica.PaymentMethodCode;
        })
        .shift()?.abreviatura;

      cuenta.value = TipoCuenta()
        .filter((c: any) => {
          return c.codigo == nominaElectronica.AccountTypeCode;
        })
        .shift()?.abreviatura;

      moneda.value = TipoMoneda()
        .filter((m: any) => {
          return m.codigo === nominaElectronica.CurrencyCode;
        })
        .shift()?.abreviatura;
    };

    const FormatearFechas = () => {
      nominaElectronica.StartSettlementDate = DatetimeToDate(
        nominaElectronica.StartSettlementDate
      );
      nominaElectronica.EndSettlementDate = DatetimeToDate(
        nominaElectronica.EndSettlementDate
      );
      nominaElectronica.PaymentDates = nominaElectronica.PaymentDates.map(
        (paymentDate: string) => {
          return DatetimeToDate(paymentDate);
        }
      );
    };

    onMounted(() => {
      FormatearFechas();
      ListasDesplegables();
    });

    return {
      nominaElectronica,
      v$,
      PeriodoNomina,
      FormatearFechas,
      FormaPago,
      MetodoPago,
      TipoCuenta,
      TipoMoneda,
      CodigoPeriodoNomina,
      CodigoMetodoPago,
      CodigoFormaPago,
      CodigoTipoCuenta,
      ValidarFechasPago,
      CodigoMoneda,
      periodo,
      forma,
      metodo,
      cuenta,
      moneda,
    };
  },
};
